import Vue from 'vue'
import config from '@/configurations/config-environment'
import { BaseService } from '../baseService'

const dataws = config.apis.dataws

export class QueryService extends BaseService {
  getOverviewQueryResponse(params) {
    params = params || {
      Viewname: 'ITW Toetsing Wbb',
      Param: '{ProjectID: 100000138}'
    } // Check if this should?

    const authorisation = this.getAuthorizationObject()
    const languageCode = this.getLangCodeLongFromShort(authorisation.Language)
    const data = {
      Authorisation: authorisation,
      LanguageCode: languageCode,
      WebserviceVersion: '1.0',
      UseZipStream: false,
      DataType: 'JSON',
      ...params
    }

    return Vue.http
      .post(`${dataws}ITWViewRestService_V1_0/GetQuerryResponse`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        if (response.status !== 200 && response.data.ResultCode !== 'Succes') throw new Error(`Unable to retrieve the overview queries. ${response.data}`)
        const content = JSON.parse(response.data.Content) || {}
        return content.Table || []
      })
  }

  getOverviewQueries() {
    const authorisation = this.getAuthorizationObject()
    const languageCode = this.getLangCodeLongFromShort(authorisation.Language)
    const data = {
      Authorisation: authorisation,
      LanguageCode: languageCode,
      WebserviceVersion: '1.0',
      UseZipStream: false,
      DataType: 'JSON'
    }

    return Vue.http
      .post(`${dataws}ITWViewRestService_V1_0/GetOverviewQueries`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        if (response.status !== 200 && response.data.ResultCode !== 'Succes') throw new Error(`Unable to retrieve the overview queries. ${response.data}`)
        return JSON.parse(response.data.Content)
      })
  }
}
