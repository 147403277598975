import Vue from 'vue'
import config from '@/configurations/config-environment'
import { BaseService } from '../baseService'

const dataws = config.apis.dataws
export class AnalysisResultService extends BaseService {
  getAllAnalysisSamples(projectID) {
    const authorisation = this.getAuthorizationObject()
    const languageCode = this.getLangCodeLongFromShort(authorisation.Language)
    const data = {
      Authorisation: authorisation,
      LanguageCode: languageCode,
      WebserviceVersion: '1.0',
      Parameters: [
        {
          ParameterType: 'ProjectID',
          FloatValue: projectID,
          StringValue: projectID
        }
      ],
      UseZipStream: false
    }
    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/GetAllSamples`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        if (response.data.ResultCode !== 'Export_Succes') {
          throw new Error(`Something went wrong with getting all the samples ${response.data.ResultMessage}`)
        } else {
          return JSON.parse(response.data.Content)
        }
      })
  }

  getAllSamplesWithResults(projectID) {
    const authorisation = this.getAuthorizationObject()
    const languageCode = this.getLangCodeLongFromShort(authorisation.Language)
    const data = {
      Authorisation: authorisation,
      LanguageCode: languageCode,
      WebserviceVersion: '1.0',
      Parameters: [
        {
          ParameterType: 'ProjectID',
          FloatValue: projectID,
          StringValue: projectID
        }
      ],
      UseZipStream: false
    }
    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/GetAllSamplesWithResults`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        if (response.data.ResultCode !== 'Export_Succes') {
          throw new Error(`Something went wrong with getting all the samples with results ${response.data.ResultMessage}`)
        } else {
          return JSON.parse(response.data.Content)
        }
      })
  }

  getAllSubSamples(projectID, sampleGUID) {
    const authorisation = this.getAuthorizationObject()
    const languageCode = this.getLangCodeLongFromShort(authorisation.Language)
    const data = {
      Authorisation: authorisation,
      LanguageCode: languageCode,
      WebserviceVersion: '1.0',
      Parameters: [
        {
          ParameterType: 'ProjectID',
          FloatValue: projectID,
          StringValue: projectID
        },
        {
          ParameterType: 'SampleGuid',
          StringValue: sampleGUID
        }
      ],
      UseZipStream: false
    }
    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/GetAllSampleParts`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        if (response.data.ResultCode !== 'Export_Succes') {
          throw new Error(`Something went wrong with getting all the sub samples ${response.data.ResultMessage}`)
        } else {
          return JSON.parse(response.data.Content)
        }
      })
  }

  GetAllResultsWithConclusions(projectID, sampleGUID) {
    const authorisation = this.getAuthorizationObject()
    const languageCode = this.getLangCodeLongFromShort(authorisation.Language)
    const data = {
      Authorisation: authorisation,
      LanguageCode: languageCode,
      WebserviceVersion: '1.0',
      Parameters: [
        {
          ParameterType: 'ProjectID',
          FloatValue: projectID,
          StringValue: projectID
        },
        {
          ParameterType: 'SampleGuid',
          StringValue: sampleGUID
        }
      ],
      UseZipStream: false
    }
    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/GetAllResultsWithConclusions`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        if (response.data.ResultCode !== 'Export_Succes') {
          throw new Error(`Something went wrong with getting all the samples with results ${response.data.ResultMessage}`)
        } else {
          return JSON.parse(response.data.Content)
        }
      })
  }

  GetAllResults(projectID, sampleGUID) {
    const authorisation = this.getAuthorizationObject()
    const languageCode = this.getLangCodeLongFromShort(authorisation.Language)
    const data = {
      Authorisation: authorisation,
      LanguageCode: languageCode,
      WebserviceVersion: '1.0',
      Parameters: [
        {
          ParameterType: 'ProjectID',
          FloatValue: projectID,
          StringValue: projectID
        },
        {
          ParameterType: 'SampleGuid',
          StringValue: sampleGUID
        }
      ],
      UseZipStream: false
    }
    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/GetAllResults`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        if (response.data.ResultCode !== 'Export_Succes') {
          throw new Error(`Something went wrong with getting all the samples with results ${response.data.ResultMessage}`)
        } else {
          return JSON.parse(response.data.Content)
        }
      })
  }

  CRUDAnalysisResults(projectID, sampleGUID, results) {
    const authorisation = this.getAuthorizationObject()
    const languageCode = this.getLangCodeLongFromShort(authorisation.Language)
    const data = {
      Authorisation: authorisation,
      LanguageCode: languageCode,
      WebserviceVersion: '1.0',
      Parameters: [
        {
          ParameterType: 'ProjectID',
          FloatValue: projectID,
          StringValue: projectID
        },
        {
          ParameterType: 'SampleGuid',
          StringValue: sampleGUID
        }
      ],
      Content: JSON.stringify(results),
      UseZipStream: false
    }
    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/UpdateAnalysisResults`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        if (response.data.ResultCode !== 'Export_Succes') {
          throw new Error(`Something went wrong with updating the samples with results ${response.data.ResultMessage}`)
        } else {
          return JSON.parse(response.data.Content)
        }
      })
  }
}
