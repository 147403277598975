import Vue from 'vue'
import config from '@/configurations/config-environment'
import { BaseService } from '../baseService'

const dataws = config.apis.dataws

export class ProjectUserService extends BaseService {
  getProjectUsersNotPinned(projectId) {
    return new Promise((resolve, reject) => {
      const authorisation = this.getAuthorizationObject()
      const languageCode = this.getLangCodeLongFromShort(authorisation.Language)
      const data = {
        Authorisation: authorisation,
        LanguageCode: languageCode,
        WebserviceVersion: '1.0',
        TypeOfExport: 'Export_FIELD_JSON_v1_0_0',
        Parameters: [
          {
            ParameterType: 'ExportFormat',
            StringValue: 'json'
          },
          {
            ParameterType: 'ProjectID',
            FloatValue: projectId.toString()
          },
          {
            ParameterType: 'NotPinnedUsers'
          }
        ],
        logFormat: 'XML'
      }

      return Vue.http
        .post(`${dataws}ITWDataRestService_V1_0/GetUsers`, data, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then((resp) => {
          if (resp.status === 200 && resp.data.ResultCode === 'Export_Succes' && typeof resp.data.Files[0] !== 'undefined') {
            resolve(JSON.parse(resp.data.Files[0].FileContent))
          } else {
            throw new Error(`Unable to save getProjectUsersNotPinned: ${resp.data.ResultMessage}`)
          }
        })
    })
  }

  pinUnpinUser(res) {
    return new Promise((resolve, reject) => {
      const authorisation = this.getAuthorizationObject()
      const languageCode = this.getLangCodeLongFromShort(authorisation.Language)
      const data = {
        Authorisation: authorisation,
        WebserviceVersion: '1.0',
        LanguageCode: languageCode,
        ProjectID: res.PrID,
        Enabled: res.Enabled,
        UserToPin: res.UserName
      }

      return Vue.http
        .post(`${dataws}ITWDataRestService_V1_0/UnpinAndPinProject`, data, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then((resp) => {
          if (resp.status === 200 && resp.data.ResultCode === 'Succes') {
            resolve()
          } else {
            throw new Error(`Unable to save getProjectUsersNotPinned: ${resp.data.ResultMessage}`)
          }
        })
    })
  }

  getProjectUsersPinned(projectId) {
    const authorisation = this.getAuthorizationObject()
    const languageCode = this.getLangCodeLongFromShort(authorisation.Language)
    const data = {
      Authorisation: authorisation,
      LanguageCode: languageCode,
      WebserviceVersion: '1.0',
      TypeOfExport: 'Export_FIELD_JSON_v1_0_0',
      Parameters: [
        {
          ParameterType: 'ExportFormat',
          StringValue: 'json'
        },
        {
          ParameterType: 'ProjectID',
          FloatValue: projectId
        },
        {
          ParameterType: 'PinnedUsers'
        }
      ],
      logFormat: 'XML'
    }

    return Vue.http.post(`${dataws}ITWDataRestService_V1_0/GetUsers`, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  getProjectUsersPinnedForGrid(projectId) {
    const authorisation = this.getAuthorizationObject()
    const languageCode = this.getLangCodeLongFromShort(authorisation.Language)
    const data = {
      Authorisation: authorisation,
      LanguageCode: languageCode,
      WebserviceVersion: '1.0',
      TypeOfExport: 'Export_FIELD_JSON_v1_0_0',
      Parameters: [
        {
          ParameterType: 'ExportFormat',
          StringValue: 'json'
        },
        {
          ParameterType: 'ProjectID',
          FloatValue: projectId
        },
        {
          ParameterType: 'PinnedUsers'
        }
      ],
      logFormat: 'XML'
    }

    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/GetUsers`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        this.evaluateResponse(response, 'getProjectUsersPinnedForGrid')
        const pinnedUsersJson = JSON.parse(response.data.Files[0].FileContent)
        const pinnedUsers = []

        pinnedUsersJson.forEach((user) => {
          pinnedUsers.push({
            UserName: user.UserName,
            FirstName: user.Person ? user.Person.FirstName : '',
            LastName: user.Person ? user.Person.LastName : '',
            Email: user.Person ? user.Person.Email : ''
          })
        })

        return pinnedUsers
      })
  }
  evaluateResponse(response, requestName) {
    if (
      response.status !== 200 ||
      !response.data ||
      (response.data.ResultCode !== 'Import_Succes' && response.data.ResultCode !== 'Export_Succes') ||
      (response.data.ResultMessage !== null && response.data.ResultMessage.toLowerCase().includes('error'))
    ) {
      throw new Error(`Request ${requestName} failed with message '${response.data.ResultMessage}'`)
    }
  }
}
