import _ from 'lodash'
import configLocales from './app/config-locales'
import configEditors from './app/config-editor'
import configMap from './app/config-map'
import configEnvironment from './config-environment'
import configBuild from './config-build'
import configConstants from './app/config-constants'
import applicationCodes from './config-app-codes'

const config = _.extend(configEnvironment, {
  configLocales,
  configEditors,
  configBuild,
  map: configMap,
  constants: configConstants,
  applicationCodes
})
export default config
