export default {
  develop: {
    ios: 62,
    win: 60,
    web: 61
  },
  production: {
    ios: 62,
    win: 60,
    web: 61
  },
  test: {
    ios: 62,
    win: 60,
    web: 61
  }
}
