export default [
  {
    name: 'Digitale Hoogtemodel Vlaanderen ',
    type: 'WMS',
    country: 'BE',
    url: 'https://geo.api.vlaanderen.be/DHMV/wms',
    sublayers: [
      { id: 'DHMV_II_HILL_25cm', title: 'Multidirectionale hillshade 0,25 m' },
      { id: 'DHMV_II_SVF_25cm', title: 'Skyview factor 0,25 m' },
      { id: 'DHMVII_DTM_1m', title: 'Digitaal terreinmodel 1m' },
      { id: 'DHMVII_DSM_1m', title: 'Digitaal oppervlaktemodel 1m' }
    ]
  },
  {
    name: 'Kadastrale percelen België',
    type: 'WMS',
    country: 'BE',
    url: 'http://ccff02.minfin.fgov.be/geoservices/arcgis/services/WMS/Cadastral_Layers/MapServer/WMSServer?',
    sublayers: [{ id: 'Cadastral_parcel', title: 'percelenplan' }]
  },
  {
    name: 'Gemeentegrens',
    type: 'WMS',
    country: 'BE',
    url: 'https://geo.api.vlaanderen.be/GRB/wms?',
    sublayers: [{ id: 'GEM_GRENS', title: 'GEM - gemeentegrens' },
      { id: 'GRB_ADP', title: 'GRB - ADP - administratief perceel' }]
  },
  {
    name: 'Grootschalig Referentiebestand - 3D Gebouw LOD 1',
    type: 'WMS',
    country: 'BE',
    url: 'https://geo.api.vlaanderen.be/3DGRB/wms?',
    sublayers: [{ id: 'GRBGEBL1D2', title: '3D GRB Gebouw LOD 1 DHMV IIp' }]
  },
  {
    name: 'Relief de la Wallonie',
    type: 'WMS',
    country: 'BE',
    url: 'https://geoservices.wallonie.be/arcgis/services/RELIEF/WALLONIE_MNS_2013_2014/MapServer/WMSServer',
    sublayers: [{ id: '0', title: '1.0 meter' }],
    invertMapTextColour: true
  },
  {
    name: 'Grootschalig Referentiebestand',
    type: 'WMS',
    country: 'BE',
    url: 'https://geo.api.vlaanderen.be/GRB-basiskaart/wms',
    sublayers: [{ id: 'GRB_BSK', title: 'GRB-basiskaart' }]
  },
  {
    name: 'Luchtfoto Vlaanderen 2022 Winter',
    type: 'WMS',
    country: 'BE',
    url: 'https://geo.api.vlaanderen.be/OMW/wms',
    sublayers: [
      { id: 'OMWRGB22VL', title: 'Kleur' }
    ],
    invertMapTextColour: false
  },
  {
    name: 'Luchtfoto Vlaanderen 2021 Zomer',
    type: 'WMS',
    country: 'BE',
    url: 'https://geo.api.vlaanderen.be/OMZ/wms',
    sublayers: [
      { id: 'OMZNIR21VL', title: 'Infrarood' },
      { id: 'OMZPAN21VL', title: 'Zwart-wit' },
      { id: 'OMZRGB21VL', title: 'Kleur' }
    ],
    invertMapTextColour: false
  },
  {
    name: 'Luchtfoto Vlaanderen 1971 - 1990 Zomer',
    type: 'WMS',
    country: 'BE',
    url: 'https://geo.api.vlaanderen.be/OKZ/wms',
    sublayers: [
      { id: 'OKZRGB79_90VL', title: 'Zomer 1979-1990 - kleur' },
      { id: 'OKZPAN71VL', title: 'Zomer 1971 - zwart-wit' }
    ],
    invertMapTextColour: false
  },
  {
    name: 'Urbis Brussel',
    type: 'WMS',
    country: 'BE',
    url: 'https://geoservices-urbis.irisnet.be/geoserver/ows',
    sublayers: [
      { id: 'urbisNL', title: 'Urbis Base Map NL' },
      { id: 'urbisFR', title: 'Urbis Base Map FR' }
    ],
    invertMapTextColour: true
  },
  {
    name: 'CRAB adressen Vlaanderen',
    type: 'WMS',
    country: 'BE',
    url: 'https://geo.api.vlaanderen.be/adressen/wms',
    sublayers: [{ id: 'Adrespos', title: 'Adrespunten (min:2.5k)' }]
  },
  {
    name: 'Adrespunten Brussel',
    type: 'WMS',
    country: 'BE',
    url: 'https://geoservices-urbis.irisnet.be/geoserver/ows',
    sublayers: [{ id: 'UrbisAdm:Adpt', title: 'Adrespunten (min:1.5k)' }]
  },
  {
    name: 'Grondwaterstandindicator Vlaanderen',
    type: 'WMS',
    country: 'BE',
    url: 'https://www.dov.vlaanderen.be/geoserver/wms',
    sublayers: [{ id: 'gw_varia:stand_freatisch', title: 'Grondwaterstandindicator' }]
  },
  {
    name: 'Grondwaterlichamen Vlaanderen',
    type: 'WMS',
    country: 'BE',
    url: 'https://www.dov.vlaanderen.be/geoserver/wms',
    sublayers: [{ id: 'gw_beleid:gwlichamen', title: 'Grondwaterlichamen' }],
    invertMapTextColour: false
  },
  {
    name: 'Bodemtypes Vlaanderen',
    type: 'WMS',
    country: 'BE',
    url: 'https://www.dov.vlaanderen.be/geoserver/wms',
    sublayers: [{ id: 'bodemkaart:bodemtypes', title: 'Bodemtypes (min:100k)' }],
    invertMapTextColour: true
  },
  {
    name: 'Grondmonsters Vlaanderen',
    type: 'WMS',
    country: 'BE',
    url: 'https://www.dov.vlaanderen.be/geoserver/wms',
    sublayers: [{ id: 'boringen:grondmonsters', title: 'Grondmonsters (min:60k)' }]
  },
  {
    name: 'Bodembedekkingsbestanden 2018',
    type: 'WMS',
    country: 'BE',
    url: 'https://geo.api.vlaanderen.be/BodemgebruikBodembedekking/wms',
    sublayers: [
      { id: 'BAK5_18', title: 'Bodemafdekkingskaart (BAK) 5m' },
      { id: 'WOK5_18', title: 'Waterondoorlaatbaarheidskaart (WOK) 5m' },
      { id: 'BBK1_18', title: 'Bodembedekkingskaart (BBK) 1m' }
    ]
  }
]
