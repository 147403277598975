import _ from 'lodash'
const RELATED_FIELDS = ['PrCoordinateSystem']

export default function UpdateDefaultCoordinateSystemPlugin () {
  return store => {
    store.subscribe((mutation, state) => {
      let triggerCondition = false
      if (mutation.type === 'updateWorkingObject') {
        triggerCondition = _.intersection(Object.keys(mutation.payload), RELATED_FIELDS).length > 0
      }

      if (triggerCondition) {
        if (mutation.payload.PrCoordinateSystem) {
          window.localStorage.setItem('defaultCoordinateSystem', mutation.payload.PrCoordinateSystem)
        } else {
          console.error('UpdateDefaultCoordinateSystemPlugin: mutation.payload.PrCoordinateSystem not valid.')
        }
      }
    })
  }
}
