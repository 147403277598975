
export default {
  /**
   * Getter for the loading state
   *
   * @param {*} state
   * @returns
   */
  isLoading (state) {
    return state.loading === true
  },
  /**
   * Getter returning the list of a given query response
   *
   * @param {*} state
   * @returns
   */
  getOverviewQueryResponse (state) {
    return state.overviewQueryResponse
  },
  /**
   * Getter returning the current list of Overview queries
   *
   * @param {*} state
   * @returns
   */
  getOverviewQueries (state) {
    return state.overviewQueries
  },
  getActiveTitle: function (state) {
    return state.activeTitle ? state.activeTitle : sessionStorage.getItem('activeTitle')
  },
  getPrevTitle: function (state) {
    return state.prevTitle
  },
  getCurrentProject: function (state) {
    if (!state.currentProject) {
      let sessionData = sessionStorage.getItem('CURRENT_PROJECT') || ''
      state.currentProject = sessionData ? JSON.parse(sessionData) : ''
    }
    return state.currentProject
  },
  getPrTypeCode (state) {
    if (!state.prTypeCode) {
      state.prTypeCode = sessionStorage.getItem('PR_TYPE_CODE') || ''
    }
    return state.prTypeCode
  },
  getPrTemplateID (state) {
    if (!state.prTemplateID) {
      state.prTemplateID = sessionStorage.getItem('PR_TEMPLATE_ID')
    }
    return state.prTemplateID
  },
  getTemplateField: (state, getters) => (key) => {
    return state.templateFields[key]
  },
  getSubLocations: (state) => {
    return state.subLocations
  },
  getSummaryAndConclusions: (state) => {
    return state.summaryAndConclusions
  },
  getSummaryAndConclusionsTypes: state => state.summaryAndConclusionsTypes,

  getSummaryAndConclusionTypeBySmType: (state) => (SmType) => {
    if (state.summaryAndConclusionsTypes.length === 0) return
    let type = state.summaryAndConclusionsTypes.find(el => el.key === SmType)
    return type
  },

  getLabAssignments: state => state.labAssignments,

  newsItems: state => state.newsItems,

  currentOverview: state => state.currentOverview,

  currentOverviewParameters: state => state.currentOverviewParameters,

  getExportInformationExportProjects: state => state.exportInformationExportProjects
}
