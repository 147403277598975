import Vue from 'vue'
import moment from 'moment'
import config from '@/configurations/config-environment'
import arrayUtils from '@/utils/arrayUtils'
import { BaseService } from '../baseService'

const dataws = config.apis.dataws

export class LocationVisitService extends BaseService {
  updateLocationVisit(locationVisit) {
    locationVisit.Authorisation = this.getAuthorizationObject()
    locationVisit.LvDateLastChanged = moment().utc().add(3, 'hour').format('YYYY-MM-DD HH:mm:ss')
    const data = {
      data: locationVisit,
      action: 'UPDATE'
    }

    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/SetLocationVisits`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        if (response.status !== 200 || response.data.ResultCode !== 'Import_Succes') throw new Error(`Unable to save location visit. ${response.data.ResultMessage}`)
        let tblLocationVisits = JSON.parse(response.data.FileContent).VeldData.tblLocationVisits
        tblLocationVisits = arrayUtils.formatArray(tblLocationVisits)
        return tblLocationVisits
      })
  }

  createLocationVisit(locationVisit) {
    locationVisit.Authorisation = this.getAuthorizationObject()
    locationVisit.LvDateLastChanged = moment().utc().add(3, 'hour').format('YYYY-MM-DD HH:mm:ss')
    const data = {
      data: locationVisit,
      action: 'INSERT'
    }

    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/SetLocationVisits`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        if (response.status !== 200 || response.data.ResultCode !== 'Import_Succes') throw new Error(`Unable to save location visit. ${response.data.ResultMessage}`)
        let tblLocationVisits = JSON.parse(response.data.FileContent).VeldData.tblLocationVisits
        tblLocationVisits = arrayUtils.formatArray(tblLocationVisits)
        return tblLocationVisits
      })
  }

  deleteLocationVisit(projectID, locationVisitGuid) {
    const data = {
      data: {
        PrID: projectID,
        LvGuid: locationVisitGuid,
        Authorisation: this.getAuthorizationObject()
      },
      action: 'DELETE'
    }

    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/SetLocationVisits`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        if (response.status !== 200 || response.data.ResultCode !== 'Import_Succes') throw new Error(`Unable to delete location visit. ${response.data.ResultMessage}`)

        return true
      })
  }

  getLocationVisits(projectID) {
    const authorisation = this.getAuthorizationObject()
    const languageCode = this.getLangCodeLongFromShort(authorisation.Language)
    const data = {
      Authorisation: authorisation,
      LanguageCode: languageCode,
      WebserviceVersion: '1.0',
      TypeOfExport: 'Export_FIELD_JSON_v1_0_0',
      Parameters: [
        {
          ParameterType: 'ExportFormat',
          StringValue: 'json'
        },
        {
          ParameterType: 'ProjectID',
          StringValue: projectID.toString(),
          FloatValue: projectID.toString()
        }
      ],
      logFormat: 'XML'
    }

    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/GetLocationVisits`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((resp) => {
        if (resp.status !== 200 || resp.data.ResultCode !== 'Export_Succes' || typeof resp.data.Files[0] !== 'object') {
          throw new Error(`Unable to get location visits. ${resp.data.ResultMessage}`)
        }

        const veldData = JSON.parse(resp.data.Files[0].FileContent).VeldData
        const tblLocationVisits = arrayUtils.formatArray(veldData.tblLocationVisits)

        return {
          tblLocationVisits
        }
      })
  }
}
