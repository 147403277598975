export const MEASUREMENT_POINT_TYPE = 'measurementPoint'
export const PROJECT_TYPE = 'project'
export const LAYER_TYPE = 'layer'
export const SOIL_SAMPLE_TYPE = 'soilSample'
export const GAUGING_TUBE_TYPE = 'gaugingTube'
export const FINISHING_TYPE = 'finishing'
export const WATER_SAMPLE_TYPE = 'waterSample'
export const BOTTLE_TYPE = 'bottle'
export const CONCERNED_CONTACT_TYPE = 'concernedContact'
export const SUBLOCATION_TYPE = 'subLocation'
export const SUMMARYANDCONCLUSION_TYPE = 'summaryAndConclusion'
export const AIR_SAMPLE_TYPE = 'airSample'

export const dateLastChangedMapping = {
  [MEASUREMENT_POINT_TYPE]: 'MpDateLastChanged',
  [PROJECT_TYPE]: 'PrDateLastChanged',
  [LAYER_TYPE]: 'LaDateLastChanged',
  [SOIL_SAMPLE_TYPE]: 'JrDateLastChanged',
  [GAUGING_TUBE_TYPE]: 'FtDateLastChanged',
  [FINISHING_TYPE]: 'FiDateLastChanged',
  [WATER_SAMPLE_TYPE]: 'WsDateLastChanged',
  [BOTTLE_TYPE]: 'BoDateLastChanged',
  [CONCERNED_CONTACT_TYPE]: 'CcDateLastChanged',
  [SUBLOCATION_TYPE]: 'SlDateLastChanged',
  [SUMMARYANDCONCLUSION_TYPE]: 'SmDateLastChanged',
  [AIR_SAMPLE_TYPE]: 'WsDateLastChanged'
}

export const typeListMapping = {
  [MEASUREMENT_POINT_TYPE]: 'measurementPoints',
  [PROJECT_TYPE]: 'projects',
  [LAYER_TYPE]: 'layers',
  [SOIL_SAMPLE_TYPE]: 'soilSamples',
  [GAUGING_TUBE_TYPE]: 'gaugingTubes',
  [FINISHING_TYPE]: 'finishings',
  [WATER_SAMPLE_TYPE]: 'waterSamples',
  [BOTTLE_TYPE]: 'bottles',
  [CONCERNED_CONTACT_TYPE]: 'concernedContacts',
  [SUBLOCATION_TYPE]: 'subLocations',
  [SUMMARYANDCONCLUSION_TYPE]: 'summaryAndConclusions',
  [AIR_SAMPLE_TYPE]: 'airSamples'
}

export const boxTypeIdentifiers = {
  'mpBox': 'MpID',
  'userBox': 'UserID',
  'layerBox': 'LaID',
  'soilSampleBox': 'JrID',
  'labAssignmentBox': 'LbID',
  'finishingBox': 'FiID',
  'pictureBox': 'FileName',
  'pictureBoxes': 'FileNames',
  'concernedContactBox': 'CcGUID',
  'subLocationBox': 'SlID',
  'mapLayerBox': 'FileName',
  'summaryAndConclusionBox': 'SMGuid',
  'projectDocumentBox': 'FileName',
  'klicFileBox': 'FileName'
}

export const CONCERNED_COMPANY_TYPES_CODE_LIST = 'concernedCompanyTypes'
export const CONTACT_PERSON_FUNCTIONS_CODE_LIST = 'contactPersonFunctions'
export const SUMMARY_AND_CONCLUSION_CODE_LIST = 'summaryAndConclusionTypes'

export const codeListIDMapping = {
  [CONCERNED_COMPANY_TYPES_CODE_LIST]: 85,
  [CONTACT_PERSON_FUNCTIONS_CODE_LIST]: 86,
  [SUMMARY_AND_CONCLUSION_CODE_LIST]: 84
}
