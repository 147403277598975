import Vue from 'vue'
import config from '@/configurations/config-environment'
import arrayUtils from '@/utils/arrayUtils'
import moment from 'moment'
import $store from '@/vuex/store'
import { BaseService } from '../baseService'
import _ from 'lodash'

const dataws = config.apis.dataws

const RELATED_FIELDS = ['LaTo']

export class MeasurementPointService extends BaseService {
  createMeasurementPoint(measurementPoint) {
    // convert datetime object to string representation of date. date editor uses empty string as
    // empty value.
    if (measurementPoint.MpDate != null && measurementPoint.MpDate !== '') {
      measurementPoint.MpDate = moment(measurementPoint.MpDate).format('YYYY-MM-DDTHH:mm:ss')
    } else {
      measurementPoint.MpDate = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss')
    }
    // PrID must be string. getProjects always returns projects with integer PrID, so must convert
    if (measurementPoint.PrID != null) {
      measurementPoint.PrID = measurementPoint.PrID.toString()
    }
    const data = {
      data: measurementPoint,
      action: 'INSERT'
    }
    data.data.Authorisation = this.getAuthorizationObject()

    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/SetMeasurementPoint`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((resp) => {
        this.evaluateResponse(resp, 'createMeasurementPoint')

        let measurementPoints = JSON.parse(resp.data.FileContent).VeldData.tblMeasurementPoints
        measurementPoints = arrayUtils.formatArray(measurementPoints)
        return measurementPoints
      })
  }

  updateMeasurementPoint(measurementPoint) {
    // convert datetime object to string representation of date. date editor uses empty string as
    // empty value.
    if (measurementPoint.MpDate != null && measurementPoint.MpDate !== '') {
      measurementPoint.MpDate = moment(measurementPoint.MpDate).format('YYYY-MM-DDTHH:mm:ss')
    }
    if (measurementPoint.MpDrillmasterSignatureTimestamp != null && measurementPoint.MpDrillmasterSignatureTimestamp !== '') {
      measurementPoint.MpDrillmasterSignatureTimestamp = moment(measurementPoint.MpDrillmasterSignatureTimestamp).format('YYYY-MM-DDTHH:mm:ss')
    }
    // PrID must be string. getProjects always returns projects with integer PrID, so must convert
    if (measurementPoint.PrID != null) {
      measurementPoint.PrID = measurementPoint.PrID.toString()
    }
    const data = {
      data: measurementPoint,
      action: 'UPDATE'
    }
    data.data.Authorisation = this.getAuthorizationObject()

    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/SetMeasurementPoint`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((resp) => {
        this.evaluateResponse(resp, 'updateMeasurementPoint')

        let measurementPoints = JSON.parse(resp.data.FileContent).VeldData.tblMeasurementPoints
        measurementPoints = arrayUtils.formatArray(measurementPoints)
        return measurementPoints
      })
  }

  setNewMeasurementPointDepth(layers) {
    if (layers.length > 0) {
      let condition = _.intersection(Object.keys(layers[layers.length - 1]), RELATED_FIELDS).length > 0
      let deepestLayer = { }
      if (condition) {
        deepestLayer = _.maxBy(layers, function (l) {
          return parseInt(l.LaTo)
        })
        let measurementPoint = $store.state.measurementPoints.find(mp => {
          return mp.MpGuid === $store.state.route.params.measurementPointId ||
            mp.MpID === $store.state.route.params.measurementPointId
        })
        if (measurementPoint) {
          if (measurementPoint.MpDepth !== deepestLayer.LaTo) {
            measurementPoint.MpDepth = (deepestLayer && deepestLayer.LaTo) ? deepestLayer.LaTo : 0
            measurementPoint.MpDateLastChanged = moment()
              .utc()
              .add(3, 'hour')
              .format('YYYY-MM-DDTHH:mm:ss')

            this.updateMeasurementPoint(measurementPoint)
              .then(measurementPoints => {
                $store.commit('setMeasurementPoints', measurementPoints)
              })
          }
        }
      }
    } else {
      let measurementPoint = $store.state.measurementPoints.find(mp => {
        return mp.MpGuid === $store.state.route.params.measurementPointId ||
          mp.MpID === $store.state.route.params.measurementPointId
      })
      measurementPoint.MpDepth = ''
      measurementPoint.MpDateLastChanged = moment()
        .utc()
        .add(3, 'hour')
        .format('YYYY-MM-DDTHH:mm:ss')

      this.updateMeasurementPoint(measurementPoint)
        .then(measurementPoints => {
          $store.commit('setMeasurementPoints', measurementPoints)
        })
    }
  }

  deleteMeasurementPoint(prID, mpGuid) {
    const data = {
      data: {
        PrID: prID,
        MpGuid: mpGuid,
        Authorisation: this.getAuthorizationObject()
      },
      action: 'DELETE'
    }

    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/SetMeasurementPoint`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((resp) => {
        this.evaluateResponse(resp, 'deleteMeasurementPoint')

        return true
      })
  }

  deleteMultipleMeasurementPoints(prID, MpList) {
    const data = {
      data: {
        PrID: prID,
        MeasurementPoints: MpList,
        Authorisation: this.getAuthorizationObject()
      },
      action: 'DELETE'
    }

    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/SetMeasurementPoints`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((resp) => {
        this.evaluateResponse(resp, 'deleteMeasurementPoint')

        return true
      })
  }

  setMeasurementPoint(mpoint, action) {
    // convert datetime object to string representation of date. date editor uses empty string as
    // empty value.
    if (mpoint.MpDate != null && mpoint.MpDate !== '') {
      mpoint.MpDate = moment(mpoint.MpDate).format('YYYY-MM-DDTHH:mm:ss')
    }
    if (mpoint.MpDrillmasterSignatureTimestamp != null && mpoint.MpDrillmasterSignatureTimestamp !== '') {
      mpoint.MpDrillmasterSignatureTimestamp = moment(mpoint.MpDrillmasterSignatureTimestamp).format('YYYY-MM-DDTHH:mm:ss')
    }

    // PrID must be string. getProjects always returns projects with integer PrID, so must convert
    if (mpoint.PrID != null) {
      mpoint.PrID = mpoint.PrID.toString()
    }

    if (!action) action = 'UPDATE' // default is 'update'

    const data = {
      data: mpoint,
      action
    }

    data.data.Authorisation = this.getAuthorizationObject()

    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/SetMeasurementPoint`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((resp) => {
        if (resp.status !== 200 || resp.data.ResultCode !== 'Import_Succes') throw new Error(`Unable to save measurement point. ${resp.data.ResultMessage}`)
        // find updated measurementpoint
        if (action !== 'DELETE' && action !== 'INSERT') {
          let measurementPoints = JSON.parse(resp.data.FileContent).VeldData.tblMeasurementPoints
          measurementPoints = arrayUtils.formatArray(measurementPoints)
          return measurementPoints
        } else {
          return this.getMeasurementPoints(mpoint.PrID).then(function (table) {
            return table.tblMeasurementPoints
          })
        }
      })
  }

  setMeasurementPoints(tableContent) {
    const authorisation = this.getAuthorizationObject()
    const data = {
      body: {
        action: 'UPDATE',
        data: {
          Authorisation: authorisation,
          MeasurementPoints: tableContent.measurementPoints,
          PrID: tableContent.projectId.toString()
        }
      }
    }
    data.body = JSON.stringify(data.body)
    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/SetMeasurementPoints`, data.body, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        let measurementPoints = JSON.parse(response.data.FileContent).VeldData.tblMeasurementPoints
        measurementPoints = arrayUtils.formatArray(measurementPoints)
        return measurementPoints
      })
  }

  getMeasurementPoints(projectID) {
    const authorisation = this.getAuthorizationObject()
    const languageCode = this.getLangCodeLongFromShort(authorisation.Language)
    const data = {
      Authorisation: authorisation,
      LanguageCode: languageCode,
      WebserviceVersion: '1.0',
      TypeOfExport: 'Export_FIELD_JSON_v1_0_0',
      Parameters: [
        {
          ParameterType: 'ExportFormat',
          StringValue: 'json'
        },
        {
          ParameterType: 'ProjectID',
          StringValue: projectID.toString(),
          FloatValue: projectID.toString()
        }
      ],
      logFormat: 'XML'
    }

    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/GetMeasurementPoints`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((resp) => {
        this.evaluateResponse(resp, 'getMeasurementPoints')

        const veldData = JSON.parse(resp.data.Files[0].FileContent).VeldData
        const tblMeasurementPoints = arrayUtils.formatArray(veldData.tblMeasurementPoints)
        const tblProjects = arrayUtils.formatArray(veldData.tblProjects)

        return {
          tblMeasurementPoints,
          tblProjects
        }
      })
  }

  GetMeasurementPointWithChilds(projectID, mpGuid) {
    const authorisation = this.getAuthorizationObject()
    const languageCode = this.getLangCodeLongFromShort(authorisation.Language)
    const data = {
      Authorisation: authorisation,
      LanguageCode: languageCode,
      WebserviceVersion: '1.0',
      TypeOfExport: 'Export_FIELD_JSON_v1_0_0',
      logFormat: 'XML',
      Parameters: [
        { ParameterType: 'ProjectID', FloatValue: projectID, StringValue: projectID },
        { ParameterType: 'ExportFormat', StringValue: 'json' },
        { ParameterType: 'MeasurementPointGuid', StringValue: mpGuid }
      ]
    }

    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/GetMeasurementPointWithChilds`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        if (response.status !== 200 && response.data.ResultCode !== '"Export_Succes"') throw new Error(`Unable to retrieve the project information. ${response.data}`)
        return JSON.parse(response.data.Files[0].FileContent)
      })
  }

  evaluateResponse(response, requestName) {
    if (
      response.status !== 200 ||
      !response.data ||
      (response.data.ResultCode !== 'Import_Succes' && response.data.ResultCode !== 'Export_Succes') ||
      (response.data.ResultMessage !== null && response.data.ResultMessage.toLowerCase().includes('error'))
    ) {
      throw new Error(`Request ${requestName} failed with message '${response.data.ResultMessage}'`)
    }
  }
}
