import _ from 'lodash'
import nenClassificationUtil from '@/utils/nenClassificationUtil'
import { LAYER_TYPE } from '@/vuex/constants'

const RELATED_FIELDS = ['LaGravelPerc', 'LaLoamPerc', 'LaLutumPerc', 'LaHumusPerc', 'LaSiltPerc', 'LaM50']

/**
 * Calculate NEN classification if one (or more) of the properties on which its value is dependent (RELATED_FIELDS)
 * has changed.
 */
export default function NENClassificationPlugin () {
  return store => {
    store.subscribe((mutation, state) => {
      let condition = state.workingObjectType === LAYER_TYPE &&
        mutation.type === 'updateWorkingObject' &&
        // if one or more keys are mutated that are in PERC_FIELDS
        _.intersection(Object.keys(mutation.payload), RELATED_FIELDS).length > 0
      if (condition) {
        // calculate the texture from percentage data
        let nenTexture = nenClassificationUtil.calculateNENClassification(
          state.workingObject.LaGravelPerc,
          state.workingObject.LaLoamPerc,
          state.workingObject.LaLutumPerc,
          state.workingObject.LaHumusPerc,
          state.workingObject.LaSiltPerc,
          state.workingObject.LaM50)

        store.commit('updateWorkingObject', {
          LaSoilTypeCode: nenTexture.SoilTypeCode || '',
          LaMedianCode: nenTexture.MedianCode.toString() || '',
          LaAd1TypeCode: nenTexture.Ad1TypeCode || '',
          LaAd1GradationCode: nenTexture.Ad1GradationCode.toString() || '',
          LaAd2TypeCode: nenTexture.Ad2TypeCode || '',
          LaAd2GradationCode: nenTexture.Ad2GradationCode.toString() || '',
          LaAd3GradationCode: '',
          LaAd3TypeCode: ''
        })
      }
    })
  }
}
