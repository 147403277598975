/* eslint-disable */
'use strict'

const editors = {
  '1': {
    name: 'textEditor', // normal text
    dataType: 'string'
  },
  '2': {
    name: 'dateEditor',
    dataType: 'date',
    options: {
      mode: 'date',
      formats: {
        nl: 'DD-MM-YYYY',
        en: 'YYYY-MM-DD'
      }
    }
  },
  '3': {
    name: 'listEditor',
    options: {
      // for these fields, save the localizedText instead of the value of the selected item. this is done to enforce a 'decoupling' of the
      // field and the object corresponding to the item. in case, for instance, a drilling master is removed, then this has no implications
      // on the projects of which it was selected.
      saveFullTextInsteadOfID: ['MpDrillingMasterCode', 'PrDrillingMasterCode', 'WsSampleTakerCode', 'MpSampleTakerCode', 'PrLeaderCode', 'PrPhaseCode', 'SlNameInspector'],
      isObject: true,
      isLookup: true,
      textFormat: function(item){ return (item.localizedText?item.localizedText:'') },
      textFormatKeys: ["localizedText"]
    }
  },
  '4': {
    name: 'booleanEditor',
    dataType: 'boolean',
    tableEditorComponent: 'tableBooleanEditor',
    options: {
      isObject: true
    }
  },
  '5': {
    name: 'numberEditor',
    dataType: 'number',
    options: {
      allowDigits: false,
      allowMinus: true
    }
  },
  '6': {
    name: 'numberEditor',
    dataType: 'number',
    options: {
      allowDigits: true,
      allowMinus: true
    }
  },
  '8': 'textAreaEditor',
  '8': {
    name: 'textAreaEditor',
    dataType: 'string'
  },
  '11': {
    name: 'listEditor',
    tableEditorComponent: 'tableDropdownEditor',
    dataType: 'string',
    options: {
      isObject: true,
      isLookup: true,
      textFormat: function(item){ return item.localizedText },
      textFormatKeys: ["localizedCode","localizedText"],
      saveFullTextInsteadOfID: []
    }
  },
  '12': {
    name: 'listEditor',
    tableEditorComponent: 'tableDropdownEditor',
    dataType: 'string',
    options: {
      disabledIfFieldIsNotCorrect: { fieldName: 'SmType', code: '1' },
      isObject: true,
      isLookup: true,
      textFormat: function(item){ return item.localizedText },
      textFormatKeys: ["localizedCode","localizedText"],
      saveFullTextInsteadOfID: []
    }
  },
  '13': {
    name: 'dateEditor',
    dataType: 'datetime',
    options: {
      mode: 'datetime',
      formats: {
        default:'DD-MM-YYYY HH:mm:ss',
        en: 'YYYY-MM-DD HH:mm:ss',
        nl: 'DD-MM-YYYY HH:mm:ss',
        fr: 'DD-MM-YYYY HH:mm:ss',
        es: 'DD-MM-YYYY HH:mm:ss',
        de: 'DD-MM-YYYY HH:mm:ss',
        it: 'DD-MM-YYYY HH:mm:ss'
      }
    }
  },
  '14': {
    name: 'barcodeEditor',
    options: {
      isObject: true,
      maxLength: 5
    }
  },
  '17': 'textEditor', // JrName
  '19': {
    name: 'trajectoryEditor',
    options: {
      allowDigits: true,
      allowMinus: false // does not apply for finishing trajectory
    }
  },
  '21': 'projectCodeEditor',
  '22': {
    name: 'textureCodeEditor',
    options: {
      isObject: true
    }
  },
  '23': {
    name: 'specCompEditor',
    options: {
      isObject: true,
      maxLength: 5
    }
  },
  '24': {
    name: 'colourEditor',
    options: {
      isObject: true
    }
  },
  '25': 'textEditor', // MpName
  '26': 'textEditor', // FtName
  '27': 'textEditor', // WsName
  '28': 'textEditor', // BoName
  '30': {
    name: 'subLocationListEditor',
    options: {
      isLookup: true
    }
  },
  '31': {
    name: 'coordinatesEditor',
    options: {
      allowDigits: true,
      allowMinus: true
    }
  },
  '32': {
    name: 'listEditor',
    options: {
      isObject: true,
      saveFullTextInsteadOfID: [],
      textFormat: function(item){ return (item.localizedText?item.localizedText:'') },
      textFormatKeys: ["localizedText"],
    }
  },
  '36': {
    name: 'twoListEditor',
    options: {
      isObject: true
    }
  },
  '37': {
    name: 'listEditor',
    options: {
      isObject: true,
      textFormat: function(item){ return item.localizedCode+' - '+(item.localizedText?item.localizedText:'') },
      textFormatKeys: ["localizedCode","localizedText","localizedText2"],
      saveFullTextInsteadOfID: []
    }
  },
  '40': 'imageEditor', // Show image
  '*': 'labelEditor'
}

export default function getEditor (code) {
  var selectedEditor = editors[code]
  selectedEditor = selectedEditor || editors['*']

  if (typeof selectedEditor === 'string') {
    selectedEditor = {
      name: selectedEditor,
      dataType: 'string',
      options: {}
    }
  }

  return selectedEditor
}
