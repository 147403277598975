import Vue from 'vue'
import config from '@/configurations/config-environment'
import arrayUtils from '@/utils/arrayUtils'
import * as localForage from 'localforage'
import { BaseService } from '../baseService'

const dataws = config.apis.dataws
const codeListCacheStore = localForage.createInstance({
  name: 'codeListCacheStore'
})

export class CodeListService extends BaseService {
  clearLocalCache() {
    return codeListCacheStore.clear()
  }

  getCodeListByCategoryIDOrCode(categoryID, codeID, lang = '') {
    const authorisation = this.getAuthorizationObject()
    const languageCode = this.getLangCodeLongFromShort(authorisation.Language)
    const data = {
      Authorisation: authorisation,
      LanguageCode: languageCode,
      WebserviceVersion: '1.0',
      TypeOfExport: 'Export_Field_Codelist_JSON',
      Parameters: [],
      LogFormat: 'XML'
    }

    if (categoryID) {
      data.Parameters.push({
        ParameterType: 'CategoryID',
        StringValue: categoryID.toString(),
        FloatValue: categoryID.toString()
      })
    }

    if (codeID) {
      data.Parameters.push({
        ParameterType: 'CodeID',
        StringValue: codeID.toString(),
        FloatValue: codeID.toString()
      })
    }

    const cacheKey = `${categoryID};${languageCode}`
    return codeListCacheStore
      .getItem(cacheKey)
      .then((storeResult) => {
        if (storeResult && storeResult.cacheDate && storeResult.cacheDate === new Date().toDateString()) {
          return storeResult
        }

        return Vue.http
          .post(`${dataws}ITWDataRestService_V1_0/GetCodeListByCategoryIDOrCode`, data, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then((result) => {
            result.cacheDate = new Date().toDateString()
            return codeListCacheStore.setItem(cacheKey, result).then(() => {
              return result
            })
          })
      })
      .then((resp) => {
        if (resp.status !== 200 || resp.data.ResultCode !== 'Export_Succes' || typeof resp.data.Files[0] !== 'object') {
          throw new Error(`Unable to get code list. ${resp.data.ResultMessage}`)
        }

        const codes = JSON.parse(resp.data.Files[0].FileContent).Codes
        return arrayUtils.formatArray(codes.tblGeneralCodes)
      })
  }

  getCoordinateSystems() {
    return this.getCodeListByCategoryIDOrCode(37, null)
  }

  getLabStatusCodes() {
    return this.getCodeListByCategoryIDOrCode(3, null)
  }
}
