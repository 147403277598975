import Vue from 'vue'
import config from '@/configurations/config-environment'
import _ from 'lodash'
import { BaseService } from '../baseService'

const sso = config.sso.ssoServer

export class UserManagementService extends BaseService {
  getAuthorizations() {
    const header = this.getAuthorizationObject()
    const url = `${sso}/UserManagement/AuthorFunctions/${header.Licensenumber}?lang=${header.Language}`

    return Vue.http.get(url).then((resp) => {
      if (resp.status !== 200 || resp.data.ResultCode !== 0) throw new Error('Unable to retrieve permissions.')

      return _.flatten(resp.data.Data.map((p) => p.FunctionList.map((f) => f.FunctionName)))
    })
  }

  getUserProfile() {
    const url = `${sso}/UserManagement/user/profile`
    const authorisation = this.getAuthorizationObject()
    const languageCode = this.getLangCodeLongFromShort(authorisation.Language)
    const data = {
      Authorisation: authorisation,
      LanguageCode: languageCode,
      WebserviceVersion: '1.0',
      UseZipStream: false,
      DataType: 'JSON'
    }

    return Vue.http
      .get(url, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        if (response.status !== 200 || response.ok !== true || response.statusText !== 'OK' || !response.data || !response.data.Data) {
          throw new Error(`Unable to retrieve the user profile. ${response}`)
        }

        return response.data.Data
      })
  }

  checkIfLicenseForUserIsAuthorizedForFeatureword(featureWord) {
    const url = `${config.apis.managementWs}AuthorisationServiceRest/CheckFunctionRights`
    const authorisation = this.getAuthorizationObject()
    const data = {
      Username: authorisation.Username,
      Licensenumber: authorisation.Licensenumber,
      ApplicationCode: authorisation.ApplicationCode,
      FeatureWord: featureWord
    }

    return Vue.http
      .post(url, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        console.log({ response })
        if (response.status !== 200 || response.ok !== true || response.data.ResultCode !== 'Succes') {
          return false
        }

        return true
      })
  }

  checkIfUserHasRoleFunction(functionName) {
    const url = `${config.apis.managementWs}AuthorisationServiceRest/UserHasFunction`
    const authorisation = this.getAuthorizationObject()
    const data = {
      Username: authorisation.Username,
      Licensenumber: authorisation.Licensenumber,
      ApplicationCode: authorisation.ApplicationCode,
      FunctionName: functionName
    }

    return Vue.http
      .post(url, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        console.log({ response })
        if (response.status !== 200 || response.ok !== true || response.data.ResultCode !== 'Succes') {
          return false
        }

        return true
      })
  }
}
