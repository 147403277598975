import arrayUtils from '@/utils/arrayUtils'
import guidUtils from '@/utils/guidUtils'
import fastCopy from '@/utils/fastCopy'

import { ProjectService } from '@/services/DataWS/projectService'
import { MeasurementPointService } from '@/services/DataWS/measurementPointService'
import stringUtils from '@/utils/stringUtils'
import { LabAssignmentService } from '@/services/DataWS/labAssignmentService'

const projectService = new ProjectService()
const measurementPointService = new MeasurementPointService()
const labAssignmentsService = new LabAssignmentService()

export default class MeasurementPointDuplicator {
  async duplicateMeasurementPoints(options, measurementPointSelection) {
    return new Promise(async (resolve, reject) => {
      try {
        if (measurementPointSelection.length === 0) throw new Error('Empty selection')
        const sourceProjectId = options.sourceProjectId.toString()
        const destinationProjectId = options.projectId ? options.projectId.toString() : sourceProjectId
        const joinedMeasurementPointSelection = measurementPointSelection.join(';')

        // get all tables connect to the selected measurement points
        let projectWithFieldData = await labAssignmentsService.getProjectWithFieldData(options.sourceProjectId.toString(), joinedMeasurementPointSelection)

        if (!projectWithFieldData || !projectWithFieldData.VeldData) throw new Error('Getting source table information went wrong')
        projectWithFieldData.VeldData = this.cleanServiceResponse(projectWithFieldData.VeldData)

        // foreach measurement point delete coordinates fields when copy coordinates is not selected
        if (!options.copyCoordinates && projectWithFieldData.VeldData.tblMeasurementPoints) {
          projectWithFieldData.VeldData.tblMeasurementPoints.forEach(measurementPointTable => {
            this.deleteCoordinatesFromMeasurementPointTable(measurementPointTable)
          })
        }

        // delete fieldSample if option was deselected
        if (!options.copyFieldSamples) {
          this.deleteFieldSampleData(projectWithFieldData.VeldData)
        }

        // Generate new names for measurement points and water/air samples
        if (options.action === 'copy') {
          this.createCopiesOfMeasurementPoint(projectWithFieldData.VeldData, options.copies)
          await this.updateDateAndName(
            projectWithFieldData.VeldData.tblMeasurementPoints,
            projectWithFieldData.VeldData.tblFilterTubes,
            projectWithFieldData.VeldData.tblWaterSamples,
            destinationProjectId,
            options.copies)
        }

        // add new GUID to all data (assignNewGuidToAllObjectsInCopyRequest)
        this.assignNewGuidToAllTables(projectWithFieldData.VeldData)

        // Update project ID
        projectWithFieldData = this.updateAllProjectId(projectWithFieldData, sourceProjectId, destinationProjectId)

        // Get destination project and update last changed
        const getProjectResponse = await projectService.getSingleProject(destinationProjectId)
        if (getProjectResponse.status !== 200) throw new Error('Getting destination project went wrong')
        const destinationProject = JSON.parse(getProjectResponse.data.Files[0].FileContent)
        if (!destinationProject.VeldData) throw new Error('destination project has no data')
        projectWithFieldData.VeldData.tblProjects = destinationProject.VeldData.tblProjects
        projectWithFieldData.VeldData.tblProjects.PrDateContentLastChanged = new Date()

        let result = await projectService.SetProjectWithChildsCall(destinationProjectId, projectWithFieldData.VeldData)
        if (options.action !== 'move') {
          resolve(result)
          return
        }
        // delete measurement point of old data
        const deleteMeasurementPointList = []
        measurementPointSelection.forEach(measurementPointGuid => {
          deleteMeasurementPointList.push({ MpGuid: measurementPointGuid })
        })
        result = await measurementPointService.deleteMultipleMeasurementPoints(sourceProjectId, deleteMeasurementPointList)
        resolve(result)
      } catch (error) {
        reject(new Error('Something went wrong with copying the measurement point(s) due to: ' + error.message))
      }
    })
  }

  createCopiesOfMeasurementPoint(veldData, copyCount) {
    if (copyCount === 1) return
    let copyVeldData = JSON.parse(JSON.stringify(veldData))
    // sublocation shouldn't be duplicated
    delete (copyVeldData.tblSublocations)
    for (let i = 1; i < copyCount; i++) {
      const veldDataWithNewId = this.assignNewIdsToDuplicatedTableData(veldData, copyVeldData)
      Object.keys(copyVeldData).forEach(key => {
        veldData[key] = [...veldData[key], ...veldDataWithNewId[key]]
      })
    }
  }

  assignNewIdsToDuplicatedTableData(veldData, duplicatedTableData) {
    const tablesWithIDs = {
      tblMeasurementPoints: { id: 'MpID' },
      tblLayers: { id: 'LaID' },
      tblJars: { id: 'JrID' },
      tblFinishings: { id: 'FiID' },
      tblFilterTubes: { id: 'FtID' },
      tblWaterSamples: { id: 'WsID' },
      tblBottles: { id: 'BoID' },
      tblWaterSampleMeasurements: { id: 'WmID' }
    }

    Object.keys(duplicatedTableData).forEach((table) => {
      if (!tablesWithIDs[table]) return
      let requestTable = duplicatedTableData[table]
      const startIdCountTableKey = veldData[table].length + 1

      const idKey = tablesWithIDs[table].id
      for (let tableRow = 0; tableRow < requestTable.length; tableRow++) {
        let stringDuplicatedFieldData = JSON.stringify(duplicatedTableData)
        // ids start at 1
        const replacePattern = `"${idKey}":"${tableRow + 1}"`
        const replacementValue = `"${idKey}":"${startIdCountTableKey + tableRow}"`
        duplicatedTableData = JSON.parse(stringDuplicatedFieldData.replaceAll(replacePattern, replacementValue))
      }
    })
    return duplicatedTableData
  }

  findMeasurementPointInState(veldData, guid, state) {
    const measurementPointTable = veldData ? veldData.tblMeasurementPoints : state.measurementPoints
    const result = measurementPointTable.find((measurementPoint) => { return measurementPoint.MpGuid === guid })
    return result ? fastCopy.fastCopy(result) : undefined
  }

  async getMeasurementPointListByProjectID(projectId) {
    let measurementPointList = (await measurementPointService.getMeasurementPoints(projectId)).tblMeasurementPoints
    if (measurementPointList.PrID) {
      measurementPointList = [{ ...measurementPointList }]
    }

    return measurementPointList
  }

  deleteCoordinatesFromMeasurementPointTable(tlbMeasurementPoint) {
    delete (tlbMeasurementPoint.MpXCoord)
    delete (tlbMeasurementPoint.MpYCoord)
    delete (tlbMeasurementPoint.MpPointGeometry)
  }

  deleteFieldSampleData(VeldData) {
    delete (VeldData.tblJars)
    delete (VeldData.tblWaterSamples)
    delete (VeldData.tblWaterSampleMeasurements)
    delete (VeldData.tblObservation)
    delete (VeldData.tblBottles)
  }

  updateAllProjectId(projectWithFieldData, sourceProjectId, destinationProjectId) {
    let stringProjectFieldData = JSON.stringify(projectWithFieldData)
    const replacePattern = `"PrID":"${sourceProjectId}"`
    const replacementValue = `"PrID":"${destinationProjectId}"`
    return JSON.parse(stringProjectFieldData.replaceAll(replacePattern, replacementValue))
  }

  cleanServiceResponse(veldData) {
    let copyVeldData = { ...veldData }
    delete (copyVeldData.tblProjects)
    delete (copyVeldData.tblMetaData)
    // first value in array is table name, second value is the table content.
    // this can be an object, so for easier code we turn all objects into arrays
    for (const [tableKey, tableValue] of Object.entries(copyVeldData)) {
      if (Array.isArray(tableValue)) continue
      copyVeldData[tableKey] = [tableValue]
    }
    return copyVeldData
  }

  assignNewGuidToAllTables(veldData) {
    const tablesWithGuids = {
      tblLayers: { classicIdentifier: 'LaGuid' },
      tblJars: { classicIdentifier: 'JrGuid' },
      tblFinishings: { classicIdentifier: 'FiGuid' },
      tblFilterTubes: { classicIdentifier: 'FtGuid' },
      tblWaterSamples: { classicIdentifier: 'WsGuid' },
      tblBottles: { classicIdentifier: 'BoGuid' },
      tblObservation: { classicIdentifier: 'ObID' },
      tblSublocations: { classicIdentifier: 'SlGuid' },
      tblMeasurementPoints: { classicIdentifier: 'MpGuid' }
    }

    Object.keys(veldData).forEach((table) => {
      if (!tablesWithGuids[table]) return

      let requestTable = veldData[table]
      const classicGuidKey = tablesWithGuids[table].classicIdentifier
      requestTable.forEach((tableRecord) => {
        tableRecord[classicGuidKey] = guidUtils.newGuid()
      })
    })
  }

  async updateDateAndName(tblMeasurementPoints, tblFilterTubes, tblWaterSamples, destinationProjectId) {
    const measurementPointList = await this.getMeasurementPointListByProjectID(destinationProjectId)

    for (let measurementPoint of tblMeasurementPoints) {
      measurementPoint.MpDateLastChanged = new Date()
      // Update measurement point name
      const originalMeasurementPointName = measurementPoint.MpName
      const newMeasurementPointName = arrayUtils.getSuggestedName(measurementPointList, 'Mp', measurementPoint.MpName)
      measurementPoint.MpName = newMeasurementPointName
      measurementPointList.push(measurementPoint)

      // This is necessary for water samples (and air samples, which are water samples)
      // as they're required to have unique names across their project
      // find all filter tubes for measurement point and update all watersamples within those filter tubes
      if (!tblFilterTubes || !tblWaterSamples) continue
      this.renameWaterSamples(tblFilterTubes, tblWaterSamples, measurementPoint, originalMeasurementPointName, newMeasurementPointName)
    }
  }

  renameWaterSamples(tblFilterTubes, tblWaterSamples, measurementPoint, originalMeasurementPointName, newMeasurementPointName) {
    const fieldKeyMpId = 'MpID'
    const fieldKeyFtId = 'FtID'
    const filterTubesInMeasurementPoint = tblFilterTubes.filter(filterTube => filterTube[fieldKeyMpId] === measurementPoint[fieldKeyMpId])
    if (filterTubesInMeasurementPoint.length === 0) return
    for (let filterTube of filterTubesInMeasurementPoint) {
      const waterSamplesInFilterTube = tblWaterSamples.filter(waterSample => waterSample[fieldKeyFtId] === filterTube[fieldKeyFtId])
      if (waterSamplesInFilterTube.length === 0) continue
      waterSamplesInFilterTube.forEach(waterSample => {
        waterSample.WsDateLastChanged = new Date()
        waterSample.WsName = stringUtils.fixStringLength(waterSample.WsName.replace(originalMeasurementPointName, newMeasurementPointName), 50)
      })
    }
  }
}
