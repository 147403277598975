export default [
  {
    name: 'AHN',
    type: 'WMS',
    country: 'NL',
    url: 'https://service.pdok.nl/rws/ahn/wms/v1_0',
    sublayers: [
      { id: 'dtm_05m', title: '0.5 meter (min:15k)' },
      { id: 'dsm_05m', title: '0.5 meter ruw (min:15k)' }
    ],
    invertMapTextColour: false
  },
  {
    name: 'TOP10NL',
    type: 'WMS',
    country: 'NL',
    url: 'https://service.pdok.nl/brt/top10nl/wms/v1_0',
    sublayers: [{ id: 'top10nl', title: 'TOP10NL  (min:30k)' }],
    invertMapTextColour: true
  },
  {
    name: 'Basisregistraties Adressen en Gebouwen',
    type: 'WMS',
    country: 'NL',
    url: 'https://service.pdok.nl/lv/bag/wms/v2_0',
    sublayers: [
      { id: 'pand', title: 'Pand' },
      { id: 'Verblijfsobject', title: 'Verblijfsobject (min:7k)' }
    ]
  },
  {
    name: 'Fysisch-Geografische Nederland',
    type: 'WMS',
    country: 'NL',
    url: 'https://service.pdok.nl/ez/fysischgeografischeregios/wms/v1_0',
    sublayers: [
      { id: 'fysischgeografischeregios', title: 'Fysisch-Geografische Regios' }
    ],
    invertMapTextColour: true
  },
  {
    name: 'Archeologischewaarden Nederland',
    type: 'WMS',
    country: 'NL',
    url: 'http://services.rce.geovoorziening.nl/ikaw/wms',
    sublayers: [
      {
        id: 'IKAW3Indicatievekaartarcheologischewaarden2008',
        title: 'Archeologischewaarden Kaart'
      }
    ],
    invertMapTextColour: true
  },
  {
    name: 'Landschappen kaart Nederland',
    type: 'WMS',
    country: 'NL',
    url: 'http://services.rce.geovoorziening.nl/landschappenkaart/wms',
    sublayers: [
      { id: 'LandschappenkaartNederland', title: 'Landschappen kaart (min:500k)' }
    ],
    invertMapTextColour: true
  },
  {
    name: 'Bodemgebruik kaart Nederland',
    type: 'WMS',
    country: 'NL',
    url: 'https://service.pdok.nl/cbs/bestandbodemgebruik/2010/wms/v1_0',
    sublayers: [
      { id: 'bestand_bodemgebruik_2010', title: 'Bodemgebruik (min:130k)' }
    ],
    invertMapTextColour: true
  },
  {
    name: 'BRO Grondwaterstandindicator Nederland',
    type: 'WMS',
    country: 'NL',
    url: 'https://service.pdok.nl/bzk/brogmwkenset/wms/v2_1',
    sublayers: [{ id: 'gmw_kenset', title: 'Grondwaterstandindicator' }]
  },
  {
    name: 'Kadastrale percelen Nederland',
    type: 'WMS',
    country: 'NL',
    url: 'https://service.pdok.nl/kadaster/kadastralekaart/wms/v5_0',
    sublayers: [{ id: 'Perceel', title: 'Kadastrale percelen (min:25k)' }]
  },
  {
    name: 'Minuutplanbegrenzingen',
    type: 'WMS',
    country: 'NL',
    url: 'https://services.rce.geovoorziening.nl/misc/wms',
    sublayers: [
      { id: 'Bonnebladen1900_index', title: 'Bonnebladen 1900' },
      { id: 'Minuutplanbegrenzingen', title: 'Minuutplanbegrenzingen' },
      { id: 'RDcoordRaster_lijnen', title: 'Raster lijnen' },
      { id: 'gezichten_met_rehabilitatieplan', title: 'Gezichten met rehabilitatieplan' },
      { id: 'gezichten_met_rehabilitatieplan', title: 'Gezichten met rehabilitatieplan' },
      { id: 'landschapstypen', title: 'Landschapstypen' },
      { id: 'res_regios', title: 'Regios' },
      { id: 'stadsvernieuwingsgebieden', title: 'Stadsvernieuwingsgebieden' }
    ]
  },
  {
    name: 'Bodemloket voortgang bodemonderzoek',
    type: 'WMS',
    country: 'NL',
    url: 'https://www.gdngeoservices.nl/arcgis/services/blk/lks_blk_rd/MapServer/WMSServer',
    sublayers: [
      { id: 'Bevoegd_gezag', title: 'Bevoegd gezag' },
      { id: 'Beschikbaarheid_gegevens', title: 'Beschikbaarheid gegevens' },
      { id: 'WBB_locaties', title: 'WBB locaties' }
    ]
  },
  {
    name: 'Bodemloket digitale bodemkwaliteitskaarten',
    type: 'WMS',
    country: 'NL',
    EPSG: 'EPSG:28992',
    url: 'https://www.bodemloket.nl/cgi-bin/mapserv?map=maps/algemeen.map',
    sublayers: [
      { id: 'zonering_bovengrond', title: 'Zonering' },
      { id: 'ontgravingskaart_bovengrond', title: 'Ontgravingskaart' },
      { id: 'toepassingskaart_bovengrond', title: 'Toepassing' },
      { id: 'gemeenten', title: 'Nederlandse Gemeenten' },
      { id: 'beleid', title: 'Gemeentelijk bodem beleid' }
    ]
  },
  {
    name: 'Spoorwegen',
    type: 'WMS',
    country: 'NL',
    url: 'https://service.pdok.nl/prorail/spoorwegen/wms/v1_0',
    sublayers: [
      { id: 'kilometrering', title: 'Kilometrering' },
      { id: 'kruising', title: 'Kruising' },
      { id: 'overweg', title: 'Overweg' },
      { id: 'spooras', title: 'Spooras' },
      { id: 'station', title: 'Station' },
      { id: 'trace', title: 'Trace' },
      { id: 'wissel', title: 'Wissel' }
    ]
  },
  {
    name: 'NWB Wegen',
    type: 'WMS',
    country: 'NL',
    url: 'https://service.pdok.nl/rws/nwbwegen/wms/v1_0',
    sublayers: [
      { id: 'hectopunten', title: 'hectopunten' },
      { id: 'wegvakken', title: 'wegvakken' }
    ]
  },
  {
    name: 'NWB Vaarwegen',
    type: 'WMS',
    country: 'NL',
    url: 'https://service.pdok.nl/rws/nwbvaarwegen/wms/v1_0',
    sublayers: [
      { id: 'kmmarkeringen', title: 'kmmarkeringen (min:50k)' },
      { id: 'vaarwegvakken', title: 'vaarwegvakken (min:50k)' }
    ]
  }
]
