import Vue from 'vue'
import config from '@/configurations/config-environment'
import { BaseService } from '../baseService'

const dataws = config.apis.dataws

export class LabAssignmentService extends BaseService {
  getLabassignmentSoilData(projectId, excludeFieldSamplesOlderThenDays) {
    const authorisation = this.getAuthorizationObject()
    const languageCode = this.getLangCodeLongFromShort(authorisation.Language)
    const data = {
      Authorisation: authorisation,
      LanguageCode: languageCode,
      WebserviceVersion: '1.0',
      TypeOfExport: 'Export_FIELD_JSON_v1_0_0',
      Parameters: [
        {
          ParameterType: 'ExportFormat',
          StringValue: 'json'
        },
        {
          ParameterType: 'ProjectID',
          StringValue: projectId.toString(),
          FloatValue: projectId.toString()
        },
        {
          ParameterType: 'ExcludeFieldsampleOlderThenXDays',
          FloatValue: parseInt(excludeFieldSamplesOlderThenDays)
        }
      ],
      logFormat: 'XML'
    }

    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/GetLabassignmentSoilData`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        if (response.status !== 200 || response.data.ResultCode !== 'Export_Succes' || !response.data.Files || !response.data.Files[0] || !response.data.Files[0].FileContent) {
          throw new Error(`Unable to GetLabassignmentSoilData. ${response.data.ResultMessage}`)
        }

        return JSON.parse(response.data.Files[0].FileContent)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  getProjectWithFieldData(projectId, selectedMeasurementPointGuids) {
    const authorisation = this.getAuthorizationObject()
    const languageCode = this.getLangCodeLongFromShort(authorisation.Language)
    const data = {
      Authorisation: authorisation,
      LanguageCode: languageCode,
      WebserviceVersion: '1.0',
      TypeOfExport: 'Export_FIELD_JSON_v1_0_0',
      Parameters: [
        {
          ParameterType: 'ExportFormat',
          StringValue: 'json'
        },
        {
          ParameterType: 'ProjectID',
          StringValue: projectId.toString(),
          FloatValue: projectId.toString()
        },
        {
          ParameterType: 'MeasurementPointGuid',
          StringValue: selectedMeasurementPointGuids
        }
      ],
      logFormat: 'XML'
    }

    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/GetProjectWithFieldData`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        if (response.status !== 200 || response.data.ResultCode !== 'Export_Succes' || !response.data.Files || !response.data.Files[0] || !response.data.Files[0].FileContent) {
          throw new Error(`Unable to GetProjectWithFieldData. ${response.data.ResultMessage}`)
        }

        return JSON.parse(response.data.Files[0].FileContent)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  getProjectLabassignments(projectId) {
    const authorisation = this.getAuthorizationObject()
    const languageCode = this.getLangCodeLongFromShort(authorisation.Language)
    const data = {
      Authorisation: authorisation,
      LanguageCode: languageCode,
      WebserviceVersion: '1.0',
      TypeOfExport: 'Export_FIELD_JSON_v1_0_0',
      Parameters: [
        {
          ParameterType: 'ExportFormat',
          StringValue: 'json'
        },
        {
          ParameterType: 'ProjectID',
          FloatValue: projectId.toString()
        }
      ],
      logFormat: 'XML'
    }

    return Vue.http.post(`${dataws}ITWDataRestService_V1_0/GetLabassignments`, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  deleteLabAssignment(labAssignment, tblProject) {
    labAssignment.LbDeleted = true
    const data = {
      data: {
        Authorisation: this.getAuthorizationObject(),
        PrID: tblProject.PrID,
        velddata: {
          VeldData: {
            tblProjects: [tblProject],
            tblLabassignments: [labAssignment]
          }
        }
      },
      action: 'DELETE'
    }
    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/SetLabassignment`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        if (response.status !== 200 || response.data.ResultCode !== 'Import_Succes') {
          throw new Error(`Unable to delete lab assignment. ${response.data.ResultMessage}`)
        } else {
          return true
        }
      })
  }

  getAllLabAssignmentsBetweenTwoDate(fromDate, toDate) {
    const authorisation = this.getAuthorizationObject()
    const languageCode = this.getLangCodeLongFromShort(authorisation.Language)
    const data = {
      Authorisation: authorisation,
      LanguageCode: languageCode,
      WebserviceVersion: '1.0',
      Parameters: [
        {
          ParameterType: 'FromDate',
          StringValue: fromDate
        },
        {
          ParameterType: 'ToDate',
          StringValue: toDate
        }
      ],
      UseZipStream: false
    }

    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/GetAllLabAssignmentsBetweenDateRanges`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        if (response.data.ResultCode === 'Export_Succes') {
          return JSON.parse(response.data.Content)
        }

        return []
      })
  }

  setAnalysisSamples(projectData) {
    const data = {
      action: 'CREATE',
      data: {
        Authorisation: this.getAuthorizationObject(),
        PrID: projectData.VeldData.tblProjects.PrID,
        velddata: {
          VeldData: {
            tblAnalysisSamples: projectData.VeldData.tblAnalysisSamples,
            tblJars: projectData.VeldData.tblJars,
            tblMeasurementPoints: projectData.VeldData.tblMeasurementPoints,
            tblMetaData: projectData.VeldData.tblMetaData,
            tblProjects: projectData.VeldData.tblProjects,
            tblSampleParts: projectData.VeldData.tblSampleParts
          }
        }
      }
    }
    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/SetLabassignment`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        if (!response || response.status !== 200 || !response.data || response.data.ResultCode !== 'Import_Succes') {
          return false
        }
        return true
      })
      .catch((error) => {
        console.error(error)
        return false
      })
  }
}
