'use strict'

const typefield = {
  '22': [
    // if it is normal input. skip the validation
    {
      name: 'default',
      skip: true
    },
    {
      name: 'La',
      title: 'texture',
      maxLength: 10,
      skipParentValid: true,
      db_fields: [
        {
          name: 'LaSoilTypeCode',
          mapName: 'LaSoilTypeCode',
          fieldKey: 'LaSoilType'
        },
        {
          name: 'LaMedianCode',
          mapName: 'LaMedianCode'
        },
        {
          name: 'LaAd1TypeCode',
          mapName: 'LaAd1TypeCode'
        },
        {
          name: 'LaAd1GradationCode',
          mapName: 'LaAd1GradationCode'
        },
        {
          name: 'LaAd2GradationCode',
          mapName: 'LaAd2GradationCode'
        },
        {
          name: 'LaAd2TypeCode',
          mapName: 'LaAd2TypeCode'
        },
        {
          name: 'LaAd3TypeCode',
          mapName: 'LaAd3TypeCode'
        },
        {
          name: 'LaAd3GradationCode',
          mapName: 'LaAd3GradationCode'
        },
        {
          name: 'LaAd4TypeCode',
          mapName: 'LaAd4TypeCode'
        },
        {
          name: 'LaAd4GradationCode',
          mapName: 'LaAd4GradationCode'
        },
        {
          name: 'LaTextureTertiaryCode',
          mapName: 'LaTextureTertiaryCode'
        },
        {
          name: 'LaPeatAmorphousCode',
          mapName: 'LaPeatAmorphousCode'
        },
        {
          name: 'LaConsistencyCode',
          mapName: 'LaConsistencyCode'
        },
        {
          name: 'LaTexturePlasticityCode',
          mapName: 'LaTexturePlasticityCode'
        },
        {
          name: 'LaTextureGrainShapeCode',
          mapName: 'LaTextureGrainShapeCode'
        },
        {
          name: 'LaTextureFormCode',
          mapName: 'LaTextureFormCode'
        },
        {
          name: 'LaTextureSurfaceCode',
          mapName: 'LaTextureSurfaceCode'
        },
        {
          name: 'LaTextureParticleStrengthCode',
          mapName: 'LaTextureParticleStrengthCode'
        },
        {
          name: 'LaRockShapeCode',
          mapName: 'LaRockShapeCode'
        },
        {
          name: 'LaRockWeatheringCode',
          mapName: 'LaRockWeatheringCode'
        }
      ]
    }
  ],
  '23': [
    // if it is normal input. skip the validation
    {
      name: 'default',
      skip: true
    },
    {
      name: 'LaSpecialCompounds',
      title: 'Spec. comp.',
      maxLength: 10,
      db_fields: [
        {
          name: 'LaSc1TypeCode',
          mapName: 'first_1'
        },
        {
          name: 'LaSc1GradationCode',
          mapName: 'second_1'
        },
        {
          name: 'LaSc1OriginCode',
          mapName: 'third_1'
        },
        {
          name: 'LaSc2TypeCode',
          mapName: 'first_2'
        },
        {
          name: 'LaSc2GradationCode',
          mapName: 'second_2'
        },
        {
          name: 'LaSc2OriginCode',
          mapName: 'third_2'
        },
        {
          name: 'LaSc3TypeCode',
          mapName: 'first_3'
        },
        {
          name: 'LaSc3GradationCode',
          mapName: 'second_3'
        },
        {
          name: 'LaSc3OriginCode',
          mapName: 'third_3'
        },
        {
          name: 'LaSc4TypeCode',
          mapName: 'first_4'
        },
        {
          name: 'LaSc4GradationCode',
          mapName: 'second_4'
        },
        {
          name: 'LaSc4OriginCode',
          mapName: 'third_4'
        },
        {
          name: 'LaSc5TypeCode',
          mapName: 'first_5'
        },
        {
          name: 'LaSc5GradationCode',
          mapName: 'second_5'
        },
        {
          name: 'LaSc5OriginCode',
          mapName: 'third_5'
        },
        {
          name: 'LaSc6TypeCode',
          mapName: 'first_6'
        },
        {
          name: 'LaSc6GradationCode',
          mapName: 'second_6'
        },
        {
          name: 'LaSc6OriginCode',
          mapName: 'third_6'
        },
        {
          name: 'LaSc7TypeCode',
          mapName: 'first_7'
        },
        {
          name: 'LaSc7GradationCode',
          mapName: 'second_7'
        },
        {
          name: 'LaSc7OriginCode',
          mapName: 'third_7'
        }
      ]
    }
  ],
  '24': [
    {
      name: 'default',
      maxLength: 10,
      skip: true
    },
    {
      name: 'LaColour',
      options: {// define the sources of list field of the control
        gradation: 'LaColourShadeCode',
        mainColour: 'LaColourMainCode',
        subColour: 'LaColourMainCode'
      },
      maxLength: 10,
      db_fields: [// define the map of control field and db field
        {
          name: 'LaColourShadeCode',
          mapName: 'gradation'
        },
        {
          name: 'LaColourMainCode',
          mapName: 'mainColour'
        },
        {
          name: 'LaColourSubCode',
          mapName: 'subColour'
        }
      ]
    },
    // DO NOT CHANGE THE ORDER OF LaSpot2 AND LaSpot. indexOf LaSpot2 in LaSpotMainColorCode == -1 and LaSpot in LaSpot2MainColorCode > -1
    {
      name: 'LaSpot2',
      options: {
        gradation: 'LaSpot2ShadeColorCode',
        mainColour: 'LaSpot2MainColorCode',
        subColour: 'LaSpot2MainColorCode'
      },
      maxLength: 10,
      db_fields: [
        {
          name: 'LaSpot2ColorIntensityCode',
          mapName: 'gradation'
        },
        {
          name: 'LaSpot2MainColorCode',
          mapName: 'mainColour'
        },
        {
          name: 'LaSpot2SubColorCode',
          mapName: 'subColour'
        }
      ]
    },
    {
      name: 'LaSpot',
      options: {
        gradation: 'LaSpotShadeColorCode',
        mainColour: 'LaSpotMainColorCode',
        subColour: 'LaSpotMainColorCode'
      },
      maxLength: 10,
      db_fields: [
        {
          name: 'LaSpotColorIntensityCode',
          mapName: 'gradation'
        },
        {
          name: 'LaSpotMainColorCode',
          mapName: 'mainColour'
        },
        {
          name: 'LaSpotSubColorCode',
          mapName: 'subColour'
        }
      ]
    },
    {
      name: 'WsColour',
      options: {
        gradation: 'WsColourShadeCode',
        mainColour: 'WsColourMainCode',
        subColour: 'WsColourMainCode'
      },
      maxLength: 10,
      db_fields: [
        {
          name: 'WsColourShadeCode',
          mapName: 'gradation'
        },
        {
          name: 'WsColourMainCode',
          mapName: 'mainColour'
        },
        {
          name: 'WsColourSubCode',
          mapName: 'subColour'
        }
      ]
    }
  ],
  '36': [
    // if it is normal input. skip the validation
    {
      name: 'default'
    },
    {
      name: 'LaOdour',
      maxLength: 10,
      db_fields: [
        {
          name: 'LaOdourTypeCode',
          mapName: 'first'
        },
        {
          name: 'LaOdourIntensityCode',
          mapName: 'second'
        }
      ]
    },
    {
      name: 'WsOdour',
      maxLength: 10,
      db_fields: [
        {
          name: 'WsOdourGradationCode',
          mapName: 'first'
        },
        {
          name: 'WsOdourCode',
          mapName: 'second'
        }
      ]
    },
    {
      name: 'LaOdur',
      maxLength: 10,
      db_fields: [
        {
          name: 'LaOdourIntensityCode',
          mapName: 'first'
        },
        {
          name: 'LaOdourTypeCode',
          mapName: 'second'
        }
      ]
    },

    {
      name: 'LaBiogenAdmixtureCode',
      maxLength: 10,
      db_fields: [
        {
          name: 'LaBiogenAdmixtureCode',
          mapName: 'first'
        },
        {
          name: 'LaBiogenAdmixtureGradCode',
          mapName: 'second'
        }
      ]
    },

    {
      name: 'LaArchIndication1Code',
      maxLength: 10,
      db_fields: [
        {
          name: 'LaArchIndication1Code',
          mapName: 'first'
        },
        {
          name: 'LaArchIndication1GradCode',
          mapName: 'second'
        }
      ]
    },
    {
      name: 'LaArchIndication2Code',
      maxLength: 10,
      db_fields: [
        {
          name: 'LaArchIndication2Code',
          mapName: 'first'
        },
        {
          name: 'LaArchIndication2GradCode',
          mapName: 'second'
        }
      ]
    },
    {
      name: 'LaArchIndication3Code',
      maxLength: 10,
      db_fields: [
        {
          name: 'LaArchIndication3Code',
          mapName: 'first'
        },
        {
          name: 'LaArchIndication3GradCode',
          mapName: 'second'
        }
      ]
    },
    {
      name: 'LaArchIndication4Code',
      maxLength: 10,
      db_fields: [
        {
          name: 'LaArchIndication4Code',
          mapName: 'first'
        },
        {
          name: 'LaArchIndication4GradCode',
          mapName: 'second'
        }
      ]
    },
    {
      name: 'LaArchIndication5Code',
      maxLength: 10,
      db_fields: [
        {
          name: 'LaArchIndication5Code',
          mapName: 'first'
        },
        {
          name: 'LaArchIndication5GradCode',
          mapName: 'second'
        }
      ]
    }
  ],
  '31': [
    // if it is normal input. skip the validation
    {
      name: 'default',
      skip: true
    },
    {
      name: 'Coord',
      spiltchar: ' ',
      maxLength: 10,
      db_fields: [
        {
          name: 'MpXCoord',
          mapName: 'mpxcoord'
        },
        {
          name: 'MpYCoord',
          mapName: 'mpycoord'
        }
      ]
    }
  ],
  '19': [
    {
      name: 'default',
      maxLength: 10,
      options: {
        rule: 'lower', // define the valid rule
        translateOne: 'from', // define the maps of the codes that are in translate file and the texts of the control wwhich should be translated
        translateTwo: 'to'
      },
      db_fields: [
        {
          name: 'JrFrom',
          mapName: 'first'
        },
        {
          name: 'JrTo',
          mapName: 'second'
        }
      ]
    },
    {
      name: 'Jr',
      maxLength: 10,
      options: {
        rule: 'lower',
        translateOne: 'from',
        translateTwo: 'to'
      },
      db_fields: [
        {
          name: 'JrFrom',
          mapName: 'first'
        },
        {
          name: 'JrTo',
          mapName: 'second'
        }
      ]
    },
    {
      name: 'Fi',
      maxLength: 10,
      options: {
        rule: 'lower',
        translateOne: 'from',
        translateTwo: 'to'
      },
      db_fields: [
        {
          name: 'FiFrom',
          mapName: 'first'
        },
        {
          name: 'FiTo',
          mapName: 'second'
        }
      ]
    },
    {
      name: 'PrLoc',
      maxLength: 10,
      options: {
        translateOne: 'xcoord',
        translateTwo: 'ycoord'
      },
      db_fields: [
        {
          name: 'PrLocXCoord',
          mapName: 'first'
        },
        {
          name: 'PrLocYCoord',
          mapName: 'second'
        }
      ]
    },
    {
      name: 'La',
      maxLength: 10,
      options: {
        first_isReadOnly: true, // set the input text of given field's readonly propetry
        second_isReadOnly: false,
        rule: 'next',
        translateOne: 'from',
        translateTwo: 'to'
      },
      db_fields: [
        {
          name: 'LaFrom',
          mapName: 'first'
        },
        {
          name: 'LaTo',
          mapName: 'second'
        },
        {
          name: 'nextLaTo',
          mapName: 'next'
        }
      ]
    },
    {
      name: 'FtFilter',
      maxLength: 10,
      options: {
        rule: 'lower',
        translateOne: 'from',
        translateTwo: 'to'
      },
      db_fields: [
        {
          name: 'FtFilterFrom',
          mapName: 'first'
        },
        {
          name: 'FtFilterTo',
          mapName: 'second'
        }
      ]
    },
    {
      name: 'MpTrialPit',
      maxLength: 10,
      options: {
        translateOne: 'length',
        translateTwo: 'width'
      },
      db_fields: [
        {
          name: 'MpTrialPitLength',
          mapName: 'first'
        },
        {
          name: 'MpTrialPitWidth',
          mapName: 'second'
        }
      ]
    }
  ],
  '1': [
    // if it is normal input. skip the validation
    {
      name: 'default',
      skip: true
    },
    // for jrname to check unique name. It has a rule and params
    {
      name: 'JrName',
      skip: true,
      options: {
        rule: 'jrUnique',
        params: 'soilSamples'
      }
    }
  ]
}

export default typefield
