import Vue from 'vue'
import config from '@/configurations/config-environment'
import { BaseService } from '../baseService'

const sso = config.sso.ssoServer

export class OathService extends BaseService {
  connectUserToAzure(idToken, username, password) {
    const url = `${sso}/oauth/ConnectUserToAzureLogin`

    const data = {
      AzureJwt: idToken,
      Username: username,
      Password: password
    }

    return Vue.http.post(url, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  connectUserToAzureByInviteToken(idToken, inviteToken) {
    const url = `${sso}/oauth/ConnectUserToAzureLoginByInviteToken`

    const data = {
      AzureJwt: idToken,
      InviteToken: inviteToken
    }

    return Vue.http.post(url, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  validateInviteToken(inviteToken) {
    const url = `${sso}/oauth/ValidateInviteToken`

    const data = {
      InviteToken: inviteToken
    }

    return Vue.http.post(url, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  getUserInfoByAzureJwt(idToken) {
    const url = `${sso}/oauth/GetUserInfoByAzureJwt`

    const data = {
      AzureJwt: idToken
    }

    return Vue.http.post(url, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  getLicensesByAzureJwt(idToken) {
    const url = `${sso}/oauth/GetLicensesByAzureJwt`

    const data = {
      AzureJwt: idToken
    }

    return Vue.http.post(url, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  getClassicTokenByAzureJwt(idToken, licenseNumber, language) {
    const url = `${sso}/oauth/GetSingleClassicTokenByAzureJwt`

    const data = {
      AzureJwt: idToken,
      ApplicationCode: config.applicationCodes[config.env][config.platform],
      Language: language,
      Licensenumber: licenseNumber,
      ClientId: config.sso.client_id
    }

    return Vue.http.post(url, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  getLicenseUsageInformation(idToken, licenseNumber) {
    const url = `${sso}/oauth/GetLicenseUsageInformation`

    const data = {
      AzureJwt: idToken,
      ApplicationCode: config.applicationCodes[config.env][config.platform],
      Licensenumber: licenseNumber,
      ClientId: config.sso.client_id
    }

    return Vue.http.post(url, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  generateAndSavePincode(idToken) {
    const url = `${sso}/oauth/GenerateAndSavePincodeAsPassword`

    const data = {
      AzureJwt: idToken
    }

    return Vue.http.post(url, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }
}
