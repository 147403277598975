import _ from 'lodash'
import moment from 'moment'
import { MeasurementPointService } from '@/services/DataWS/measurementPointService'

const measurementPointService = new MeasurementPointService()

const RELATED_FIELDS = ['LaTo']

/**
 * Update measurement point depth if the deepest layer LaTo has changed.
 *
 * Note that this plugin cannot separate new / updated layers, nor which layer (first/middle/last) based on the setLayer mutation + state alone.
 * See: https://vuex.vuejs.org/guide/plugins.html#taking-state-snapshots
 *
 * As a 'workaround' a newLayer mutation is added, as well as a compare to the working object.
 *
 */

export default function MeasurementPointDepthPlugin () {
  return store => {
    store.subscribe((mutation, state) => {
      let condition = false
      let MpGuid = ''
      // The payload for the table layer entry also contains the MpGuid to make sure the function below works overwrite the payload with the layers from the payload
      if (
        (mutation.payload && (mutation.payload.length >= 1 || (mutation.payload.layers && mutation.payload.layers.length > 0))) ||
        (mutation.payload && mutation.payload && mutation.payload.LaTo)
      ) {
        if (mutation.type === 'updateWorkingObject') {
          condition = _.intersection(Object.keys(mutation.payload), RELATED_FIELDS).length > 0
        } else if (mutation.type === 'newLayer') {
          // new layer is on last index
          condition = _.intersection(Object.keys(mutation.payload[mutation.payload.length - 1]), RELATED_FIELDS).length > 0
        } else if (mutation.type === 'setTableLayers') {
          MpGuid = mutation.payload.MpGUID
          let filterLayer = mutation.payload.layers.filter((layer) => {
            return layer.MpGUID === MpGuid
          })
          mutation.payload = filterLayer
          if (mutation.payload.length > 0) {
            condition = _.intersection(Object.keys(mutation.payload[mutation.payload.length - 1]), RELATED_FIELDS).length > 0
          }
        }
      }
      if (condition) {
        let tableLayers
        // check if this mutation is on the deepest layer and thus the mp depth should be updated accordingly (or else leave untouched)
        let deepestLayer = { }
        if (mutation.type === 'updateWorkingObject' || mutation.type === 'newLayer') {
          deepestLayer = _.maxBy(store.state.layers, function (l) {
            return parseInt(l.LaTo)
          })
        } else {
          tableLayers = store.state.tableLayers.filter((layer) => { return layer.MpGUID === mutation.payload[mutation.payload.length - 1].MpGUID })
          deepestLayer = _.maxBy(tableLayers, function (l) {
            return parseInt(l.LaTo)
          })
        }

        let tableUpdate = false
        if (mutation.type === 'setTableLayers') {
          // Because the date time of the store is the same as the mutation we check if the date time of the muttation differse at max 2 seconds of the current datetime. Of so update MPDepth else skip
          let dateTime = moment().utc().add(2, 'hour')
          let dateTimeMutation = moment.utc(mutation.payload[mutation.payload.length - 1].LaDateLastChanged, 'YYYY-MM-DDTHH:mm:ss')
          let dateTimeDiff = dateTime.diff(dateTimeMutation, 'seconds')
          if (dateTimeDiff >= 0 && dateTimeDiff <= 2) {
            tableUpdate = true
          }
        }
        if (
          mutation.type === 'newLayer' ||
          (mutation.payload.LaTo && (mutation.payload.LaTo === deepestLayer.LaTo)) ||
          (tableUpdate)
        ) {
          // Get the parent measurement point and push to server
          let measurementPoint = null
          if (store.state.route.name === 'overview') {
            measurementPoint = state.measurementPoints.find((mp) => { return mp.MpGuid === MpGuid })
          } else {
            measurementPoint = state.measurementPoints.find(mp => {
              // router._currentRoute.params.measurementPointId can be GUID or ID, so compare to both...
              return mp.MpGuid === store.state.route.params.measurementPointId ||
                mp.MpID === store.state.route.params.measurementPointId
            })
          }

          if (measurementPoint) {
            if (measurementPoint.MpDepth !== deepestLayer.LaTo) {
              measurementPoint.MpDepth = (deepestLayer && deepestLayer.LaTo) ? deepestLayer.LaTo : 0
              measurementPoint.MpDateLastChanged = moment()
                .utc()
                .add(3, 'hour')
                .format('YYYY-MM-DDTHH:mm:ss')

              // Danger: because of the code below the measurement point depth can be updated while the layer isn't.
              // This happens if the browser tab is refreshed/closed and the warning alert to save is ignored.
              measurementPointService.updateMeasurementPoint(measurementPoint)
                .then(measurementPoints => {
                  store.commit('setMeasurementPoints', measurementPoints)
                })
            }
          }
        }
      }
    })
  }
}
