'use strict'

export const LOCAL_STORAGE_VERSIONS_KEY = 'localStorageVersions'

export const MAP_LAYERS_CONFIGS_KEY = 'MapLayersConfigs'

export const localStorageVersions = {
  [MAP_LAYERS_CONFIGS_KEY]: '3.5'
}

export const LOCAL_STORAGE_USERINFO_KEY = 'UserInfo'

export const EDITOR_TYPES = { // Category ID 209 AND LanguageCode = 'eng', Descriptions are ugly it there first then just reexport it
  TEXT_INPUT_CONTROL: '1',
  DATE_INPUT_CONTROL: '2',
  CUSTOM_LIST_INPUT_CONTROL: '3',
  CHECK_INPUT_CONTROL: '4',
  INTEGER_INPUT_CONTROL: '5',
  DOUBLE_INPUT_CONTROL: '6',
  CURRENCY_CONTROL: '7',
  MEMO_INPUT_CONTROL: '8',
  GROUP_CONTROL: '9',
  MASTER_TABLE_INPUT_CONTROL: '10',
  LOOKUP_INPUT_CONTROL: '11',
  RADIO_INPUT_CONTROL: '12',
  DATE_TIME_INPUT_CONTROL: '13',
  BARCODE_INPUT_CONTROL: '14',
  SIGN_IN_INPUT_CONTROL: '15',
  TITLE_CONTROL: '16',
  JARNAME_CONTROL: '17',
  LOT_PARTNAME_CONTROL: '18',
  TWO_DOUBLE_CONTROL: '19',
  CALCULATE_DOUBLE_CONTROL: '20',
  PROJECT_CODE_CONTROL: '21',
  LAYER_TEXTURE_CONTROL: '22',
  LAYER_SPECIAL_COMPOUNDS_CONTROL: '23',
  LAYER_COLOUR_CONTROL: '24',
  MEASUREMENTPOINT_NAME: '25',
  FILTERTUBE_NAME_CONTROL: '26',
  WATERSAMPLE_NAME_CONTROL: '27',
  BOTTLENAME_CONTROL: '28',
  MULTI_BARCODE_INPUT_CONTROL: '29',
  SUBLOCATION_INPUT_CONTROL: '30',
  GPSXY_INPUT_CONTROL: '31',
  DESCRIPTION_LIST_INPUT_CONTROL: '32',
  MEETREEKSEN_CONTROL: '33',
  MAP_PREVIEW_CONTROL: '34',
  FOTO_CONTROL: '35',
  TWO_LISTS_INPUT_CONTROL: '36',
  READ_ONLY_MIN_MAX_CONTROL: '37',
  LOGGER_CONTROL: '38'
}

export default {
  MediaClassification: {
    ProjectPhoto: 'ProjectPhoto',
    MapImages: 'MapImages',
    MapLayers: 'MapLayers',
    ProjectDocuments: 'ProjectDocuments',
    KLICFiles: 'KLICFiles',
    LabCertificates: 'LabCertificates'
  }
}
