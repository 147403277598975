import TwsMapping from '@/configurations/app/tws-mapping'
import stringUtils from '@/utils/stringUtils'
import guidUtils from '@/utils/guidUtils'
import Moment from 'moment'

export default {
  // Observations have their own database table - but here we add the observations
  // as key/value pairs to the primary data table to be displayed as table data.
  async MapObservationsToDataTable (dataTable, dataTableObservations) {
    dataTable.forEach((sample) => {
      if (dataTableObservations && Array.isArray(dataTableObservations)) {
        const fromObservations = dataTableObservations.filter((fromObservation) => fromObservation.WsID === sample.WsID)
        fromObservations.forEach((fromObservation) => {
          const toObservation = TwsMapping.observation.find((mapping) => mapping.type.toString() === fromObservation.ObObservationType.toString() && mapping.field !== 'ObMeasurementUnit')
          if (toObservation) {
            sample[toObservation.key] = fromObservation[toObservation.field]
          }
        })
        sample.observations = fromObservations
      } else {
        sample.observations = []
      }
    })

    return dataTable
  },

  // Key/value pairs are undone here and added back to the original secondary data table,
  // in order for the server to be able to proces and store it in the database.
  async MapDataTableToObservations (dataTable, projectId) {
    const toObservations = []

    dataTable.forEach((row) => {
      const observationsWithExistingMappings = []

      Object.keys(row).forEach((samplePropertyKey) => {
        const mappingFound = TwsMapping.observation.find((mapping) => mapping.key === samplePropertyKey)
        if (mappingFound && row[samplePropertyKey]) {
          observationsWithExistingMappings.push({
            mapping: mappingFound,
            value: JSON.parse(JSON.stringify(row[samplePropertyKey]))
          })
          delete row[samplePropertyKey]
        }
      })

      observationsWithExistingMappings.forEach((fromObservation) => {
        let fromObservationFound

        if (row.observations) {
          fromObservationFound = row.observations.find((observationReference) => {
            return observationReference.ObObservationType === fromObservation.mapping.type
          })
        }

        if (fromObservationFound) {
          const field = fromObservation.mapping.field
          fromObservationFound[field] = fromObservation.value
          toObservations.push(fromObservationFound)
        } else {
          const toObservation = {
            ObID: guidUtils.newGuid(),
            WsID: row.WsID,
            WsGuid: row.WsGuid,
            PrID: projectId,
            ObObservationType: fromObservation.mapping.type
          }

          if (fromObservation.mapping.unit !== '-1') {
            toObservation.ObMeasurementUnit = fromObservation.mapping.unit
          }
          const field = fromObservation.mapping.field
          toObservation[field] = fromObservation.value
          toObservations.push(toObservation)
        }
      })
    })
    return { dataTable: dataTable, tblObservations: toObservations }
  },

  async FilterWaterSamplesFromSamples (samplesDataTable) {
    if (!samplesDataTable || Array.isArray(samplesDataTable) === false) {
      return []
    }
    return samplesDataTable.filter(row => ['OL', 'BL'].includes(row.WsMatrixCode) === false)
  },

  async FilterAirSamplesFromSamples (samplesDataTable) {
    if (!samplesDataTable || Array.isArray(samplesDataTable) === false) {
      return []
    }
    return samplesDataTable.filter(row => ['OL', 'BL'].includes(row.WsMatrixCode))
  },

  async FilterSurfaceSamplesFromSamples (samplesDataTable) {
    if (!samplesDataTable || Array.isArray(samplesDataTable) === false) {
      return []
    }
    return samplesDataTable.filter(row => stringUtils.isNullOrEmptyOrWhitespace(row.FtID))
  },

  async FilterGroundSamplesFromSamples (samplesDataTable) {
    if (!samplesDataTable || Array.isArray(samplesDataTable) === false) {
      return []
    }
    return samplesDataTable.filter(row => stringUtils.isNullOrEmptyOrWhitespace(row.FtID) === false)
  },

  async GenerateLastUpdatedAt () {
    return Moment()
      .utc()
      .add(3, 'hours')
      .format('YYYY-MM-DDTHH:mm:ss')
  },

  async FilterAirSampleMatrices (options) {
    return options.filter(option => ['GW', 'OW', 'AW', 'SW', 'UW', 'DW', 'ST', 'SL', 'OV', 'GR', '14'].includes(option.value) === false)
  },

  async FilterGroundWaterSampleMatrices (options) {
    return options.filter(option => ['OW', 'OL', 'BL', 'SL', 'OV', 'GR', '14'].includes(option.value) === false)
  },

  async FilterSurfaceWaterSampleMatrices (options) {
    return options.filter(option => ['GW', 'OL', 'BL', 'SL', 'OV', 'GR', '14'].includes(option.value) === false)
  },

  ValidatePropertyIsUniqueInDataTable (context) {
    const foundItems = context.dataTable.filter(row => {
      return row[context.propertyToValidate].toLowerCase().trim() === context.event.newData[context.propertyToValidate].toLowerCase().trim()
    })

    if (foundItems.length > 1) {
      return false
    }

    if (foundItems.length === 1 && (!context.event.oldData || foundItems[0][context.guidToValidate] !== context.event.oldData[context.guidToValidate])) {
      return false
    }

    return true
  }
}
