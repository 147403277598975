import Vue from 'vue'
import config from '@/configurations/config-environment'
import arrayUtils from '@/utils/arrayUtils'
import { BaseService } from '../baseService'

const dataws = config.apis.dataws

export class ConcernedContactsService extends BaseService {
  searchConcernedContacts(
    searchFilterTypeConcerned = null,
    searchFilterCompanyName = null,
    searchFilterContactPerson = null,
    searchFilterTypeFunctionContactPerson = null,
    pageIndex = 0,
    pageSize = 30
  ) {
    const authorisation = this.getAuthorizationObject()
    const languageCode = this.getLangCodeLongFromShort(authorisation.Language)
    const data = {
      Authorisation: authorisation,
      LanguageCode: languageCode,
      WebserviceVersion: '1.0',
      searchFilterTypeConcerned: searchFilterTypeConcerned,
      searchFilterCompanyName: searchFilterCompanyName,
      searchFilterContactPerson: searchFilterContactPerson,
      searchFilterTypeFunctionContactPerson: searchFilterTypeFunctionContactPerson,
      pageIndex: pageIndex,
      pageSize: pageSize
    }

    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/SearchConcernedContacts`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((resp) => {
        if (resp.status !== 200 || resp.data.ResultCode !== 'Success') {
          throw new Error(`Unable to get concerned contacts. ${resp.data.ResultMessage}`)
        }

        return resp.data.Contacts
      })
  }

  createConcernedContact(concernedContact) {
    // PrID must be string. getProjects always returns projects with integer PrID, so must convert
    if (concernedContact.PrID != null) {
      concernedContact.PrID = concernedContact.PrID.toString()
    }

    const data = {
      data: concernedContact,
      action: 'INSERT'
    }

    data.data.Authorisation = this.getAuthorizationObject()

    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/SetConcernedContact`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((resp) => {
        if (resp.status !== 200 || resp.data.ResultCode !== 'Import_Succes') {
          throw new Error(`Unable to save concerned contact. ${resp.data.ResultMessage}`)
        }

        let tblConcernedCompanies = JSON.parse(resp.data.FileContent).VeldData.tblConcernedCompanies
        tblConcernedCompanies = arrayUtils.formatArray(tblConcernedCompanies)
        return tblConcernedCompanies
      })
  }

  updateConcernedContact(concernedContact) {
    // PrID must be string. getProjects always returns projects with integer PrID, so must convert
    if (concernedContact.PrID != null) {
      concernedContact.PrID = concernedContact.PrID.toString()
    }

    const data = {
      data: concernedContact,
      action: 'UPDATE'
    }

    data.data.Authorisation = this.getAuthorizationObject()

    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/SetConcernedContact`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((resp) => {
        if (resp.status !== 200 || resp.data.ResultCode !== 'Import_Succes') {
          throw new Error(`Unable to save concerned contact. ${resp.data.ResultMessage}`)
        }

        let tblConcernedCompanies = JSON.parse(resp.data.FileContent).VeldData.tblConcernedCompanies
        tblConcernedCompanies = arrayUtils.formatArray(tblConcernedCompanies)
        return tblConcernedCompanies
      })
  }

  deleteConcernedContact(PrID, CcGUID) {
    const data = {
      data: {
        PrID: PrID,
        CcGUID: CcGUID,
        Authorisation: this.getAuthorizationObject()
      },
      action: 'DELETE'
    }

    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/SetConcernedContact`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((resp) => {
        if (resp.status !== 200 || resp.data.ResultCode !== 'Import_Succes') {
          throw new Error(`Unable to delete concerned contacts. ${resp.data.ResultMessage}`)
        }

        return true
      })
  }

  getConcernedContacts(prid) {
    const authorisation = this.getAuthorizationObject()
    const languageCode = this.getLangCodeLongFromShort(authorisation.Language)
    const data = {
      Authorisation: authorisation,
      LanguageCode: languageCode,
      WebserviceVersion: '1.0',
      TypeOfExport: 'Export_FIELD_JSON_v1_0_0',
      Parameters: [
        {
          ParameterType: 'ExportFormat',
          StringValue: 'json'
        },
        {
          ParameterType: 'ProjectID',
          StringValue: prid.toString(),
          FloatValue: prid.toString()
        }
      ],
      logFormat: 'XML'
    }

    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/GetConcernedContacts`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((resp) => {
        if (resp.status !== 200 || resp.data.ResultCode !== 'Export_Succes' || typeof resp.data.Files[0] !== 'object') {
          throw new Error(`Unable to get concerned contacts. ${resp.data.ResultMessage}`)
        }
        const veldData = JSON.parse(resp.data.Files[0].FileContent).VeldData
        const tblConcernedCompanies = arrayUtils.formatArray(veldData.tblConcernedCompanies)
        return {
          tblConcernedCompanies
        }
      })
  }
}
