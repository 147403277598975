import Vue from 'vue'
import config from '@/configurations/config-environment'
import arrayUtils from '@/utils/arrayUtils'
import { BaseService } from '../baseService'

const dataws = config.apis.dataws

export class SoilSampleService extends BaseService {
  createSoilSamples(projectId, measurementPointId, soilSamples) {
    const data = this.createSoilSampleObject(projectId, measurementPointId, soilSamples, 'INSERT')
    return this.setSoilSamples(data).then((resp) => {
      this.evaluateResponse(resp, 'createSoilSamples')

      let tblJars = JSON.parse(resp.data.FileContent).VeldData.tblJars
      tblJars = arrayUtils.formatArray(tblJars)
      return tblJars
    })
  }

  createSoilSampleObject(projectId, measurementPointGuid, soilSamples, action) {
    return {
      data: {
        PrID: projectId,
        MpGuid: measurementPointGuid,
        Jars: soilSamples,
        Authorisation: this.getAuthorizationObject()
      },
      action: action
    }
  }

  updateSoilSamples(projectId, measurementPointGuid, soilSamples) {
    const data = this.createSoilSampleObject(projectId, measurementPointGuid, soilSamples, 'UPDATE')
    return this.setSoilSamples(data).then((resp) => {
      this.evaluateResponse(resp, 'updateSoilSamples')

      let tblJars = JSON.parse(resp.data.FileContent).VeldData.tblJars
      tblJars = arrayUtils.formatArray(tblJars)
      tblJars.forEach((jar) => {
        jar.MpGuid = measurementPointGuid
      })
      return tblJars
    })
  }

  setSoilSamples(data) {
    // eslint-disable-next-line no-extend-native
    Date.prototype.toJSON = function () {
      let month = '' + (this.getMonth() + 1)
      let day = '' + this.getDate()
      let year = this.getFullYear()

      if (month.length < 2) {
        month = '0' + month
      }
      if (day.length < 2) {
        day = '0' + day
      }

      return [year, month, day].join('-') + 'T00:00:00'
    }
    const d = JSON.stringify(data)
    return Vue.http.post(`${dataws}ITWDataRestService_V1_0/SetSoilSamples`, d, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  deleteSoilSample(projectId, measurementPointGuid, jarGuid) {
    let data = this.createSoilSampleObject(projectId, measurementPointGuid, null, 'DELETE')
    data.data.JrGuid = jarGuid

    return this.setSoilSamples(data).then((resp) => {
      this.evaluateResponse(resp, 'deleteSoilSample')
      return true
    })
  }

  getSoilSamples(projectId, measurementPointGuid) {
    const authorisation = this.getAuthorizationObject()
    const languageCode = this.getLangCodeLongFromShort(authorisation.Language)
    const data = {
      Authorisation: authorisation,
      LanguageCode: languageCode,
      WebserviceVersion: '1.0',
      TypeOfExport: 'Export_FIELD_JSON_v1_0_0',
      Parameters: [
        {
          ParameterType: 'ExportFormat',
          StringValue: 'json'
        },
        {
          ParameterType: 'ProjectID',
          StringValue: projectId.toString(),
          FloatValue: projectId.toString()
        },
        {
          ParameterType: 'MeasurementPointGuid',
          StringValue: measurementPointGuid.toString()
        }
      ],
      logFormat: 'XML'
    }
    return Vue.http.post(`${dataws}ITWDataRestService_V1_0/GetSoilSamples`, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  getSoilSamplesParsed(projectId, measurementPointGuid) {
    const authorisation = this.getAuthorizationObject()
    const languageCode = this.getLangCodeLongFromShort(authorisation.Language)
    const data = {
      Authorisation: authorisation,
      LanguageCode: languageCode,
      WebserviceVersion: '1.0',
      TypeOfExport: 'Export_FIELD_JSON_v1_0_0',
      Parameters: [
        {
          ParameterType: 'ExportFormat',
          StringValue: 'json'
        },
        {
          ParameterType: 'ProjectID',
          StringValue: projectId.toString(),
          FloatValue: projectId.toString()
        },
        {
          ParameterType: 'MeasurementPointGuid',
          StringValue: measurementPointGuid.toString()
        }
      ],
      logFormat: 'XML'
    }
    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/GetSoilSamples`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((resp) => {
        this.evaluateResponse(resp, 'getSoilSamplesParsed')

        const veldData = JSON.parse(resp.data.Files[0].FileContent).VeldData
        const tblJars = arrayUtils.formatArray(veldData.tblJars)

        return {
          tblJars
        }
      })
  }

  evaluateResponse(response, requestName) {
    if (
      response.status !== 200 ||
      !response.data ||
      (response.data.ResultCode !== 'Import_Succes' && response.data.ResultCode !== 'Export_Succes') ||
      (response.data.ResultMessage !== null && response.data.ResultMessage.toLowerCase().includes('error'))
    ) {
      throw new Error(`Request ${requestName} failed with message '${response.data.ResultMessage}'`)
    }
  }
}
