'use strict'

import Vue from 'vue'
import cookie from '@/utils/cacheProviders/cookieCacheProvider'
import config from '@/configurations/config.js'
import azureStateUtils from '@/utils/azureStateUtils'

import { LOCAL_STORAGE_USERINFO_KEY } from '../../configurations/app/config-constants'

export default {
  process (query) {
    let apiPath = config.sso.ssoServer + '/access_token'
    let code = query.code

    if (code) {
      let state = query.state

      let data = {
        'code': code,
        'grant_type': 'authorization_code',
        'client_id': config.sso.client_id,
        'redirect_uri': config.sso.return_uri
      }

      return Vue.http.post(apiPath, data, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        emulateJSON: true
      })
        .then(res => {
          let token = res.data

          if (!token) { throw new Error('Invalid token') }

          cookie.set('access_token', token.access_token, token.expires_in)
          cookie.set('refresh_token', token.refresh_token, token.expires_in)
          cookie.set('language', token.language, token.expires_in)
          cookie.set('licensenumber', token.licensenumber, token.expires_in)
          cookie.set('username', token.name, token.expires_in)

          return { url: state || config.sso.return_uri || 'projects', language: token.language }
        })
    }

    let returnedToken = query
    if (returnedToken.token) {
      return new Promise((resolve, reject) => {
        cookie.set('access_token', returnedToken.token, returnedToken.expires_in)
        cookie.set('language', returnedToken.language, returnedToken.expires_in)
        cookie.set('licensenumber', returnedToken.licensenumber, returnedToken.expires_in)
        cookie.set('username', returnedToken.username, returnedToken.expires_in)

        return resolve({ url: 'projects', language: returnedToken.language })
      })
    }
  },

  signOut () {
    cookie.remove('refresh_token')
    cookie.remove('licensenumber')
    cookie.remove('username')
    cookie.remove('idToken')

    this.removeUserInfo()
    location.href = config.sso.ssoServer + '/OAuth/SignOff?url=' + encodeURIComponent(config.sso.azureLogoutUrl) + encodeURIComponent(config.apps.web)
  },

  isAuthenticated () {
    return cookie.get('access_token')
  },

  redirectToSSOAzure () {
    const lang = cookie.get('language')
    location.href = `${config.sso.ssoUrl}&ui_locales=${lang}`
  },

  redirectToPreLoginPage (query) {
    const state = azureStateUtils.getQueryParameterAzureState(this.$route)
    if (state && state.redirect_url) {
      location.href = config.sso.preLoginUrl + '?redirect_url=' + state.redirect_url
    }
    const redirectUrlWithoutQueryParams = window.location.href.split('?')[0]
    location.href = config.sso.preLoginUrl + '?redirect_url=' + encodeURIComponent(redirectUrlWithoutQueryParams)
  },

  redirectToSSO () {
    location.href = config.sso.ssoUrl
  },

  redirectToAzureB2cRegister () {
    const lang = cookie.get('language')
    if (lang) location.href = `${config.sso.azureB2cUrlRegister}&ui_locales=${lang}`
    else location.href = config.sso.azureB2cUrlRegister
  },

  redirectToAzureB2cLoginSelection () {
    const lang = cookie.get('language')
    if (lang) location.href = `${config.sso.azureB2cUrlLoginSelection}&ui_locales=${lang}`
    else location.href = config.sso.azureB2cUrlLoginSelection
  },

  isUserConnectedToAzure() {
    if (this.isUserInfoAvailable()) {
      const userInfo = this.getUserInfo()
      return userInfo.AzureId !== undefined && userInfo.AzureId !== null
    }
    return false
  },

  getUserInfo() {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_USERINFO_KEY))
  },

  isUserInfoAvailable() {
    return localStorage.getItem(LOCAL_STORAGE_USERINFO_KEY) !== null
  },

  storeUserInfo(userInfo) {
    localStorage.setItem(LOCAL_STORAGE_USERINFO_KEY, JSON.stringify(userInfo))
  },

  removeUserInfo() {
    localStorage.removeItem(LOCAL_STORAGE_USERINFO_KEY)
  },

  inProcess (query) {
    if (!query) { return false }

    return query.code || query.token
  },

  getAuthenticatedUser () {
    let language = cookie.get('language')
    let licenseNumber = cookie.get('licensenumber')
    let username = cookie.get('username')
    let token = cookie.get('access_token')

    return {
      username, language, licenseNumber, token
    }
  },

  isAuthenticationRequired (route) {
    // always return true if not explicitly set to auth_required = false
    return !(route && route.meta && route.meta.auth_required === false)
  }
}
