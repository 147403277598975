import Vue from 'vue'
import config from '@/configurations/config-environment'
import $store from '@/vuex/store'
import * as localForage from 'localforage'
import _ from 'lodash'
import { ProjectService } from '@/services/DataWS/projectService'
import { BaseService } from '../baseService'

const projectService = new ProjectService()
const dataws = config.apis.dataws
const templateCacheStore = localForage.createInstance({
  name: 'templateCacheStore'
})
export class TemplateService extends BaseService {
  clearLocalCache() {
    return templateCacheStore.clear()
  }

  getFieldTemplateFromTemplateId(screenName, templateId, fieldCode) {
    const authorisation = this.getAuthorizationObject()
    const languageCode = this.getLangCodeLongFromShort(authorisation.Language)
    const data = {
      Authorisation: authorisation,
      LanguageCode: languageCode,
      WebserviceVersion: '1.0',
      ScreenName: screenName,
      TemplateID: templateId,
      FieldCode: fieldCode
    }

    const cacheKey = `${screenName};${templateId || ''};${languageCode}`
    return templateCacheStore
      .getItem(cacheKey)
      .then((storeResult) => {
        if (storeResult && storeResult.cacheDate && storeResult.cacheDate === new Date().toDateString()) {
          return storeResult
        }

        return Vue.http
          .post(`${dataws}ITWDataRestService_V1_0/GetTemplatesData`, data, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then((result) => {
            result.cacheDate = new Date().toDateString()

            return templateCacheStore.setItem(cacheKey, result).then(() => {
              return result
            })
          })
      })
      .then((result) => {
        if (result.data && result.data.ResultCode === 'TiComponentsCode_Succes') {
          const templates = result.data.Result.templates

          let templateID = data.TemplateID

          if (typeof templateID === 'undefined' || templateID === 'undefined') {
            templateID = '-1'
          }

          let template = _.find(templates, { id: templateID })

          if (typeof template === 'undefined' || template === 'undefined') {
            templateID = '-1'
            template = _.find(templates, { id: templateID })
          }
          return template
        }
      })
  }

  getDefaultFieldTemplate(screenName, templateId) {
    return this.getFieldTemplateFromTemplateId(screenName, templateId)
  }

  getFieldTemplate(screenName, projectID, fieldCode) {
    let currentProject = $store.state.projects.find((pr) => pr.PrID === projectID)

    if (currentProject && currentProject.PrTemplateID && currentProject.PrTemplateID !== '') {
      return this.getFieldTemplateFromTemplateId(screenName, currentProject.PrTemplateID, fieldCode)
    }

    return projectService.getSingleProject(projectID).then((projectRes) => {
      if (projectRes.data && projectRes.data.ResultCode === 'Export_Succes') {
        const project = JSON.parse(projectRes.data.Files[0].FileContent).VeldData.tblProjects

        $store.state.projects.push(project)
        return this.getFieldTemplateFromTemplateId(screenName, project.PrTemplateID, fieldCode)
      }
    })
  }

  getWsMatrixCode(projectID) {
    const screenName = 'frmWatersampleDetails'
    const fieldCode = 'WsMatrixCode'
    return this.getFieldTemplate(screenName, projectID, fieldCode)
  }

  getLaSoilType(projectID) {
    const screenName = 'frmLayerDetails'
    const fieldCode = 'LaSoilType'
    return this.getFieldTemplate(screenName, projectID, fieldCode)
  }

  getFiTypeCode(projectID) {
    const screenName = 'frmFinishingDetails'
    const fieldCode = 'FiTypeCode'
    return this.getFieldTemplate(screenName, projectID, fieldCode)
  }

  getMpTypeCode(projectID) {
    const screenName = 'frmMeasurementPointDetails'
    const fieldCode = 'MpTypeCode'
    return this.getFieldTemplate(screenName, projectID, fieldCode)
  }
}
