import Vue from 'vue'
import config from '@/configurations/config-environment'
import * as localForage from 'localforage'
import { BaseService } from '../baseService'
import JSZip from 'jszip'

const mediaws = config.apis.mediaws

const mediaThumbnailCacheStore = localForage.createInstance({
  name: 'mediaThumbnailCacheStore'
})
export class MediaService extends BaseService {
  clearLocalCache() {
    mediaThumbnailCacheStore.iterate((value, key) => {
      if (new Date(value.cacheDate) < new Date(this.getDateStringForSixDaysAgo())) {
        mediaThumbnailCacheStore.removeItem(key)
      }
    })
  }

  getDateStringForSixDaysAgo() {
    return new Date(Date.now() - 6 * 24 * 60 * 60 * 1000).toDateString()
  }

  async getMediaThumbnail(projectGuid, fileName, mediaClassification) {
    const thumbnailFromCache = await this.tryGetThumbnailFromCache(projectGuid, fileName, mediaClassification)

    if (thumbnailFromCache) {
      return thumbnailFromCache
    }

    const authorisation = this.getAuthorizationObject()
    const languageCode = this.getLangCodeLongFromShort(authorisation.Language)
    const data = {
      Authorisation: authorisation,
      LanguageCode: languageCode,
      WebserviceVersion: '1.0',
      MediaClassification: mediaClassification,
      Parameters: [
        {
          ParameterType: 'ProjectGuid',
          StringValue: projectGuid
        },
        {
          ParameterType: 'Filename',
          StringValue: fileName
        }
      ]
    }

    return Vue.http
      .post(`${mediaws}ITWMediaRestService_V1_0/DownloadMediaThumbnail`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(async (result) => {
        if (this.isResponseSuccess(result) && this.isThumbnailResponseDataValid(result.data)) {
          return this.setThumbnailInCache(fileName, result.data.FileContent, projectGuid, mediaClassification)
        }
      })
  }

  async getMediaThumbnailBatch(projectGuid, fileNames, mediaClassification) {
    const fileNamesToIncludeInServiceRequest = []
    const existingCachedThumbnails = []

    for (let i = 0; i < fileNames.length; i++) {
      const thumbnailFromCache = await this.tryGetThumbnailFromCache(projectGuid, fileNames[i], mediaClassification)

      if (thumbnailFromCache) {
        existingCachedThumbnails.push(thumbnailFromCache)
      } else {
        fileNamesToIncludeInServiceRequest.push(fileNames[i])
      }
    }

    const authorisation = this.getAuthorizationObject()
    const languageCode = this.getLangCodeLongFromShort(authorisation.Language)
    const data = {
      Authorisation: authorisation,
      LanguageCode: languageCode,
      WebserviceVersion: '1.0',
      MediaClassification: mediaClassification,
      Parameters: [
        {
          ParameterType: 'ProjectGuid',
          StringValue: projectGuid
        },
        {
          ParameterType: 'FileNames',
          StringValues: fileNamesToIncludeInServiceRequest
        }
      ]
    }

    if (fileNamesToIncludeInServiceRequest.length === 0) {
      return existingCachedThumbnails
    }

    return Vue.http
      .post(`${mediaws}ITWMediaRestService_V1_0/DownloadMediaThumbnailBatch`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(async (result) => {
        if (this.isResponseSuccess(result) && this.isThumbnailBatchResponseDataValid(result.data)) {
          const cachedThumbnailsFromServiceRequest = await this.cacheThumbnailsFromBatchResponse(result, projectGuid, mediaClassification)
          return cachedThumbnailsFromServiceRequest.concat(existingCachedThumbnails)
        }
      })
  }

  isThumbnailBatchResponseDataValid(data) {
    return data && data.FileContentPerFileName && Object.keys(data.FileContentPerFileName).length && data.ResultCode === 'Download_Succes'
  }

  isThumbnailResponseDataValid(data) {
    return data && data.FileContent && data.FileContent.length && data.ResultCode === 'Download_Succes'
  }

  async tryGetThumbnailFromCache(projectGuid, fileName, MediaClassification) {
    const cacheKey = `${projectGuid};${fileName};${MediaClassification}`
    const thumbnailCacheObject = await mediaThumbnailCacheStore.getItem(cacheKey)
    if (thumbnailCacheObject) {
      return this.refreshThumbnailCacheDate(cacheKey, thumbnailCacheObject)
    }
  }

  async cacheThumbnailsFromBatchResponse(result, projectGuid, mediaClassification) {
    const cachedThumbnails = []
    for (const fileName of Object.keys(result.data.FileContentPerFileName)) {
      const cachedThumbnail = await this.setThumbnailInCache(fileName, result.data.FileContentPerFileName[fileName], projectGuid, mediaClassification)
      cachedThumbnails.push(cachedThumbnail)
    }
    return cachedThumbnails
  }

  async setThumbnailInCache(fileName, fileContent, projectGuid, mediaClassification) {
    const cacheKey = `${projectGuid};${fileName};${mediaClassification}`
    const thumbnailCacheObject = {
      fileName: fileName,
      fileContent: fileContent,
      mediaClassification: mediaClassification,
      cacheDate: new Date().toDateString()
    }
    return mediaThumbnailCacheStore.setItem(cacheKey, thumbnailCacheObject)
  }

  async refreshThumbnailCacheDate(cacheKey, thumbnailCacheObject) {
    thumbnailCacheObject.cacheDate = new Date().toDateString()
    return mediaThumbnailCacheStore.setItem(cacheKey, thumbnailCacheObject)
  }

  async clearThumbnailFromCache(fileName, projectGuid, mediaClassification) {
    const cacheKey = `${projectGuid};${fileName};${mediaClassification}`
    return mediaThumbnailCacheStore.removeItem(cacheKey)
  }

  getProjectMediaLocationLink(projectGuid, MediaClassification, fileName) {
    const authorisation = this.getAuthorizationObject()
    const languageCode = this.getLangCodeLongFromShort(authorisation.Language)
    const data = {
      Authorisation: authorisation,
      LanguageCode: languageCode,
      WebserviceVersion: '1.0',
      MediaClassification,
      Parameters: [
        {
          ParameterType: 'ProjectGuid',
          StringValue: projectGuid
        }
      ]
    }

    if (fileName) {
      let fileNameParameter = {
        ParameterType: 'Filename',
        StringValue: fileName
      }
      data.Parameters.push(fileNameParameter)
    }

    return Vue.http.post(`${mediaws}ITWMediaRestService_V1_0/GetLink`, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  getProjectMediaList(projectGuid, MediaClassification) {
    const authorisation = this.getAuthorizationObject()
    const languageCode = this.getLangCodeLongFromShort(authorisation.Language)
    const data = {
      Authorisation: authorisation,
      LanguageCode: languageCode,
      WebserviceVersion: '1.0',
      MediaClassification,
      Parameters: [
        {
          ParameterType: 'ProjectGuid',
          StringValue: projectGuid
        }
      ]
    }

    return Vue.http.post(`${mediaws}ITWMediaRestService_V1_0/GetMediaList`, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  uploadMedia(projectGuid, fileName, fileContent, mediaClassification) {
    const authorisation = this.getAuthorizationObject()
    const languageCode = this.getLangCodeLongFromShort(authorisation.Language)
    const data = {
      Authorisation: authorisation,
      LanguageCode: languageCode,
      WebserviceVersion: '1.0',

      MediaClassification: mediaClassification,
      FileContent: fileContent,
      Parameters: [
        {
          ParameterType: 'ProjectGuid',
          StringValue: projectGuid
        },
        {
          ParameterType: 'Filename',
          StringValue: fileName
        }
      ]
    }

    const extension = fileName.split('.').pop().toUpperCase()
    const zipFileExtensions = ['ZIP', 'KLIP']

    const endpoint = zipFileExtensions.includes(extension) ? 'UploadZip' : 'UploadMedia'
    return Vue.http.post(`${mediaws}ITWMediaRestService_V1_0/${endpoint}`, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).catch(error => {
      return error
    })
  }

  async sessionUpload(projectGuid, files, MediaClassification) {
    const authorisation = this.getAuthorizationObject()
    const languageCode = this.getLangCodeLongFromShort(authorisation.Language)
    let zip = new JSZip()
    let fileName = projectGuid + '_files.zip'

    for (let file of files) {
      let filename = file.name
      zip.file(filename, file)
    }
    const zipBlob = await zip.generateAsync({ type: 'base64' })

    const data = {
      Authorisation: authorisation,
      LanguageCode: languageCode,
      WebserviceVersion: '1.0',

      FileContent: zipBlob,
      MediaClassification: MediaClassification,
      Parameters: [
        {
          ParameterType: 'ProjectGuid',
          StringValue: projectGuid
        },
        {
          ParameterType: 'Filename',
          StringValue: fileName
        }
      ]
    }
    return Vue.http.post(`${mediaws}ITWMediaRestService_V1_0/SessionUploadMedia`, data, {
      headers: {
        'Content-Type': `application/json`
      }
    })
  }

  async deleteMedia(projectGuid, fileName, mediaClassification) {
    const authorisation = this.getAuthorizationObject()
    const languageCode = this.getLangCodeLongFromShort(authorisation.Language)
    const data = {
      Authorisation: authorisation,
      LanguageCode: languageCode,
      WebserviceVersion: '1.0',

      MediaClassification: mediaClassification,
      Parameters: [
        {
          ParameterType: 'ProjectGuid',
          StringValue: projectGuid
        },
        {
          ParameterType: 'Filename',
          StringValue: fileName
        }
      ]
    }

    return Vue.http.post(`${mediaws}ITWMediaRestService_V1_0/DeleteMedia`, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(async (result) => {
      await this.clearThumbnailFromCache(fileName, projectGuid, mediaClassification)
      return result
    })
  }
}
