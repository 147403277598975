import Vue from 'vue'
import config from '@/configurations/config-environment'
import arrayUtils from '@/utils/arrayUtils'
import { BaseService } from '../baseService'

const dataws = config.apis.dataws

export class LayerService extends BaseService {
  // TODO refactor the throw error. The unit test should receive an throw
  updateLayers(projectId, measurementPointGuid, layers) {
    const data = {
      data: {
        PrID: projectId.toString(),
        MpGuid: measurementPointGuid,
        Layers: layers,
        Authorisation: this.getAuthorizationObject()
      },
      action: 'UPDATE'
    }

    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/SetLayers`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((resp) => this.parseSetLayersResponse(resp))
      .catch((err) => {
        console.error(err)
      })
  }

  createLayers(projectId, measurementPointGuid, layers) {
    const data = {
      data: {
        PrID: projectId.toString(),
        MpGuid: measurementPointGuid,
        Layers: layers,
        Authorisation: this.getAuthorizationObject()
      },
      action: 'INSERT'
    }
    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/SetLayers`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((resp) => this.parseSetLayersResponse(resp))
      .catch((err) => {
        console.error(err)
      })
  }

  deleteLayer(projectId, measurementPointGuid, layerGuid, layers) {
    const data = {
      data: {
        PrID: projectId,
        MpGuid: measurementPointGuid,
        DeleteLaGuid: layerGuid,
        Layers: layers,
        Authorisation: this.getAuthorizationObject()
      },
      action: 'DELETE'
    }

    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/SetLayers`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((resp) => this.parseSetLayersResponse(resp))
  }

  getLayers(projectID, measurementpointGuid) {
    const authorisation = this.getAuthorizationObject()
    const languageCode = this.getLangCodeLongFromShort(authorisation.Language)
    const data = {
      Authorisation: authorisation,
      LanguageCode: languageCode,
      WebserviceVersion: '1.0',
      TypeOfExport: 'Export_FIELD_JSON_v1_0_0',
      Parameters: [
        {
          ParameterType: 'ExportFormat',
          StringValue: 'json'
        },
        {
          ParameterType: 'ProjectID',
          StringValue: projectID.toString(),
          FloatValue: projectID.toString()
        },
        {
          ParameterType: 'MeasurementPointGuid',
          StringValue: measurementpointGuid.toString()
        }
      ],
      logFormat: 'XML'
    }

    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/GetLayers`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((resp) => {
        this.evaluateResponse(resp, 'getLayers')

        const veldData = JSON.parse(resp.data.Files[0].FileContent).VeldData
        const tblMeasurementPoints = arrayUtils.formatArray(veldData.tblMeasurementPoints)
        let tblLayers = arrayUtils.formatArray(veldData.tblLayers)

        tblLayers.forEach((layer) => {
          layer.MpGUID = measurementpointGuid
        })

        return {
          tblMeasurementPoints,
          tblLayers
        }
      })
  }

  parseSetLayersResponse(reponse) {
    if (reponse.status !== 200 || reponse.data.ResultCode !== 'Import_Succes') throw new Error(`Unable to save layers. ${reponse.data.ResultMessage}`)
    let layers = JSON.parse(reponse.data.FileContent).VeldData.tblLayers
    layers = arrayUtils.formatArray(layers)
    return layers
  }

  evaluateResponse(response, requestName) {
    if (
      response.status !== 200 ||
      !response.data ||
      (response.data.ResultCode !== 'Import_Succes' && response.data.ResultCode !== 'Export_Succes') ||
      (response.data.ResultMessage !== null && response.data.ResultMessage.toLowerCase().includes('error'))
    ) {
      throw new Error(`Request ${requestName} failed with message '${response.data.ResultMessage}'`)
    }
  }
}
