import router from '../router'

export default {
  findComponentByName (name, source) {
    // MeasurementPointMap is not an official component. The correct component is measurementPoint
    if (name === 'measurementPointMap') {
      name = 'measurementPoint'
    }
    if (!source) {
      source = router.app
    }
    for (var i = 0; i < source.$children.length; i++) {
      let $child = source.$children[i]

      if ($child.$options && $child.$options.name && $child.$options.name === name) {
        return $child
      } else {
        let match = this.findComponentByName(name, $child)

        if (match) {
          return match
        }
      }
    }

    return false
  }
}
