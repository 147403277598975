import proj4 from 'proj4'
import OlGeomPoint from 'ol/geom/Point'
import * as olProj from 'ol/proj'
import * as olProj4 from 'ol/proj/proj4'
import GeometryUtil from '@/utils/geometryUtil'
import { CodeListService } from '@/services/DataWS/codeListService'

const codeListService = new CodeListService()

export default {

  coordinateSystems: [
    { GcCode: 'ZD', name: 'Zelf gedefinieerd systeem', WGS84Bounds: { latMin: '-180.0000', longMin: '-90.0000', latMax: '180.0000', longMax: '90.0000' }, projectedBounds: { XMin: '-100000.00', YMin: '-100000.00', XMax: '100000.00', YMax: '100000.00' }, epsg: 'EPSG:0000', formula: '+proj=sterea +lat_0=52.15616055555555 +lon_0=5.38763888888889 +k=0.999908 +x_0=155000 +y_0=463000 +ellps=bessel +units=m +towgs84=565.2369,50.0087,465.658,-0.406857330322398,0.350732676542563,-1.8703473836068,4.0812 +no_defs <>' },
    { GcCode: 'WG', name: 'WGS84', WGS84Bounds: { latMin: '-180.0000', longMin: '-90.0000', latMax: '180.0000', longMax: '90.0000' }, projectedBounds: { XMin: '-180.0000', YMin: '-90.0000', XMax: '180.0000', YMax: '90.0000' }, epsg: 'EPSG:4326', formula: '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs ' },
    { GcCode: 'RD', name: 'RD coordinatensysteem', WGS84Bounds: { latMin: '3.3700', longMin: '50.7500', latMax: '7.2100', longMax: '53.56700' }, projectedBounds: { XMin: '12628.0541', YMin: '308179.0423', XMax: '283594.4779', YMax: '611063.1429' }, epsg: 'EPSG:28992', formula: '+proj=sterea +lat_0=52.15616055555555 +lon_0=5.38763888888889 +k=0.999908 +x_0=155000 +y_0=463000 +ellps=bessel +units=m +towgs84=565.2369,50.0087,465.658,-0.406857330322398,0.350732676542563,-1.8703473836068,4.0812 +no_defs <>' },
    { GcCode: 'LU', name: 'Luxemburg, gebaseerd op LUREF', WGS84Bounds: { latMin: '5.7300', longMin: '49.4800', latMax: '6.5300', longMax: '50.2100' }, projectedBounds: { XMin: '48250.9349', YMin: '60645.7231', XMax: '106224.1264', YMax: '141869.5837' }, epsg: 'EPSG:2169', formula: '+proj=tmerc +lat_0=49.83333333333334 +lon_0=6.166666666666667 +k=1 +x_0=80000 +y_0=100000 +ellps=intl +towgs84=-193,13.7,-39.3,-0.41,-2.933,2.688,0.43 +units=m +no_defs ' },
    { GcCode: 'I2', name: 'Italie, zone 2', WGS84Bounds: { latMin: '12.0000', longMin: '36.6000', latMax: '18.5300', longMax: '47.0900' }, projectedBounds: { XMin: '2251632.4276', YMin: '4054757.3924', XMax: '2835795.3321', YMax: '5221314.5428' }, epsg: 'EPSG:3004', formula: '+proj=tmerc +lat_0=0 +lon_0=15 +k=0.9996 +x_0=2520000 +y_0=0 +ellps=intl +units=m +no_defs ' },
    { GcCode: 'GE', name: 'Geografisch coordinatiesysteem | ETRS89', WGS84Bounds: { latMin: '-10.6700', longMin: '34.5000', latMax: '31.5500', longMax: '71.0500' }, projectedBounds: { XMin: '-10.6700', YMin: '34.5000', XMax: '31.5500', YMax: '71.0500' }, epsg: 'EPSG:4258', formula: '+proj=longlat +ellps=GRS80 +no_defs ' },
    { GcCode: 'F9', name: 'Frankrijk, RGF93 (Lambert projectie)', WGS84Bounds: { latMin: '-9.6200', longMin: '41.1800', latMax: '10.3000', longMax: '51.5400' }, projectedBounds: { XMin: '-357823.2365', YMin: '6037008.6939', XMax: '1313632.3628', YMax: '7230727.3772' }, epsg: 'EPSG:2154', formula: '+proj=lcc +lat_1=49 +lat_2=44 +lat_0=46.5 +lon_0=3 +x_0=700000 +y_0=6600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs ' },
    { GcCode: 'ET', name: 'UTM ETRS 89', WGS84Bounds: { latMin: ' 0.0000', longMin: '38.5000', latMax: '6.0000', longMax: '62.3300' }, projectedBounds: { XMin: '238379.2278', YMin: '4265559.3497', XMax: '761620.7722', YMax: '6914547.3835' }, epsg: 'EPSG:25831', formula: '+proj=utm +zone=31 +ellps=GRS80 +units=m +no_defs ' },
    { GcCode: 'ED', name: 'UTM ED50', WGS84Bounds: { latMin: '0.0000', longMin: '38.6000', latMax: '6.0000', longMax: '67.0000' }, projectedBounds: { XMin: '238730.0252', YMin: '4276730.7754', XMax: '761269.9748', YMax: '7434723.1222' }, epsg: 'EPSG:23031', formula: '+proj=utm +zone=31 +ellps=intl +units=m +no_defs ' },
    { GcCode: 'BL', name: 'Lambert 1972 (België)', WGS84Bounds: { latMin: '2.5400', longMin: '49.5100', latMax: '6.4000', longMax: '51.5000' }, projectedBounds: { XMin: '17736.0314', YMin: '23697.0977', XMax: '297289.9391', YMax: '245375.4223' }, epsg: 'EPSG:31370', formula: '+proj=lcc +lat_1=51.16666723333333 +lat_2=49.8333339 +lat_0=90 +lon_0=4.367486666666666 +x_0=150000.013 +y_0=5400088.438 +ellps=intl +towgs84=-106.8686,52.2978,-103.7329,0.3366,-0.457,1.8422,-1.2747 +units=m +no_defs' },
    { GcCode: '93', name: 'RGF 93 - Lambert 93', WGS84Bounds: { latMin: '-9.6200', longMin: '41.1800', latMax: '10.3000', longMax: '51.5400' }, projectedBounds: { XMin: '-357823.2365', YMin: '6037008.6939', XMax: '1313632.3628', YMax: '7230727.3772' }, epsg: 'EPSG:2154', formula: '+proj=lcc +lat_1=49 +lat_2=44 +lat_0=46.5 +lon_0=3 +x_0=700000 +y_0=6600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs ' },
    { GcCode: 'F5', name: 'RGF 93 - Lambert CC42 (Zone 1 du 41°N au 43°N - Corse)', WGS84Bounds: { latMin: '-1.8000', longMin: '41.0000', latMax: '9.6000', longMax: '43.0000' }, projectedBounds: { XMin: '1296335.9393', YMin: '1100257.6521', XMax: '2254779.1544', YMax: '1331811.4895' }, epsg: 'EPSG:3942', formula: '+proj=lcc +lat_1=41.25 +lat_2=42.75 +lat_0=42 +lon_0=3 +x_0=1700000 +y_0=1200000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs ' },
    { GcCode: 'F6', name: 'RGF 93 - Lambert CC43 (Zone 2 sud du 44°N)', WGS84Bounds: { latMin: '-1.8000', longMin: '42.0000', latMax: '7.7500', longMax: '44.0000' }, projectedBounds: { XMin: '1302506.4384', YMin: '2100044.3184', XMax: '2093357.4402', YMax: '2322094.8666' }, epsg: 'EPSG:3943', formula: '+proj=lcc +lat_1=42.25 +lat_2=43.75 +lat_0=43 +lon_0=3 +x_0=1700000 +y_0=2200000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs ' },
    { GcCode: 'F7', name: 'RGF 93 - Lambert CC44 (Zone 3 du 43°N au 45°N)', WGS84Bounds: { latMin: '-1.8000', longMin: '43.0000', latMax: '7.7500', longMax: '45.0000' }, projectedBounds: { XMin: '1308798.4230', YMin: '3100052.0968', XMax: '2087131.0906', YMax: '3322128.5732' }, epsg: 'EPSG:3944', formula: '+proj=lcc +lat_1=43.25 +lat_2=44.75 +lat_0=44 +lon_0=3 +x_0=1700000 +y_0=3200000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs ' },
    { GcCode: 'F8', name: 'RGF 93 - Lambert CC45 (Zone 4 du 44°N au 46°N)', WGS84Bounds: { latMin: '-1.3500', longMin: '44.0000', latMax: '7.7500', longMax: '46.0000' }, projectedBounds: { XMin: '1351247.6083', YMin: '4098245.7319', XMax: '2080786.3812', YMax: '4321920.5102' }, epsg: 'EPSG:3945', formula: '+proj=lcc +lat_1=44.25 +lat_2=45.75 +lat_0=45 +lon_0=3 +x_0=1700000 +y_0=4200000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs ' },
    { GcCode: 'L6', name: 'RGF 93 - Lambert CC46 (Zone 5 du 45°N au 47°N)', WGS84Bounds: { latMin: '-2.4500', longMin: '45.0000', latMax: '7.1500', longMax: '47.0000' }, projectedBounds: { XMin: '1270591.6480', YMin: '5097385.5589', XMax: '2027087.9253', YMax: '5325334.0729' }, epsg: 'EPSG:3946', formula: '+proj=lcc +lat_1=45.25 +lat_2=46.75 +lat_0=46 +lon_0=3 +x_0=1700000 +y_0=5200000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs ' },
    { GcCode: 'L7', name: 'RGF 93 - Lambert CC47 (Zone 6 du 46°N au 48°N)', WGS84Bounds: { latMin: '-4.6000', longMin: '46.0000', latMax: '7.8000', longMax: '48.0000' }, projectedBounds: { XMin: '1112163.1498', YMin: '6100231.0759', XMax: '2071615.7622', YMax: '6338667.6413' }, epsg: 'EPSG:3947', formula: '+proj=lcc +lat_1=46.25 +lat_2=47.75 +lat_0=47 +lon_0=3 +x_0=1700000 +y_0=6200000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs ' },
    { GcCode: 'L8', name: 'RGF 93 - Lambert CC48 (Zone 7 du 47°N au 49°N)', WGS84Bounds: { latMin: '-5.0000', longMin: '47.0000', latMax: '8.2700', longMax: '49.0000' }, projectedBounds: { XMin: '1092603.2215', YMin: '7102517.8254', XMax: '2100529.4893', YMax: '7341541.8099' }, epsg: 'EPSG:3948', formula: '+proj=lcc +lat_1=47.25 +lat_2=48.75 +lat_0=48 +lon_0=3 +x_0=1700000 +y_0=7200000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs ' },
    { GcCode: 'L9', name: 'RGF 93 - Lambert CC49 (Zone 8 du 48°N au 50°N)', WGS84Bounds: { latMin: '-5.0000', longMin: '48.0000', latMax: '8.2700', longMax: '50.0000' }, projectedBounds: { XMin: '1104062.1794', YMin: '8102449.7137', XMax: '2092985.7591', YMax: '8341410.9019' }, epsg: 'EPSG:3949', formula: '+proj=lcc +lat_1=48.25 +lat_2=49.75 +lat_0=49 +lon_0=3 +x_0=1700000 +y_0=8200000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs ' },
    { GcCode: 'L0', name: 'RGF 93 - Lambert CC50 (Zone 9 au nord du 49°N)', WGS84Bounds: { latMin: '-9.9500', longMin: '49.0000', latMax: '8.2700', longMax: '52.0000' }, projectedBounds: { XMin: '1338040.0982', YMin: '9100766.7009', XMax: '2085321.7705', YMax: '9324263.6899' }, epsg: 'EPSG:3950', formula: '+proj=lcc +lat_1=49.25 +lat_2=50.75 +lat_0=50 +lon_0=3 +x_0=1700000 +y_0=9200000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs ' },
    { GcCode: 'PN', name: 'NTF(Paris) - Lambert Nord standard', WGS84Bounds: { latMin: ' -5.2000', longMin: '48.1500', latMax: '8.2300', longMax: '51.1000' }, projectedBounds: { XMin: ' 40063.0848', YMin: '67022.9279', XMax: '1038149.3679', YMax: '404377.9733' }, epsg: 'EPSG:27561', formula: '+proj=lcc +lat_1=49.50000000000001 +lat_0=49.50000000000001 +lon_0=0 +k_0=0.999877341 +x_0=600000 +y_0=200000 +a=6378249.2 +b=6356515 +towgs84=-168,-60,320,0,0,0,0 +pm=paris +units=m +no_defs ' },
    { GcCode: 'PC', name: 'NTF(Paris) - Lambert Centre standard', WGS84Bounds: { latMin: '-4.7000', longMin: '45.4500', latMax: '7.6700', longMax: '48.1500' }, projectedBounds: { XMin: '50198.0586', YMin: '64100.6841', XMax: '1016966.9382', YMax: '373525.1009' }, epsg: 'EPSG:27562', formula: '+proj=lcc +lat_1=46.8 +lat_0=46.8 +lon_0=0 +k_0=0.99987742 +x_0=600000 +y_0=200000 +a=6378249.2 +b=6356515 +towgs84=-168,-60,320,0,0,0,0 +pm=paris +units=m +no_defs ' },
    { GcCode: 'PS', name: 'NTF(Paris) - Lambert Sud standard', WGS84Bounds: { latMin: '-1.7600', longMin: '42.3300', latMax: '7.7700', longMax: '45.4500' }, projectedBounds: { XMin: '262373.4731', YMin: '11755.1415', XMax: '1047663.1958', YMax: '364038.4062' }, epsg: 'EPSG:27563', formula: '+proj=lcc +lat_1=44.10000000000001 +lat_0=44.10000000000001 +lon_0=0 +k_0=0.999877499 +x_0=600000 +y_0=200000 +a=6378249.2 +b=6356515 +towgs84=-168,-60,320,0,0,0,0 +pm=paris +units=m +no_defs ' },
    { GcCode: 'CS', name: 'NTF(Paris) - Lambert Corse standard', WGS84Bounds: { latMin: '8.5000', longMin: '41.3300', latMax: '9.6000', longMax: '43.0500' }, projectedBounds: { XMin: '501997.4013', YMin: '111734.6001', XMax: '607585.0937', YMax: '309328.6374' }, epsg: 'EPSG:27564', formula: '+proj=lcc +lat_1=42.16500000000001 +lat_0=42.16500000000001 +lon_0=0 +k_0=0.99994471 +x_0=234.358 +y_0=185861.369 +a=6378249.2 +b=6356515 +towgs84=-168,-60,320,0,0,0,0 +pm=paris +units=m +no_defs ' },
    { GcCode: 'P1', name: 'NTF(Paris) - Lambert Zone II carto', WGS84Bounds: { latMin: '-5.2000', longMin: '48.1500', latMax: '8.2300', longMax: '51.1000' }, projectedBounds: { XMin: '40063.0848', YMin: '1067022.9279', XMax: '1038149.3679', YMax: '1404377.9733' }, epsg: 'EPSG:27571', formula: '+proj=lcc +lat_1=49.50000000000001 +lat_0=49.50000000000001 +lon_0=0 +k_0=0.999877341 +x_0=600000 +y_0=1200000 +a=6378249.2 +b=6356515 +towgs84=-168,-60,320,0,0,0,0 +pm=paris +units=m +no_defs ' },
    { GcCode: 'P2', name: 'NTF(Paris) - Lambert Zone II carto (Lambert II étendu)', WGS84Bounds: { latMin: '-5.2000', longMin: '42.2500', latMax: '8.2300', longMax: '51.1000' }, projectedBounds: { XMin: '-22841.3905', YMin: '1712212.6192', XMax: '1087335.9605', YMax: '2703971.1254' }, epsg: 'EPSG:27572', formula: '+proj=lcc +lat_1=46.8 +lat_0=46.8 +lon_0=0 +k_0=0.99987742 +x_0=600000 +y_0=2200000 +a=6378249.2 +b=6356515 +towgs84=-168,-60,320,0,0,0,0 +pm=paris +units=m +no_defs ' },
    { GcCode: 'P3', name: 'NTF(Paris) - Lambert Zone III carto', WGS84Bounds: { latMin: '-1.7600', longMin: '42.3300', latMax: '7.7700', longMax: '45.4500' }, projectedBounds: { XMin: '262373.4731', YMin: '3011755.1415', XMax: '1047663.1958', YMax: '3364038.4062' }, epsg: 'EPSG:27573', formula: '+proj=lcc +lat_1=44.10000000000001 +lat_0=44.10000000000001 +lon_0=0 +k_0=0.999877499 +x_0=600000 +y_0=3200000 +a=6378249.2 +b=6356515 +towgs84=-168,-60,320,0,0,0,0 +pm=paris +units=m +no_defs ' },
    { GcCode: 'P4', name: 'NTF(Paris) - Lambert Zone IV carto', WGS84Bounds: { latMin: '8.5000', longMin: '41.3300', latMax: '9.6000', longMax: '43.0500' }, projectedBounds: { XMin: '501997.4013', YMin: '4111734.6001', XMax: '607585.0937', YMax: '4309328.6374' }, epsg: 'EPSG:27574', formula: '+proj=lcc +lat_1=42.16500000000001 +lat_0=42.16500000000001 +lon_0=0 +k_0=0.99994471 +x_0=234.358 +y_0=4185861.369 +a=6378249.2 +b=6356515 +towgs84=-168,-60,320,0,0,0,0 +pm=paris +units=m +no_defs ' },
    { GcCode: '32', name: 'ETRS89 / UTM zone 32N', WGS84Bounds: { latMin: '-16.1', longMin: '40.18', latMax: '32.88', longMax: '84.17' }, projectedBounds: { XMin: '-1877994.66', YMin: '836715.13', XMax: '3932281.56', YMax: '9440581.95' }, epsg: 'EPSG:25832', formula: '+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs ' },
    { GcCode: '33', name: 'ETRS89 / UTM zone 33N', WGS84Bounds: { latMin: '-16.1', longMin: '40.18', latMax: '32.88', longMax: '84.17' }, projectedBounds: { XMin: '-2465144.80', YMin: '4102893.55', XMax: '776625.76', YMax: '9408555.22' }, epsg: 'EPSG:25833', formula: '+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs ' },
    { GcCode: '29', name: 'ETRS89 / ETRS-TM29', WGS84Bounds: { latMin: '-12.0000', longMin: '36.0000', latMax: '-6.0000', longMax: '62.3300' }, projectedBounds: { XMin: '229578.6300', YMin: '3988111.9622', XMax: '770421.3700', YMax: '6914547.3835' }, epsg: 'EPSG:3041', formula: '+proj=utm +zone=29 +ellps=GRS80 +units=m +no_defs' },
    { GcCode: '30', name: 'ETRS89 / ETRS-TM30', WGS84Bounds: { latMin: '-6.0000', longMin: '34.7500', latMax: '0.0000', longMax: '62.3300' }, projectedBounds: { XMin: '225370.7346', YMin: '3849419.9580', XMax: '774629.2654', YMax: '6914547.3835' }, epsg: 'EPSG:3042', formula: '+proj=utm +zone=30 +ellps=GRS80 +units=m +no_defs' },
    { GcCode: '31', name: 'ETRS89 / ETRS-TM31', WGS84Bounds: { latMin: '0.0000', longMin: '38.5000', latMax: '6.0000', longMax: '62.3300' }, projectedBounds: { XMin: '238379.2278', YMin: '4265559.3497', XMax: '761620.7722', YMax: '6914547.3835' }, epsg: 'EPSG:3043', formula: '+proj=utm +zone=31 +ellps=GRS80 +units=m +no_defs' },
    { GcCode: '28', name: 'ETRS89 / ETRS-TM28', WGS84Bounds: { latMin: '-18.0000', longMin: '27.6000', latMax: '-12.0000', longMax: '66.5500' }, projectedBounds: { XMin: '203872.6488', YMin: '3056487.4888', XMax: '796127.3512', YMax: '7384416.42333' }, epsg: 'EPSG:3040', formula: '+proj=utm +zone=28 +ellps=GRS80 +units=m +no_defs' },
    { GcCode: '78', name: 'WGS 84 / UTM zone 32N', WGS84Bounds: { latMin: '6.0000', longMin: '0.0000', latMax: '12.0000', longMax: '84.0000' }, projectedBounds: { XMin: '166021.4431', YMin: '0.0000', XMax: '833978.5569', YMax: '9329005.1825' }, epsg: 'EPSG:32632', formula: '+proj=utm +zone=32 +ellps=WGS84 +datum=WGS84 +units=m +no_defs' },
    { GcCode: '79', name: 'WGS 84 / UTM zone 33N', WGS84Bounds: { latMin: '12.0000', longMin: '0.0000', latMax: '18.0000', longMax: '84.0000' }, projectedBounds: { XMin: '166021.4431', YMin: '0.0000', XMax: '833978.5569', YMax: '9329005.1825' }, epsg: 'EPSG:32633', formula: '+proj=utm +zone=33 +ellps=WGS84 +datum=WGS84 +units=m +no_defs' },
    { GcCode: '80', name: 'Monte Mario / Italy zone', WGS84Bounds: { latMin: '6.6500', longMin: '8.8000', latMax: '12.0000', longMax: '47.0500' }, projectedBounds: { XMin: '1241482.0019', YMin: '973563.1609', XMax: '1830078.9331', YMax: '5215189.0853' }, epsg: 'EPSG:3003', formula: '+proj=tmerc +lat_0=0 +lon_0=9 +k=0.9996 +x_0=1500000 +y_0=0 +ellps=intl +units=m +no_defs' }
  ],

  /**
   * Load projection definitions so they can be used throughout TerraIndex Web.
   */
  loadProj4Defs () {
    // Define the coordinate systems that may be used
    this.coordinateSystems.forEach(coordinateSystem => proj4.defs(coordinateSystem.epsg, coordinateSystem.formula))
    olProj4.register(proj4)

    if (!olProj.get('EPSG:28992')) {
      console.error('Failed to register projection in OpenLayers')
    }
  },

  /**
   * Returns a Promise that resolves to the codelist of coordinate systems, but filtered to just those that have conversion as seen above.
   */
  getEnabledCoordinateSystems () {
    return codeListService.getCoordinateSystems()
      .then(filterCoordinateSystems.bind(null, this.coordinateSystems))

    function filterCoordinateSystems (coordinateSystemsSupported, coordinateSystemsCodeList) {
      return coordinateSystemsCodeList.filter((s) => {
        return coordinateSystemsSupported.some((c) => {
          return c.GcCode === s.GcCode
        })
      })
    };
  },

  isCodeSupported (code) {
    return this.coordinateSystems.some((supported) => {
      return supported.GcCode === code
    })
  },

  /**
   * Returns a transformation of point from fromProjection to toProjection
   * @param {ol.geom.Point} point the point to transform
   * @param {String} fromProjection - The projection in which point is defined (in EPSG format, not RD or WGS84 for instance)
   * @param {String} toProjection - The projection to which point 'point' must be transformed (in EPSG format, not RD or WGS84 for instance)
   * @return {ol.geom.Point} the transformed point
   */
  transformPointToProjection (point, fromProjection, toProjection) {
    if (fromProjection === toProjection) {
      return point
    }
    this.loadProj4Defs()
    if (point.flatCoordinates && point.flatCoordinates.length === 2 && !isNaN(point.flatCoordinates[0]) && !isNaN(point.flatCoordinates[1])) { // so open layers can not crash
      // eslint-disable-next-line new-cap
      return new OlGeomPoint(olProj.transform((point.getCoordinates && point.getCoordinates()) || [point.flatCoordinates[0], point.flatCoordinates[1]], fromProjection, toProjection))
    } else {
      return point
    }
  },

  getNameFromEPSG (epsgCode) {
    var system = this.coordinateSystems.filter(s => s.epsg === epsgCode)[0]
    return system ? system.name : 'Unknown'
  },

  /**
   * Get the fraction digits of the projection.
   * @param projection Identifier of the projection, e.g. EPSG:4326
   */
  getFractionDigitsOfProjection (projection) {
    return projection === 'EPSG:4326' ? 8 : 2
  },

  convertCoordsToPointGeometry (xCoord, yCoord, fromProjection, toProjection) {
    const point = new OlGeomPoint([parseFloat(xCoord), parseFloat(yCoord)])
    const convertedPoint = this.transformPointToProjection(point, fromProjection, toProjection)
    const convertPointToStringInput = { x: convertedPoint.flatCoordinates[0], y: convertedPoint.flatCoordinates[1] }
    const convertedPointAsString = GeometryUtil.getWKTString(convertPointToStringInput)
    return convertedPointAsString
  }
}
