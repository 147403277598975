import Vue from 'vue'
import config from '@/configurations/config-environment'
import { BaseService } from '../baseService'

const algorithmService = config.apis.algorithmService

export class AlgorithmService extends BaseService {
  getSuggestedAnalysisSamples(settings, project) {
    const data = {
      data: {
        settings,
        inputContentBase64: btoa(unescape(encodeURIComponent(JSON.stringify(project))))
      }
    }

    return Vue.http
      .post(algorithmService, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        if (!response || response.status !== 200 || !response.data || !response.data.data) {
          throw new Error(`Unable to getSuggestedAnalysisSamples. ${response.data.ResultMessage}`)
        }

        return response.data.data
      })
      .catch((error) => {
        console.error(error)
      })
  }
}
