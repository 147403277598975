import Vue from 'vue'
import config from '@/configurations/config-environment'
import * as localForage from 'localforage'
import { BaseService } from '../baseService'

const dataws = config.apis.dataws

const borelogPreviewCacheStore = localForage.createInstance({
  name: 'borelogPreviewCacheStore'
})

const borelogTemplateCacheStore = localForage.createInstance({
  name: 'borelogTemplateCacheStore'
})
export class BoreProfileService extends BaseService {
  clearLocalCache() {
    return borelogTemplateCacheStore.clear()
  }

  getListOfAllBorelogs(licenseNumber) {
    const data = {
      Authorisation: this.getAuthorizationObject(),
      WebserviceVersion: '1.0'
    }
    return Vue.http
      .post(`${dataws}ITWBoreprofileRestService_V1_0/GetTemplateNames`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        return JSON.parse(response.data.Content)
      })
  }

  generateBorelogPreview(projectId, measurementPointID, settings, measurementPointGuid, templateId, forceReload) {
    const cacheKey = `${projectId};${measurementPointGuid};${templateId}`
    return borelogPreviewCacheStore.getItem(cacheKey).then((storeResult) => {
      if (storeResult && storeResult.cacheDate && storeResult.cacheDate === new Date().toDateString() && !forceReload) {
        return storeResult.data
      }

      const authorisation = this.getAuthorizationObject()
      const languageCode = this.getLangCodeLongFromShort(authorisation.Language)
      const data = {
        Authorisation: authorisation,
        WebserviceVersion: '1.0',
        WebserviceSession: 'false',
        BorelogParameters: {
          Language: languageCode.toUpperCase(),
          Layout: settings,
          OutputType: 'PNG',
          PageNumber: 1,
          Boreholes: [
            { BoreHoleID: measurementPointID, ProjectID: projectId }
          ]
        }
      }

      return Vue.http
        .post(`${dataws}ITWBoreprofileRestService_V1_0/GenerateBorelog`, data, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then((response) => {
          if (response.statusText !== 'OK') throw new Error('statusText was no OK')
          return borelogPreviewCacheStore.setItem(cacheKey, { data: response.data.Borelogs, cacheDate: new Date().toDateString() }).then((result) => {
            return result.data
          })
        })
    })
  }

  getTemplateByTemplateId(templateID) {
    const cacheKey = `${templateID};`
    return borelogTemplateCacheStore.getItem(cacheKey).then((storeResult) => {
      if (storeResult && storeResult.cacheDate && storeResult.cacheDate === new Date().toDateString()) {
        return storeResult.data
      }

      const data = {
        Authorisation: this.getAuthorizationObject(),
        WebserviceVersion: '1.0',
        UseZipStream: false,
        Parameters: [
          {
            ParameterType: 'TemplateID',
            FloatValue: templateID,
            StringValue: templateID
          },
          {
            ParameterType: 'ApplicationCode',
            FloatValue: '61',
            StringValue: '61'
          }
        ]
      }

      return Vue.http.post(`${dataws}ITWBoreprofileRestService_V1_0/GetSpecificTemplate`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        return borelogTemplateCacheStore.setItem(cacheKey, { data: response.data.Content, cacheDate: new Date().toDateString() }).then((result) => {
          return result.data
        })
      })
    })
  }
}
