import 'devextreme/dist/css/dx.common.css' // TODO: Do we need this, added by npx install
import 'devextreme/dist/css/dx.light.css' // SAME AS ABOVE
import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import { sync } from 'vuex-router-sync'
import store from '@/vuex/store'
import VueResource from 'vue-resource'
import VueTables from 'vue-tables-2'
import i18n from '@/configurations/app/config-locales'
import config from '@/configurations/config'
import authenticationProviders from '@/utils/authenticationProvider'
import TerraIndexEditorValidator from '@/utils/terraIndexEditorValidator'

import $ from 'jquery'
import Promise from 'bluebird'
import keepAlive from '@/utils/keepCUAlive'
import cookie from '@/utils/cacheProviders/cookieCacheProvider'
import VueObserveVisibility from 'vue-observe-visibility'
import stringUtils from './utils/stringUtils'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faGift,
  faArrowDown,
  faArrowUp,
  faXmark,
  faPlus,
  faCheck,
  faMapMarkedAlt,
  faSort,
  faSortUp,
  faSortDown,
  faChevronLeft,
  faChevronDown,
  faChevronUp,
  faSpinner,
  faExclamationTriangle,
  faMagnifyingGlass
} from '@fortawesome/free-solid-svg-icons'
import vTitle from 'vuejs-title'

library.add(
  faGift,
  faArrowDown,
  faArrowUp,
  faXmark,
  faPlus,
  faCheck,
  faMapMarkedAlt,
  faSort,
  faSortUp,
  faSortDown,
  faChevronLeft,
  faChevronDown,
  faChevronUp,
  faSpinner,
  faExclamationTriangle,
  faMagnifyingGlass
)

window.$ = window.jQuery = $ // some plugins use it, do not remove

let authenticationProvider = authenticationProviders[config.platform].default

sync(store, router)

Vue.config.productionTip = false

Vue.use(VueResource)
Vue.use(VueTables.ClientTable)
Vue.use(TerraIndexEditorValidator)
Vue.use(VueObserveVisibility)
Vue.use(vTitle, {
  cssClass: 'main-tooltip',
  fontSize: '12px',
  padding: '5px 15px',
  bgColor: '#111927'
})
Vue.component('font-awesome-icon', FontAwesomeIcon)

window.Promise = Promise

/**
 * Install the keepAlive handler
 */
const stopKeepAlive = keepAlive(
  // The SSO url from the main config
  config.sso.ssoServer,
  // Inline function returning the current authentication state
  // If this returns false then the keepAlive will not call
  () => {
    return authenticationProvider.isAuthenticated()
  },
  // Gets the currently authenticated token
  () => {
    return cookie.get('access_token')
  })

Vue.http.interceptors.push((request, next) => {
  if (authenticationProvider.isAuthenticated()) {
    let token = authenticationProvider.getAuthenticatedUser().token
    if (!request.headers) {
      // make sure headers are defined
      request.headers = {}
    }
    request.headers.Authorization = 'Bearer ' + (token || '')
  }
  return next(res => {
    let authenticationStatuses = [400, 401, 403]
    if (!res.ok && authenticationStatuses.indexOf(res.status) !== -1 &&
      !res.url.includes('ConnectUserToAzureLogin') &&
      !res.url.includes('GetSingleClassicTokenByAzureJwt')) {
      stopKeepAlive()

      cookie.remove('refresh_token')
      cookie.remove('licensenumber')
      cookie.remove('username')
      cookie.remove('idToken')
      cookie.remove('access_token')

      authenticationProvider.redirectToPreLoginPage(request.query)
    }
  })
})

/* Vue resource fix, our server returns content-type, resource expects case sensitive: Content-Type */
Vue.http.interceptors.unshift(function (request, next) {
  next(function (response) {
    if (typeof response.headers['content-type'] !== 'undefined') {
      response.headers['Content-Type'] = response.headers['content-type']
    }
  })
})

/**
 * Create and mounts the application.
 */
const createApplication = () => {
  new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
  }).$mount('#app')
}

createApplication()

let navigatorLanguage = navigator.language

if (navigatorLanguage.length === 5) {
  navigatorLanguage = stringUtils.convertFourToTwoLanguageCode(navigatorLanguage)
}

const cookieLang = cookie.get('language')
if (!cookieLang || cookieLang.length === 5) {
  cookie.set('language', navigatorLanguage)
}

const cookieLangShort = cookie.get('lang')
if (!cookieLangShort || cookieLangShort.length === 5) {
  cookie.set('lang', navigatorLanguage)
}

Vue.config.lang = cookie.get('lang')

let stylesheets = [
  'assets/css/bootstrap.min.css',
  'assets/css/bootstrap-theme.min.css',
  'assets/css/_shared/base.css',
  'assets/css/sweetalert.css',
  'assets/css/google.css',
  'assets/css/font-awesome.min.css',
  'assets/css/glyphicons.css',
  'assets/css/animate.min.css',
  'assets/css/bootstrap-datetimepicker.min.css',
  'assets/css/simplebar.min.css',
  'bower_components/bootstrap-toggle/css/bootstrap-toggle.css',
  'assets/fontawesome/css/all.css',
  'assets/fontawesome/css/sharp-light.css'
]

stylesheets
  .map(url => {
    return $("<link rel='stylesheet' type='text/css' />").attr('href', `${config.root}${url}`)
  })
  .forEach($tag => {
    $tag.appendTo($('HEAD'))
  })

let javascripts = [
  'assets/js/sweetalert.min.js',
  'assets/js/moment.min.js',
  'assets/js/bootstrap.min.js',
  'assets/js/bootstrap-datetimepicker.min.js',
  'assets/js/simplebar.js',
  'bower_components/bootstrap-toggle/js/bootstrap-toggle.js'
]

javascripts
  .map(url => {
    var absoluteUrl = url
    if (url.substr(0, 2) !== '//') {
      absoluteUrl = config.root + url
    }

    return $("<script type='text/javascript' />").attr('src', `${absoluteUrl}`)
  })
  .forEach($tag => {
    $tag.appendTo($('HEAD'))
  })

window.onbeforeunload = () => {
  if (store.state.workingObjectChanged) {
    return confirm(i18n.t('message.confirm_exit'))
  }
}
