import Vue from 'vue'
import config from '@/configurations/config-environment'
import { BaseService } from '../baseService'

const managementWs = config.apis.managementWs

export class SettingsService extends BaseService {
  getSettingByName(settingName) {
    const data = {
      Authorisation: this.getAuthorizationObject(),
      SettingName: settingName
    }

    return Vue.http
      .post(`${managementWs}SettingsRestService_V1_0/GetSettingByName`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(`Unable to fetch table settings. ${response.data.Result}`)
        }
        return response
      })
  }

  updateSetting(settingName, settings) {
    const data = {
      Authorisation: this.getAuthorizationObject(),
      SettingGroup: 'AppTableSettings',
      SettingName: settingName,
      Settings: settings
    }

    return Vue.http
      .post(`${managementWs}SettingsRestService_V1_0/UpdateSetting`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(`Unable to update table settings. ${response.data.ResultMessage}`)
        }

        return true
      })
  }
}
