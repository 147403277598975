import Vue from 'vue'
import config from '@/configurations/config-environment'
import { BaseService } from '../baseService'

const managementWs = config.apis.managementWs

export class LoggingService extends BaseService {
  logFrontEndError(errorType, errorMessage, bugReport) {
    const data = {
      Authorisation: this.getAuthorizationObject(),
      ApplicationCode: 61,
      ErrorMessage: `${errorMessage} #BUGREPORT# ${bugReport}`,
      ErrorType: errorType,
      FunctionDescription: 'FrontEnd'
    }

    return Vue.http.post(`${managementWs}/LoggingRestService/LogFrontEndError`, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  LogFrontEndActivity(activityType, activityMessage) {
    const data = {
      Authorisation: this.getAuthorizationObject(),
      ApplicationCode: 61,
      ActivityMessage: activityMessage,
      ActivityType: activityType
    }

    return Vue.http.post(`${managementWs}/LoggingRestService/LogFrontEndActivity`, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }
}
