import Vue from 'vue'
import moment from 'moment'
import config from '@/configurations/config-environment'
import arrayUtils from '@/utils/arrayUtils'
import { BaseService } from '../baseService'

const dataws = config.apis.dataws

export class SublocationService extends BaseService {
  getSubLocations(projectID) {
    const authorisation = this.getAuthorizationObject()
    const languageCode = this.getLangCodeLongFromShort(authorisation.Language)
    const data = {
      Authorisation: authorisation,
      LanguageCode: languageCode,
      WebserviceVersion: '1.0',
      TypeOfExport: 'Export_FIELD_JSON_v1_0_0',
      Parameters: [
        {
          ParameterType: 'ExportFormat',
          StringValue: 'json'
        },
        {
          ParameterType: 'ProjectID',
          StringValue: projectID.toString(),
          FloatValue: projectID.toString()
        }
      ],
      logFormat: 'XML'
    }

    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/GetSublocations`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((resp) => {
        if (resp.status !== 200 || resp.data.ResultCode !== 'Export_Succes' || typeof resp.data.Files[0] !== 'object') {
          throw new Error(`Unable to get finishings. ${resp.data.ResultMessage}`)
        }

        const veldData = JSON.parse(resp.data.Files[0].FileContent).VeldData
        const tblSubLocations = arrayUtils.formatArray(veldData.tblSublocations)

        return {
          tblSubLocations
        }
      })
  }

  updateSublocation(sublocation) {
    sublocation.Authorisation = this.getAuthorizationObject()
    sublocation.SlDateLastChanged = moment().utc().add(3, 'hour').format('YYYY-MM-DD HH:mm:ss')
    const data = {
      data: sublocation,
      action: 'UPDATE'
    }

    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/SetSubLocation`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        if (response.status !== 200 || response.data.ResultCode !== 'Import_Succes') throw new Error(`Unable to save sublocation. ${response.data.ResultMessage}`)
        let tblSubLocations = JSON.parse(response.data.FileContent).VeldData.tblSublocations
        tblSubLocations = arrayUtils.formatArray(tblSubLocations)
        return tblSubLocations
      })
  }

  createSublocation(sublocation) {
    sublocation.Authorisation = this.getAuthorizationObject()
    sublocation.SlDateLastChanged = moment().utc().add(3, 'hour').format('YYYY-MM-DD HH:mm:ss')
    const data = {
      data: sublocation,
      action: 'INSERT'
    }

    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/SetSubLocation`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        if (response.status !== 200 || response.data.ResultCode !== 'Import_Succes') throw new Error(`Unable to save sublocation. ${response.data.ResultMessage}`)
        let tblSubLocations = JSON.parse(response.data.FileContent).VeldData.tblSublocations
        tblSubLocations = arrayUtils.formatArray(tblSubLocations)
        return tblSubLocations
      })
  }

  deleteSubLocation(projectID, sublocationGUID) {
    const data = {
      data: {
        PrID: projectID,
        SlGuid: sublocationGUID,
        Authorisation: this.getAuthorizationObject()
      },
      action: 'DELETE'
    }

    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/SetSubLocation`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        if (response.status !== 200 || response.data.ResultCode !== 'Import_Succes') throw new Error(`Unable to delete sublocation. ${response.data.ResultMessage}`)

        return true
      })
  }
}
