export default {
  setQueryParameter(url, parameter, value) {
    return url + (url.includes('?') ? '&' : '?') + parameter + '=' + encodeURIComponent(value)
  },

  setQueryParameterAzureState(url, currentRoute) {
    const state = {}
    const query = currentRoute?.query || {}

    if (query.redirect_url) {
      state.redirect_url = query.redirect_url
    }

    if (query.invite_token) {
      state.invite_token = query.invite_token
    }

    if (Object.keys(state).length === 0) {
      return url
    }

    return this.setQueryParameter(url, 'state', JSON.stringify(state))
  },

  getQueryParameterAzureState(route) {
    if (!route || !route.query || !route.query.state) {
      return false
    }

    const state = JSON.parse(route.query.state)

    if (!state || !route.query.id_token || !state.redirect_url) {
      return state || false
    }

    if (state.redirect_url.toLowerCase().includes('pincode')) {
      state.redirect_url = this.setQueryParameter(state.redirect_url, 'id_token', route.query.id_token)
    }

    return state
  }
}
