// memory storage provider
// author: Jerome Chen
import cookie from 'vue-cookie'
import moment from 'moment'

const defaultDays = 1

export default {
  init: (options) => {
    options = options || {}
    return this
  },

  get: itemName => cookie.get(itemName),

  set: (itemName, value, expiresDate) => {
    let days = defaultDays
    if (expiresDate) {
      days = (moment() - moment(expiresDate)) / 1000 / 60 / 60 / 24
    }
    cookie.set(itemName, value, days || defaultDays)
    return this
  },

  remove: (itemName) => {
    cookie.delete(itemName)
    return true
  }
}
