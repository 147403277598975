/* eslint-disable no-unused-vars */
import authenticationProviders from '@/utils/authenticationProvider'
import config from '@/configurations/config-environment'

const authenticationProvider = authenticationProviders[config.platform].default

export class BaseService {
  getAuthorizationObject() {
    const currentUser = authenticationProvider.getAuthenticatedUser()

    const authObject = {
      Username: currentUser.username,
      Licensenumber: currentUser.licenseNumber,
      ApplicationCode: config.applicationCodes[config.env][config.platform],
      Language: currentUser.language
    }

    return authObject
  }

  getLangCodeLongFromShort(shortCode) {
    if (shortCode) {
      shortCode = shortCode.toLowerCase()
    }

    let result = 'eng'
    switch (shortCode) {
      case 'nl':
        result = 'nld'
        break
      case 'en':
        result = 'eng'
        break
      case 'fr':
        result = 'fra'
        break
      case 'es':
        result = 'spa'
        break
      case 'de':
        result = 'deu'
        break
      case 'it':
        result = 'ita'
        break
    }
    return result
  }

  isResponseSuccess(response) {
    return response && response.status === 200 && response.data
  }
}
