import wmsNL from './config-map/wms-nl'
import wmsBE from './config-map/wms-be'

const config = {
  BASELAYERS: [
    {
      name: 'Open Street Maps',
      source: 'OSM',
      invertMapTextColour: true,
      defaultVisible: true
    },
    {
      name: 'Bing - Aerial',
      source: 'BING',
      invertMapTextColour: false,
      defaultVisible: false,
      settings: {
        maxZoom: 18,
        key: 'Ao9wqOnCiDvABI4LnDdguzUE0lbF1PiAcDSYHkKdezCage1xuUXY2emXAuHzdOUX',
        imagerySet: 'Aerial'
      }
    },
    {
      name: 'Bing - Aerial + Labels',
      source: 'BING',
      invertMapTextColour: false,
      defaultVisible: false,
      settings: {
        maxZoom: 18,
        key: 'Ao9wqOnCiDvABI4LnDdguzUE0lbF1PiAcDSYHkKdezCage1xuUXY2emXAuHzdOUX',
        imagerySet: 'AerialWithLabels'
      }
    },
    {
      name: 'Bing - Road',
      source: 'BING',
      invertMapTextColour: true,
      defaultVisible: false,
      settings: {
        maxZoom: 18,
        key: 'Ao9wqOnCiDvABI4LnDdguzUE0lbF1PiAcDSYHkKdezCage1xuUXY2emXAuHzdOUX',
        imagerySet: 'Road'
      }
    },
    {
      name: 'WorldTopoMap',
      source: 'ESRI',
      invertMapTextColour: true,
      defaultVisible: false,
      settings: {
        url:
          'https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}'
      }
    },
    {
      name: 'WorldLightGrayBase',
      source: 'ESRI',
      invertMapTextColour: true,
      defaultVisible: false,
      settings: {
        url:
          'https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}'
      }
    },
    {
      name: 'WorldStreetMap',
      source: 'ESRI',
      invertMapTextColour: true,
      defaultVisible: false,
      settings: {
        url:
          'https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}'
      }
    },
    {
      name: 'WorldImagery',
      source: 'ESRI',
      invertMapTextColour: false,
      defaultVisible: false,
      settings: {
        url:
          'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
      }
    }
  ],
  WMSLAYERS: function (licensenumber) {
    return [
      ...wmsNL,
      ...wmsBE
    ]
  },
  companyLayers: function (licensenumber, username) {
    if (licensenumber === '10') {
      return [
        {
          name: 'Project',
          type: 'WMS',
          country: 'Company',
          url: 'https://demo.geoserver.terraindex.com/geoserver/ITWDEMO/wms',
          sublayers: [
            { id: 'bbk_how_pnt', title: 'bbk_how_pnt' },
            { id: 'boor_pnt', title: 'boor_pnt' },
            { id: 'project_pnt', title: 'project_pnt' },
            { id: 'sublocaties_lin', title: 'sublocaties_lin' },
            { id: 'testresults_bbk', title: 'testresults_bbk' },
            { id: 'testresults_wbb_gr', title: 'testresults_wbb_gr' },
            { id: 'wbb_grond_how_pnt', title: 'wbb_grond_how_pnt' },
            { id: 'wbb_water_how_pnt', title: 'wbb_water_how_pnt' }
          ],
          viewparams: 'username:' + username
        }
      ]
    } else if (licensenumber === '944') {
      return [
        {
          name: 'Schiphol',
          type: 'WMS',
          country: 'Company',
          url: 'https://geoserver.terraindex.com/geoserver/terrascan/wms',
          sublayers: [
            {
              id: 'terrascan:2016_schiphol_totaal_2d_versie_2018_2004',
              title: '2016_schiphol_totaal_2d_versie_2018_2004'
            }
          ]
        }
      ]
    } else {
      return []
    }
  }
}
export default config
