
export default {
  /**
   * Parse WKT string into object with x, y and z coordinate splitted.
   * @param WKTString String in the form 'POINT (null, null [,null])'.
   * @returns {{x: string, y: string, z: string}}
   */
  parseWKTString (WKTString) {
    if (WKTString === '') {
      return null
    }
    let coordinates = WKTString.substring(WKTString.lastIndexOf('(') + 1, WKTString.lastIndexOf(')'))
    let split = coordinates.split(' ')
    return { x: split[0], y: split[1], z: split[2] }
  },

  /**
   * Convert object to a WKT string. (TODO: add support for geometries other than points)
   * @param obj Object containing the information of a geometry.
   */
  getWKTString (obj) {
    if (obj.z) {
      return 'POINT (' + obj.x + ' ' + obj.y + ' ' + obj.z + ')'
    } else {
      return 'POINT (' + obj.x + ' ' + obj.y + ')'
    }
  }
}
