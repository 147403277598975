import Vue from 'vue'
import moment from 'moment'
import config from '@/configurations/config-environment'
import arrayUtils from '@/utils/arrayUtils'
import { BaseService } from '../baseService'

const dataws = config.apis.dataws

export class SummaryAndConclusionService extends BaseService {
  updateSummaryAndConclusion(summaryAndConclusion) {
    summaryAndConclusion.Authorisation = this.getAuthorizationObject()
    summaryAndConclusion.SmDateLastChanged = moment().utc().add(3, 'hour').format('YYYY-MM-DD HH:mm:ss')
    const data = {
      data: summaryAndConclusion,
      action: 'UPDATE'
    }

    if (data.data.SmType === undefined) return

    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/SetSummaryAndConclusion`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        if (response.status !== 200 || response.data.ResultCode !== 'Import_Succes') throw new Error(`Unable to save summary and conclusion. ${response.data.ResultMessage}`)

        const veldData = JSON.parse(response.data.FileContent).VeldData
        const summaryAndConclusions = arrayUtils.formatArray(veldData.tblSummaryConclusions)

        return summaryAndConclusions
      })
  }

  deleteSummaryAndConclusion(projectID, smGUID) {
    const data = {
      data: {
        PrID: projectID,
        SmGUID: smGUID,
        Authorisation: this.getAuthorizationObject()
      },
      action: 'DELETE'
    }

    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/SetSummaryAndConclusion`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        if (response.status !== 200 || response.data.ResultCode !== 'Import_Succes') throw new Error(`Unable to delete summary and conclusion. ${response.data.ResultMessage}`)

        return true
      })
  }

  createSummaryAndConclusion(summaryAndConclusion) {
    summaryAndConclusion.Authorisation = this.getAuthorizationObject()
    summaryAndConclusion.SmDateLastChanged = moment().utc().add(3, 'hour').format('YYYY-MM-DD HH:mm:ss')
    const data = {
      data: summaryAndConclusion,
      action: 'INSERT'
    }

    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/SetSummaryAndConclusion`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        if (response.status !== 200 || response.data.ResultCode !== 'Import_Succes') throw new Error(`Unable to create summary and conclusion. ${response.data.ResultMessage}`)

        const veldData = JSON.parse(response.data.FileContent).VeldData
        const summaryAndConclusions = arrayUtils.formatArray(veldData.tblSummaryConclusions)
        return summaryAndConclusions
      })
  }

  getSummaryAndConclusion(projectID) {
    const authorisation = this.getAuthorizationObject()
    const languageCode = this.getLangCodeLongFromShort(authorisation.Language)
    const data = {
      Authorisation: authorisation,
      LanguageCode: languageCode,
      WebserviceVersion: '1.0',
      TypeOfExport: 'Export_FIELD_JSON_v1_0_0',
      Parameters: [
        {
          ParameterType: 'ExportFormat',
          StringValue: 'json'
        },
        {
          ParameterType: 'ProjectID',
          StringValue: projectID.toString(),
          FloatValue: projectID.toString()
        }
      ],
      logFormat: 'XML'
    }

    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/GetSummaryAndConclusion`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((resp) => {
        if (resp.status !== 200 || resp.data.ResultCode !== 'Export_Succes' || typeof resp.data.Files[0] !== 'object') {
          throw new Error(`Unable to get summary and conclusion. ${resp.data.ResultMessage}`)
        }

        const veldData = JSON.parse(resp.data.Files[0].FileContent).VeldData
        const summaryAndConclusions = arrayUtils.formatArray(veldData.tblSummaryConclusions)

        return {
          summaryAndConclusions
        }
      })
  }
}
