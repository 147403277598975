import Vue from 'vue'
import config from '@/configurations/config-environment'
import arrayUtils from '@/utils/arrayUtils'
import { BaseService } from '../baseService'

const dataws = config.apis.dataws

export class BottleService extends BaseService {
  getWaterSampleWithBottles(projectID, measurementpointGuid, gaugingTubeGuid = '', waterSampleGuid = '') {
    const authorisation = this.getAuthorizationObject()
    const languageCode = this.getLangCodeLongFromShort(authorisation.Language)
    const data = {
      Authorisation: authorisation,
      LanguageCode: languageCode,
      WebserviceVersion: '1.0',
      TypeOfExport: 'Export_FIELD_JSON_v1_0_0',
      Parameters: [
        {
          ParameterType: 'ExportFormat',
          StringValue: 'json'
        },
        {
          ParameterType: 'ProjectID',
          StringValue: projectID.toString(),
          FloatValue: projectID.toString()
        },
        {
          ParameterType: 'MeasurementPointGuid',
          StringValue: measurementpointGuid.toString()
        },
        {
          ParameterType: 'GaugingTubeGuid',
          StringValue: gaugingTubeGuid.toString()
        },
        {
          ParameterType: 'WaterSampleGuid',
          StringValue: waterSampleGuid.toString()
        }
      ],
      logFormat: 'XML'
    }

    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/GetWaterSampleBottles`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((resp) => {
        this.evaluateResponse(resp, 'getWaterSampleWithBottles')

        const veldData = JSON.parse(resp.data.Files[0].FileContent).VeldData

        const tblBottles = arrayUtils.formatArray(veldData.tblBottles)
        const tblFilterTubes = arrayUtils.formatArray(veldData.tblFilterTubes)
        const tblMeasurementPoints = arrayUtils.formatArray(veldData.tblMeasurementPoints)
        const tblObservation = arrayUtils.formatArray(veldData.tblObservation)
        const tblProjects = arrayUtils.formatArray(veldData.tblProjects)
        const tblWaterSamples = arrayUtils.formatArray(veldData.tblWaterSamples)

        return {
          tblBottles,
          tblFilterTubes,
          tblMeasurementPoints,
          tblObservation,
          tblProjects,
          tblWaterSamples
        }
      })
  }

  createWaterSampleBottles(prid, mpguid, ftGuid, wsGuid, bottles) {
    const data = {
      data: {
        PrID: prid,
        MpGuid: mpguid,
        FtGuid: ftGuid,
        WsGuid: wsGuid,
        Bottles: bottles,
        Authorisation: this.getAuthorizationObject()
      },
      action: 'INSERT'
    }

    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/SetWaterSampleBottles`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((resp) => {
        this.evaluateResponse(resp, 'createWaterSampleBottles')

        const veldData = JSON.parse(resp.data.FileContent).VeldData
        const tblBottles = arrayUtils.formatArray(veldData.tblBottles)

        return {
          tblBottles
        }
      })
  }

  updateWaterSampleBottles(prid, mpguid, ftGuid, wsGuid, bottles) {
    const data = {
      data: {
        PrID: prid,
        MpGuid: mpguid,
        FtGuid: ftGuid,
        WsGuid: wsGuid,
        Bottles: bottles,
        Authorisation: this.getAuthorizationObject()
      },
      action: 'UPDATE'
    }

    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/SetWaterSampleBottles`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((resp) => {
        this.evaluateResponse(resp, 'updateWaterSampleBottles')

        return true
      })
  }

  deleteWaterSampleBottle(prID, mpGuid, ftGuid, wsGuid, boGuid) {
    const data = {
      data: {
        PrID: prID,
        MpGuid: mpGuid,
        FtGuid: ftGuid,
        WsGuid: wsGuid,
        BoGuid: boGuid,
        Authorisation: this.getAuthorizationObject()
      },
      action: 'DELETE'
    }

    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/SetWaterSampleBottles`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((resp) => {
        this.evaluateResponse(resp, 'deleteWaterSampleBottle')

        return true
      })
  }

  evaluateResponse(response, requestName) {
    if (
      response.status !== 200 ||
      !response.data ||
      (response.data.ResultCode !== 'Import_Succes' && response.data.ResultCode !== 'Export_Succes') ||
      (response.data.ResultMessage !== null && response.data.ResultMessage.toLowerCase().includes('error'))
    ) {
      throw new Error(`Request ${requestName} failed with message '${response.data.ResultMessage}'`)
    }
  }
}
