// actions.js
import typefields from '@/configurations/app/config-typefield'
import twsMapping from '@/configurations/app/tws-mapping'
import config from '@/configurations/config.js'
import arrayFormater from '@/utils/arrayUtils'
import DataTableUtils from '@/utils/dataTableUtils'
import MeasurementPointDuplicator from '@/utils/measurementPointDuplicator'
import stringUtils from '@/utils/stringUtils'
import moment from 'moment'
import Vue from 'vue'
import cookie from 'vue-cookie'
import { FinishingsService } from '@/services/DataWS/finishingsService'
import { BottleService } from '@/services/DataWS/bottleService'
import { CadastralOvamService } from '@/services/DataWS/cadastralOvamService'
import { AnalysisResultService } from '@/services/DataWS/analysisResultService'
import { TemplateService } from '@/services/DataWS/templateService'
import { BoreProfileService } from '@/services/DataWS/boreprofileService'
import { ProjectService } from '@/services/DataWS/projectService'
import { LayerService } from '@/services/DataWS/layerService'
import { LocationVisitService } from '@/services/DataWS/locationVisitService'
import { SublocationService } from '@/services/DataWS/sublocationService'
import { SummaryAndConclusionService } from '@/services/DataWS/summaryAndConclusionService'
import { WaterSampleService } from '@/services/DataWS/waterSampleService'
import { GaugingTubeService } from '@/services/DataWS/gaugingTubeService'
import { CodeListService } from '@/services/DataWS/codeListService'
import { ProjectUserService } from '@/services/DataWS/projectUserService'
import { QueryService } from '@/services/DataWS/queryService'
import { MeasurementPointService } from '@/services/DataWS/measurementPointService'
import { LabAssignmentService } from '@/services/DataWS/labAssignmentService'
import { SoilSampleService } from '@/services/DataWS/soilSampleService'
import { ConcernedContactsService } from '@/services/DataWS/concernedContactsService'
import { AlgorithmService } from '@/services/AlgorithmWS/algorithmService'
import { OathService } from '@/services/SSO/oathService'
import { UserManagementService } from '@/services/SSO/userManagementService'
import { MediaService } from '@/services/MediaWS/mediaService'
import { SettingsService } from '@/services/ManagmentWS/settingsService'
import { LoggingService } from '@/services/ManagmentWS/loggingService'
import { MailingService } from '@/services/ManagmentWS/mailingService'
import { ExportService } from '@/services/DataWS/exportService'
import { ExportServiceReportWS } from '@/services/ReportWS/exportService'

const gaugingTubeService = new GaugingTubeService()
const codeListService = new CodeListService()
const projectUserService = new ProjectUserService()
const queryService = new QueryService()
const measurementPointService = new MeasurementPointService()
const labAssignmentService = new LabAssignmentService()
const soilSampleService = new SoilSampleService()
const concernedContactsService = new ConcernedContactsService()
const algorithmService = new AlgorithmService()
const oathService = new OathService()
const userManagementService = new UserManagementService()
const mediaService = new MediaService()
const settingsService = new SettingsService()
const waterSampleService = new WaterSampleService()
const projectService = new ProjectService()
const layerService = new LayerService()
const locationVisitService = new LocationVisitService()
const sublocationService = new SublocationService()
const summaryAndConclusionService = new SummaryAndConclusionService()

const finishingsService = new FinishingsService()

const cadastralOvamService = new CadastralOvamService()
const bottleService = new BottleService()
const boreprofileService = new BoreProfileService()
const analysisResultService = new AnalysisResultService()
const templateService = new TemplateService()
const loggingService = new LoggingService()
const mailingService = new MailingService()
const exportService = new ExportService()
const exportServiceReportWS = new ExportServiceReportWS()

let getEditor = config.configEditors

export const setActiveTitle = ({ commit }, value) => commit('SET_ACTIVE_TITLE', value)
export const setPrevTitle = ({ commit }, value) => commit('SET_PREV_TITLE', value)
export const setCurrentProject = ({ commit }, value) => commit('SET_CURRENT_PROJECT', value)
export const setPrTypeCode = ({ commit }, value) => commit('SET_PR_TYPE_CODE', value)
export const setPrTemplateID = ({ commit }, value) => commit('SET_PR_TEMPLATE_ID', value)
export const setTemplateField = ({ commit }, value) => { commit('SET_TEMPLATE_FIELD', value) }

export default {
  setActiveTitle,
  setPrevTitle,
  setCurrentProject,
  setPrTypeCode,
  setPrTemplateID,
  setTemplateField,

  clearOverviewQueryResponse: ({ commit, state }, context) => {
    return commit('clearOverviewQueryResponse')
  },

  getOverviewQueryResponse: ({ commit, state }, context) => {
    commit('loading', true)
    return queryService.getOverviewQueryResponse(context.params).then((data) => {
      commit('loading', false)
      return commit('setOverviewQueryResponse', data)
    })
  },

  getOverviewQueries: ({ commit, state }, context) => {
    return queryService.getOverviewQueries().then((data) => {
      return commit('setOverviewQueries', data)
    })
  },

  validateProject: async ({ commit, state }, context) => {
    return (await projectService.validateProject(context.PrCode)).body === 'true'
  },

  setProject: ({ commit, state }, context) => {
    return projectService.setProject(context.projectData)
  },

  fetchJars: ({ commit, state }, context) => {
    // see if still valid
    return new Promise((resolve, reject) => {
      return soilSampleService.getSoilSamples(context.PrID, context.MpGUID.toString())
        .then((data) => {
          let jars = arrayFormater.formatArray(JSON.parse(data.data.Files[0].FileContent).VeldData.tblJars)
          if (jars && jars.length > 0) {
            jars.forEach(jar => {
              jar.MpGuid = context.MpGUID.toString()
            })
          } else {
            jars = []
          }
          return commit('setSoilSamples', jars)
        }).then(() => {
          resolve()
        })
    })
  },

  fetchJarsForTable: ({ commit, state }, context) => {
    // see if still valid
    return new Promise((resolve, reject) => {
      return soilSampleService.getSoilSamples(context.PrID, context.MpGUID.toString())
        .then((data) => {
          let jars = arrayFormater.formatArray(JSON.parse(data.data.Files[0].FileContent).VeldData.tblJars)
          if (jars && jars.length > 0) {
            jars.forEach(jar => {
              jar.MpGuid = context.MpGUID.toString()
            })
          } else {
            jars = []
          }
          return commit('setTableJars', jars)
        })
        .then(() => {
          resolve()
        })
    })
  },

  fetchWaterSurfaceWaterAirSurfaceAirSamplesBottlesAndObservations({ commit, state }, context) {
    return bottleService.getWaterSampleWithBottles(context.projectId, context.MpGuid).then((result) => {
      let bottles = result.tblBottles
      let observations = result.tblObservation

      let waterSamples = []
      let surfaceWaterSamples = []
      let airSamples = []
      let surfaceAirSamples = []
      result.tblWaterSamples.forEach((sample) => {
        if (['OL', 'BL'].includes(sample.WsMatrixCode)) { // If OL or BL  the sample is an air sample
          if (sample.FtID && sample.FtID !== '') { // soil air
            airSamples.push(sample)
          } else { // ambient air
            surfaceAirSamples.push(sample)
          }
        } else {
          if (sample.FtID && sample.FtID !== '') { // water
            waterSamples.push(sample)
          } else { // surface water
            surfaceWaterSamples.push(sample)
          }
        }
      })

      let storeCommits = []
      storeCommits.push(commit('setWaterSamples', waterSamples))
      storeCommits.push(commit('setSurfaceWaterSamples', surfaceWaterSamples))
      storeCommits.push(commit('setAirSamples', airSamples))
      storeCommits.push(commit('setSurfaceAirSamples', surfaceAirSamples))
      storeCommits.push(commit('setBottles', bottles))
      storeCommits.push(commit('setObservations', observations))

      return Promise.all(storeCommits)
    })
  },

  fetchGaugintubes({ commit, state }, context) {
    return gaugingTubeService.getGaugingTubes(context.projectId, context.MpGuid).then((result) => {
      let gaugingtubes = result.tblGaugingTubes
      let storeCommites = []

      storeCommites.push(commit('setGaugingTubes', gaugingtubes))

      return Promise.all(storeCommites)
    })
  },

  fetchFinishings({ commit, state }, context) {
    return finishingsService.getFinishings(context.projectId, context.MpGuid).then((result) => {
      let finishings = result.tblFinishings

      let storeCommites = []

      storeCommites.push(commit('setFinishings', finishings))

      return Promise.all(storeCommites)
    })
  },

  fetchLayersForTable: ({ commit, state }, context) => {
    // see if still valid
    return new Promise((resolve, reject) => {
      return layerService.getLayers(context.PrID, context.MpGUID)
        .then((data) => {
          return commit('setTableLayers', { layers: data.tblLayers, MpGUID: context.MpGUID })
        })
        .then(() => {
          resolve()
        })
    })
  },

  fetchMeasurementPoints: ({ commit, state }, context) => {
    // see if still valid
    return new Promise((resolve, reject) => {
      return measurementPointService.getMeasurementPoints(context.PrID)
        .then((data) => {
          return commit('setMeasurementPoints', data.tblMeasurementPoints)
        }).then(() => {
          resolve()
        })
    })
  },

  fetchCadastralOvam: ({ commit, state }, context) => {
    // see if still valid
    return new Promise((resolve, reject) => {
      if (
        (state.cadastralOvam && state.cadastralOvam.length === 0) ||
        state.cadastralOvam[0].PrID !== context.PrID
      ) { // project id changed, clear
        return cadastralOvamService.getCadastralOvam(context.PrID, context.CaGUID)
          .then((data) => {
            return commit('setCadastralOvams', data.tblCadastralOvam)
          }).then(() => {
            resolve()
          })
      } else {
        resolve()
      }
    })
  },

  fetchProject: ({ commit, state }, context) => {
    return new Promise((resolve, reject) => {
      return projectService.getSingleProject(context.PrID)
        .then((response) => {
          let project = {}
          if (response.data && response.data.ResultCode === 'Export_Succes') {
            let projectResponse = JSON.parse(response.data.Files[0].FileContent)
            project = projectResponse.VeldData.tblProjects
          } else {
            // Todo reject? Error handling.
          }
          return commit('setCurrentProject', project)
        }).then(() => {
          resolve()
        })
    })
  },

  fetchCadastralOvamVlaremActs: ({ commit, state }, context) => {
    // see if still valid
    return new Promise((resolve, reject) => {
      if (
        (state.cadastralOvamVlaremAct && state.cadastralOvamVlaremAct.length <= 0) ||
        (state.cadastralOvamVlaremAct[0].PrID.toString() !== context.PrID.toString()) ||
        (state.cadastralOvamVlaremAct[0].CaID.toString() !== context.CaID.toString())
      ) { // project id changed or caGuid changed, empty array, clear
        return cadastralOvamService.getCadastralOvamVlaremActs(context.PrID, context.CaGUID)
          .then((data) => {
            data.tblCadastralOvamVlaremAct = data.tblCadastralOvamVlaremAct.filter(cova => cova.CaID === context.CaID)
            return commit('setCadastralOvamVlaremActs', data.tblCadastralOvamVlaremAct)
          }).then(() => {
            resolve()
          })
      } else {
        resolve()
      }
    })
  },

  fetchCadastralOvamCustomers: ({ commit, state }, context) => {
    // see if still valid
    return new Promise((resolve, reject) => {
      if (
        (state.cadastralOvamCustomers && state.cadastralOvamCustomers.length <= 0) ||
        (state.cadastralOvamCustomers[0].PrID.toString() !== context.PrID.toString()) ||
        (state.cadastralOvamCustomers[0].CaID.toString() !== context.CaID.toString())
      ) { // project id changed or caGuid changed, empty array, clear
        return cadastralOvamService.getCadastralOvamCustomers(context.PrID, context.CaGUID)
          .then((data) => {
            data.tblCadastralOvamCustomers = data.tblCadastralOvamCustomers.filter(cova => cova.CaID === context.CaID)
            return commit('setCadastralOvamCustomers', data.tblCadastralOvamCustomers)
          }).then(() => {
            resolve()
          })
      } else { // already exists, return existing
        resolve()
      }
    })
  },

  createSubLocation: ({ commit, state }, context) => {
    return sublocationService.createSublocation(context)
  },

  deleteSubLocation: ({ commit, state }, context) => {
    return sublocationService.deleteSubLocation(context.projectId, context.SlGuid)
  },

  fetchTblSubLocations: ({ commit, state }, context) => {
    // see if still valid
    return new Promise((resolve, reject) => {
      if (
        (state.tblSubLocations && (state.tblSubLocations.length > 0)) &&
        (state.tblSubLocations[0].PrID !== context.PrID)
      ) { // project id changed, clear
        state.tblSubLocations = null
      }
      if (state.tblSubLocations == null) { // empty, fetch
        return sublocationService.getSubLocations(context.PrID)
          .then((data) => {
            return commit('setSubLocations', data.tblSubLocations)
          }).then(() => {
            resolve()
          })
      } else { // already exists, return existing
        resolve()
      }
    })
  },

  setContourOfItem({ commit, state }, context) {
    sublocationService.updateSublocation(context)
  },

  deleteContourOfItem({ commit, state }, context) {
    sublocationService.updateSublocation(context)
  },

  getSubLocations({ commit, state }, context) {
    return sublocationService.getSubLocations(context.projectId).then((data) => data.tblSubLocations)
  },

  updateSubLocation: ({ commit, state }, context) => {
    return sublocationService.updateSublocation(context)
  },

  createSummaryAndConclusion: async ({ commit, state }, context) => {
    const createResult = await summaryAndConclusionService.createSummaryAndConclusion(context)
    if (!createResult) {
      console.error('Could not create summary/conclusion entry')
      return
    }
    const returned = createResult.find(item => item.SmType === context.SmType)
    context.SmGUID = returned.SmGUID
    return summaryAndConclusionService.updateSummaryAndConclusion(context)
  },

  deleteSummaryAndConclusion: ({ commit, state }, context) => {
    return summaryAndConclusionService.deleteSummaryAndConclusion(context.projectId, context.SmGUID)
  },

  fetchTblSummaryConclusions: ({ commit, state }, context) => {
    return new Promise((resolve, reject) => {
      return summaryAndConclusionService.getSummaryAndConclusion(context.PrID)
        .then((data) => {
          return commit('setSummaryAndConclusions', data.summaryAndConclusions)
        }).then(() => {
          resolve()
        })
    })
  },

  getSummaryAndConclusion: ({ commit, state }, context) => {
    return summaryAndConclusionService.getSummaryAndConclusion(context.projectId).then((data) => data.summaryAndConclusions)
  },

  updateSummaryAndConclusion: ({ commit, state }, context) => {
    return summaryAndConclusionService.updateSummaryAndConclusion(context)
  },

  fetchTblAirSamples: ({ commit, state }, context) => {
    return new Promise((resolve, reject) => {
      // Implement retrieving of packagings
      return commit('setAirSamples', []).then(() => {
        resolve()
      })
    })
  },

  fetchTblPackagings: ({ commit, state }, context) => {
    return new Promise((resolve, reject) => {
      // Implement retrieving of packagings
      return commit('setPackagings', []).then(() => {
        resolve()
      })
    })
  },

  fetchLayers: ({ commit, state }, context) => {
    // see if still valid
    return new Promise((resolve, reject) => {
      return layerService.getLayers(context.PrID, context.MpGUID)
        .then((data) => {
          return commit('setLayers', data.tblLayers)
        }).then(() => {
          resolve()
        })
    })
  },
  multiEdit: ({ commit, state }, context) => {
    return new Promise(async (resolve, reject) => {
      try {
        const {
          projectId,
          selectionIDs,
          guidColumn,
          dateColumn,
          tblMeasurementPoints,
          tblWaterSamples,
          tblGaugingTubes,
          includeAll } = context
        let { tableName, selectionObjects, changedObject } = context
        if (tableName === 'tblGaugingTubes') {
          tableName = 'tblFilterTubes'
        }
        if (!selectionObjects || selectionObjects.length < 1) {
          console.log('invalid selectionObjects')
          return
        }

        if (tableName === 'tblLayers') {
          selectionObjects.forEach((rowData) => {
            rowData['LaMedianCode'] = (rowData['LaMedianCode'].includes('.')) ? rowData['LaMedianCode'].split('.')[1] : rowData['LaMedianCode']
          })
        }

        let request = {
          tblProjects: await projectService.GetProjectRecord(projectId)
        }

        // note: for multi edit we must supply the server with a valid data set
        // e.g. each child object must reference it's parent object within this dataset
        // these references are used to resolve relations, but are not stored in the server
        if (tblWaterSamples) {
          if (tableName !== 'tblWaterSamples') {
            tblWaterSamples[0].WsID = 1
          }

          request.tblWaterSamples = tblWaterSamples
        }

        if (tblGaugingTubes) {
          if (tableName !== 'tblFilterTubes') {
            tblGaugingTubes[0].FtID = 1
          }

          request.tblFilterTubes = tblGaugingTubes
        }

        if (tblMeasurementPoints) {
          if (tableName !== 'tblMeasurementPoints') {
            tblMeasurementPoints[0].MpID = 1
          }
          request.tblMeasurementPoints = tblMeasurementPoints
        }

        request[tableName] = []

        // loop through all records
        selectionObjects.forEach(async record => {
          // find the record in the selection
          const isSelected = selectionIDs.find((item) => { return item === record[guidColumn] })
          if (isSelected) {
            let destRecord = { ...record }
            Object.keys(changedObject)
              .forEach((key) => {
                if (changedObject[key]['use']) {
                  // If it is a number editor and it needs to be cleared the key needs to be removed from the object
                  if (changedObject[key]['type'] === '6' && changedObject[key]['value'] === '') {
                    if (destRecord[changedObject[key].dataField] && ['MpXCoord', 'MpYCoord'].includes(key)) {
                      delete destRecord[changedObject[key].dataField]
                    }
                  } else if ((changedObject[key]['type'] === '13' && changedObject[key]['value'] === '') ||
                    (changedObject[key]['type'] === '2' && changedObject[key]['value'] === '')) {
                    // Emptying date fields is not allowed
                    destRecord[changedObject[key].dataField] = destRecord[changedObject[key].dataField]
                  } else {
                    destRecord[changedObject[key].dataField] = changedObject[key].value
                  }
                }
              })
            const updatedAt = new Date(moment()
              .utc()
              .add(3, 'hour'))
            destRecord[dateColumn] = updatedAt

            request[tableName].push(destRecord)
          } else if (includeAll) {
            request[tableName].push({ ...record })
          }
        })

        if (request.tblMeasurementPoints) {
          request.tblMeasurementPoints.forEach((measurementPoint) => {
            if (stringUtils.isNullOrEmptyOrWhitespace(measurementPoint.MpXCoord) && stringUtils.isNullOrEmptyOrWhitespace(measurementPoint.MpYCoord) && !stringUtils.isNullOrEmptyOrWhitespace(measurementPoint.MpPointGeometry)) {
              measurementPoint.MpPointGeometry = null
            }
          })
        }

        request.tblObservation = []
        if (request.tblWaterSamples) {
          const result = await DataTableUtils.MapDataTableToObservations(request.tblWaterSamples, projectId)
          request.tblWaterSamples = result.dataTable
          request.tblObservation = request.tblObservation.concat(result.tblObservations)
        }
        if (request.tblBottles) {
          const result = await DataTableUtils.MapDataTableToObservations(request.tblBottles, projectId)
          request.tblBottles = result.dataTable
          request.tblObservation = request.tblObservation.concat(result.tblObservations)
        }

        request.tblMetaData = {
          Version: '2.1.0'
        }

        if (request.tblMeasurementPoints) {
          request.tblSublocations = await sublocationService.getSubLocations(projectId).then((data) => data.tblSubLocations)
        }

        const result = await projectService.SetProjectWithChildsCall(projectId, request)
        resolve(result)
      } catch (err) {
        console.log(err)
        reject(err)
      }
    })
  },
  duplicateMeasurementPoint: ({ commit, state }, context) => {
    const { options, selection } = context
    const measurementPointDuplicator = new MeasurementPointDuplicator()
    return measurementPointDuplicator.duplicateMeasurementPoints(options, selection)
  },
  setSummaryAndConclusionsTypes({ commit, state }, context) {
    if (state.summaryAndConclusionsTypes.length > 0) return

    return codeListService.getCodeListByCategoryIDOrCode(context).then((result) => {
      let mapping = result.map(obj => {
        let objMap = {}
        objMap['key'] = obj.GcCode
        objMap['value'] = obj.GcDescription
        return objMap
      })
      commit('summaryAndConclusionsTypesMutation', mapping)
    })
  },

  setCurrentOverview: ({ commit, state }, context) => {
    if (context) {
      commit('mutateCurrentOverview', context)
      commit('mutateCurrentOverviewParameters', null)
    }
  },

  setCurrentOverviewParameters: ({ commit, state }, context) => {
    commit('mutateCurrentOverviewParameters', context)
  },

  fetchMultiEditFields({ commit, state }, context) {
    return templateService.getDefaultFieldTemplate(context.formName)
      .then((resultTemplate) => {
        if (typeof resultTemplate === 'undefined') {
          console.log('template controls not found in actions.js fetchMultiEditFields()')
          return
        }

        let sortFunction = function (a, b) {
          return parseInt(a.order) - parseInt(b.order)
        }
        let template = resultTemplate
        let controls
        let allowedControls = template.controls.filter((control) => {
          // return true
          return (control.inputControlOptions.AllowMultiEdit && control.inputControlOptions.AllowMultiEdit === 'true')
        })
        controls = allowedControls.map(control => {
          // leave one field
          if (control.fields.length > 1) {
            let tempfield = control.fields[0]
            let typeFields = typefields[tempfield.type]
            if (typeFields) {
              let generatedTypeField = typeFields.find(v => {
                let check = tempfield.key.indexOf(v.name) !== -1
                if (tempfield.title) {
                  check = check && tempfield.title.indexOf(v.title) !== -1
                }
                return check
              })
              if (generatedTypeField) {
                let raws = []
                control.fields.forEach(field => {
                  let raw = {}
                  raw.key = field.key
                  raw.maxLength = field.maxLength
                  raw.requiredBySystem = field.requiredBySystem
                  raw.requiredByUser = field.requiredByUser
                  raw.options = field.options
                  raws.push(raw)
                })
                tempfield.raws = raws
                control.fields = [tempfield]
              }
            }
          }
          control.fields.forEach(field => {
            delete field.component
            let editor = getEditor(field.type || '*')
            field.component = editor.name
            field.editorOptions = editor.options
            field.inputControlOptions = control.inputControlOptions || {}
          })
          return control
        })
          .sort(sortFunction)
        return controls
      })
  },

  /*
   * This function removes items from a store object based on a give property
   * Context : {
   * storeObject: name of item in vue store (layers, soilSamples, etc)
   * storeProperty: property of item that is used to filter
   * propertyValue: the value where the property should be checked on to remove
   * }
   */
  removeItemsFromStoreArray({ commit, state }, context) {
    if (context.storeObject && context.storeProperty && context.propertyValue) {
      if (state[context.storeObject]) {
        state[context.storeObject] = state[context.storeObject].filter(function (item) {
          return item[context.storeProperty] !== context.propertyValue
        })
      }
    }
  },

  getAllSamplesWithResults({ commit, state }, context) {
    return analysisResultService.getAllSamplesWithResults(context.projectId)
  },

  getAllAnalysisSamples({ commit, state }, context) {
    return analysisResultService.getAllAnalysisSamples(context.projectId)
  },

  GetAllResultsWithConclusions({ commit, state }, context) {
    return analysisResultService.GetAllResultsWithConclusions(context.projectId, context.sampleGUID)
  },

  GetAllResults({ commit, state }, context) {
    return analysisResultService.GetAllResults(context.projectId, context.sampleGUID)
  },

  CRUDAnalysisResults({ commit, state }, context) {
    return analysisResultService.CRUDAnalysisResults(context.projectId, context.sampleGUID, context.data)
  },

  getAllSubSamples({ commit, state }, context) {
    return analysisResultService.getAllSubSamples(context.projectId, context.sampleGUID)
  },

  getLocationVisits({ commit, state }, context) {
    return locationVisitService.getLocationVisits(context.projectId).then((data) => data.tblLocationVisits)
  },

  getMeasurementPoints({ commit, state }, context) {
    return measurementPointService.getMeasurementPoints(context.projectId).then((data) => data.tblMeasurementPoints)
  },

  createMeasurementPoint({ commit, state }, context) {
    return measurementPointService.createMeasurementPoint(context.measurementPoint)
  },

  updateMeasurementPoint({ commit, state }, context) {
    return measurementPointService.updateMeasurementPoint(context.measurementPoint)
  },

  deleteMeasurementPoint({ commit, state }, context) {
    return measurementPointService.deleteMeasurementPoint(context.projectId, context.measurementPointGuid)
  },

  getGaugingTubes({ commit, state }, context) {
    return gaugingTubeService.getGaugingTubes(context.projectId, context.measurementPointGuid).then((data) => data.tblGaugingTubes)
  },

  createGaugingTube({ commit, state }, context) {
    return gaugingTubeService.createGaugingTube(context.projectId, context.measurementPointGuid, context.gaugingTubes)
  },

  deleteGaugingTube({ commit, state }, context) {
    return gaugingTubeService.deleteGaugingTube(context.projectId, context.measurementPointGuid, context.gaugingTubeId)
  },

  updateGaugingTube({ commit, state }, context) {
    return gaugingTubeService.updateGaugingTube(context.projectId, context.measurementPointGuid, [context.gaugingTube])
  },

  getFinishings({ commit, state }, context) {
    return finishingsService.getFinishings(context.projectId, context.measurementPointGuid).then((data) => data.tblFinishings)
  },

  createFinishing({ commit, state }, context) {
    return finishingsService.createFinishing(context.projectId, context.measurementPointGuid, [context.finishing])
  },

  deleteFinishing({ commit, state }, context) {
    return finishingsService.deleteFinishings(context.projectId, context.measurementPointGuid, context.finishingGuid)
  },

  updateFinishing({ commit, state }, context) {
    return finishingsService.updateFinishings(context.projectId, context.measurementPointGuid, [context.finishing])
  },

  createAirSample({ commit, state }, context) {
    return waterSampleService.createWaterSample(context.projectId, context.measurementPointGuid, context.gaugingTubeGuid, [context.airSampleRecord], [])
  },

  getAirSamples({ commit, state }, context) {
    return bottleService.getWaterSampleWithBottles(context.projectId, context.measurementPointGuid, context.gaugingTubeGuid, context.waterSampleGuid)
      .then((data) => {
        const waterSamples = data.tblWaterSamples.filter(waterOfAirSample => ['OL', 'BL'].includes(waterOfAirSample.WsMatrixCode))
        const observations = data.tblObservation
        const twsMappings = twsMapping.observation
        waterSamples.forEach((waterSample) => {
          const waterSampleObservations = observations.filter((observation) => observation.WsID === waterSample.WsID)
          waterSampleObservations.forEach((observation) => {
            const twsMapping = twsMappings.find((mapping) => mapping.type === observation.ObObservationType)

            if (twsMapping) {
              waterSample[twsMapping.key] = observation[twsMapping.field]
            }
          })
          waterSample.observations = waterSampleObservations
        })

        return waterSamples
      })
  },

  getSamples({ commit, state }, context) {
    return bottleService.getWaterSampleWithBottles(context.projectId, context.measurementPointGuid, context.gaugingTubeGuid, context.waterSampleGuid)
  },

  getAllWaterSamples({ commit, state }, context) {
    return waterSampleService.getAllWaterSamples(context.projectId)
  },

  updateAirSample({ commit, state }, context) {
    const airSample = context.airSample
    const twsMappings = twsMapping.observation
    const observationsMappings = []

    Object.keys(airSample).forEach((key) => {
      const mapping = twsMappings.find((tws) => tws.key === key)
      if (mapping) {
        observationsMappings.push({
          mapping: mapping,
          value: JSON.parse(JSON.stringify(airSample[key]))
        })
        delete airSample[key]
      }
    })

    const observations = []

    observationsMappings.forEach((observationMapping) => {
      const newObservation = {
        WsID: airSample.WsID,
        WsGuid: airSample.WsGuid,
        PrID: context.projectId,
        ObObservationType: observationMapping.mapping.type
      }
      if (observationMapping.mapping.unit !== '-1') {
        newObservation.ObMeasurementUnit = observationMapping.mapping.unit
      }
      const field = observationMapping.mapping.field
      newObservation[field] = observationMapping.value
      observations.push(newObservation)
    })

    if (airSample.observations && airSample.observations.length > 0) {
      observations.forEach(observation => {
        const existingObservationMatchingType = airSample.observations
          .find(existingObservation => existingObservation.ObObservationType === observation.ObObservationType)
        if (!existingObservationMatchingType) return
        observation.ObID = existingObservationMatchingType.ObID
      })
    }

    airSample.WsDateLastChanged = moment().format('YYYY-MM-DDTHH:mm:ss')

    return waterSampleService.updateWaterSample(context.projectId, context.measurementPointGuid, context.gaugingTubeGuid, [airSample], observations)
  },

  deleteAirSample({ commit, state }, context) {
    return waterSampleService.deleteWaterSample(context.projectId, context.measurementPointGuid, context.gaugingTubeGuid, context.waterSampleGuid)
  },

  createWaterSample({ commit, state }, context) {
    return waterSampleService.createWaterSample(context.projectId, context.measurementPointGuid, context.gaugingTubeGuid, [context.waterSampleRecord], [])
  },

  getWaterSamples({ commit, state }, context) {
    return bottleService.getWaterSampleWithBottles(context.projectId, context.measurementPointGuid, context.gaugingTubeGuid, context.waterSampleGuid)
      .then((data) => {
        const waterSamples = data.tblWaterSamples.filter(waterOfAirSample => !['OL', 'BL'].includes(waterOfAirSample.WsMatrixCode))
        const observations = data.tblObservation
        const twsMappings = twsMapping.observation
        waterSamples.forEach((waterSample) => {
          const waterSampleObservations = observations.filter((observation) => observation.WsID === waterSample.WsID)
          waterSampleObservations.forEach((observation) => {
            const twsMapping = twsMappings.find((mapping) => mapping.type === observation.ObObservationType)

            if (twsMapping) {
              waterSample[twsMapping.key] = observation[twsMapping.field]
            }
          })
          waterSample.observations = waterSampleObservations
        })

        return waterSamples
      })
  },

  deleteWaterSample({ commit, state }, context) {
    return waterSampleService.deleteWaterSample(context.projectId, context.measurementPointGuid, context.gaugingTubeGuid, context.waterSampleGuid)
  },

  updateWaterSample({ commit, state }, context) {
    const waterSample = context.waterSample
    const twsMappings = twsMapping.observation
    const observationsMappings = []

    Object.keys(waterSample).forEach((key) => {
      const mapping = twsMappings.find((tws) => tws.key === key)
      if (mapping) {
        observationsMappings.push({
          mapping: mapping,
          value: JSON.parse(JSON.stringify(waterSample[key]))
        })
        delete waterSample[key]
      }
    })

    const observations = []

    observationsMappings.forEach((observationMapping) => {
      const newObservation = {
        WsID: waterSample.WsID,
        WsGuid: waterSample.WsGuid,
        PrID: context.projectId,
        ObObservationType: observationMapping.mapping.type
      }
      if (observationMapping.mapping.unit !== '-1') {
        newObservation.ObMeasurementUnit = observationMapping.mapping.unit
      }
      const field = observationMapping.mapping.field
      newObservation[field] = observationMapping.value
      observations.push(newObservation)
    })

    if (waterSample.observations && waterSample.observations.length > 0) {
      observations.forEach(observation => {
        const existingObservationMatchingType = waterSample.observations
          .find(existingObservation => existingObservation.ObObservationType === observation.ObObservationType)
        if (!existingObservationMatchingType) return
        observation.ObID = existingObservationMatchingType.ObID
      })
    }

    waterSample.WsDateLastChanged = moment().format('YYYY-MM-DDTHH:mm:ss')

    return waterSampleService.updateWaterSample(context.projectId, context.measurementPointGuid, context.gaugingTubeGuid, [waterSample], observations)
  },

  getBottles({ commit, state }, context) {
    return bottleService.getWaterSampleWithBottles(context.projectId, context.measurementPointGuid, context.gaugingTubeGuid, context.waterSampleGuid).then((data) => data.tblBottles)
  },

  createBottle({ commit, state }, context) {
    return bottleService.createWaterSampleBottles(context.projectId, context.measurementPointGuid, context.gaugingTubeGuid, context.waterSampleGuid, context.bottles)
  },

  deleteBottle({ commit, state }, context) {
    return bottleService.deleteWaterSampleBottle(context.projectId, context.measurementPointGuid, context.gaugingTubeGuid, context.waterSampleGuid, context.bottleGuid)
  },

  updateBottle({ commit, state }, context) {
    return bottleService.updateWaterSampleBottles(context.projectId, context.measurementPointGuid, context.filterTubeGuid, context.waterSampleGuid, context.bottles)
  },

  getLayers({ commit, state }, context) {
    return layerService.getLayers(context.projectId, context.measurementpointGuid).then((data) => data.tblLayers)
  },

  createLayer({ commit, state }, context) {
    return layerService.createLayers(context.projectId, context.measurementPointGuid, [context.layer])
  },

  updateLayer({ commit, state }, context) {
    return layerService.updateLayers(context.projectId, context.measurementPointGuid, [context.layer])
  },

  deleteLayer({ commit, state }, context) {
    return layerService.deleteLayer(context.projectId, context.measurementPointGuid, context.layerGuid, context.layers)
  },

  getSoilSamples({ commit, state }, context) {
    return soilSampleService.getSoilSamplesParsed(context.projectId, context.measurementPointGuid).then((data) => data.tblJars)
  },

  createSoilSample({ commit, state }, context) {
    return soilSampleService.createSoilSamples(context.projectId, context.measurementPointGuid, [context.soilSample])
  },

  updateSoilSample({ commit, state }, context) {
    return soilSampleService.updateSoilSamples(context.projectId, context.measurementPointGuid, [context.soilSample])
  },

  deleteSoilSample({ commit, state }, context) {
    return soilSampleService.deleteSoilSample(context.projectId, context.measurementPointGuid, context.soilSampleGuid)
  },

  createLocationVisit({ commit, state }, context) {
    return locationVisitService.createLocationVisit(context.locationVisit)
  },

  updateLocationVisit({ commit, state }, context) {
    return locationVisitService.updateLocationVisit(context.locationVisit)
  },

  deleteLocationVisit({ commit, state }, context) {
    return locationVisitService.deleteLocationVisit(context.PrID, context.LvGuid)
  },

  getDefaultFieldTemplate({ commit, state }, context) {
    return templateService.getDefaultFieldTemplate(context.templateName, context.templateId)
  },

  getFieldTemplate({ commit, state }, context) {
    return templateService.getFieldTemplate(context.templateName, context.projectID, context.fieldCode)
  },

  getCodeListByCategoryIDOrCode({ commit, state }, context) {
    return codeListService.getCodeListByCategoryIDOrCode(context.categoryId, context.codeId, context.language)
  },

  getUserInfoByAzureJwt({ commit, state }, context) {
    return oathService.getUserInfoByAzureJwt(context.idToken)
  },

  connectUserToAzure({ commit, state }, context) {
    return oathService.connectUserToAzure(context.idToken, context.username, context.password)
  },

  connectUserToAzureByInviteToken({ commit, state }, context) {
    return oathService.connectUserToAzureByInviteToken(context.idToken, context.inviteToken)
  },

  getLicensesByAzureJwt({ commit, state }, context) {
    return oathService.getLicensesByAzureJwt(context.idToken)
  },

  getClassicTokenByAzureJwt({ commit, state }, context) {
    return oathService.getClassicTokenByAzureJwt(context.idToken, context.licenseNumber, context.language)
  },

  getLicenseUsageInformation({ commit, state }, context) {
    return oathService.getLicenseUsageInformation(context.idToken, context.licenseNumber)
  },

  generateAndSavePincode({ commit, state }, context) {
    return oathService.generateAndSavePincode(context.idToken)
  },

  validateInviteToken({ commit, state }, context) {
    return oathService.validateInviteToken(context.inviteToken).then((result) => {
      return result?.data?.IsValid || false
    })
  },

  getUserProfile({ commit, state }, context) {
    return userManagementService.getUserProfile()
  },

  fetchProjectWithLabData({ commit, state }, context) {
    return labAssignmentService.getLabassignmentSoilData(context.projectId, context.excludeFieldSamplesOlderThenDays).then((project) => {
      const labAssignments = project.VeldData.tblLabassignments
      if (Array.isArray(labAssignments)) {
        state.labAssignments = [...labAssignments]
      } else {
        state.labAssignments = [labAssignments]
      }
      return commit('setCurrentProject', project)
    })
  },

  fetchProjectWithAllLabData({ commit, state }, context) {
    return labAssignmentService.getProjectLabassignments(context.projectId).then((response) => {
      const parsedResponse = JSON.parse(response.body)
      const project = JSON.parse(parsedResponse.Files[0].FileContent)
      let labAssignments = project.VeldData.tblLabassignments
      if (!labAssignments) labAssignments = []
      if (Array.isArray(labAssignments)) {
        state.labAssignments = labAssignments
      } else {
        state.labAssignments = [labAssignments]
      }
      return commit('setCurrentProject', project.VeldData.tblProjects)
    })
  },

  validateWaterSampleName({ commit, state }, context) {
    return waterSampleService.validateWaterSampleName(context.projectId, context.waterSampleName, context.WSGuid).then((result) => {
      if (!result.data) {
        return false
      } else {
        return true
      }
    })
  },

  getSuggestedAnalysisSamples({ commit, state }, context) {
    return algorithmService.getSuggestedAnalysisSamples(context.settings, context.project)
  },

  setAnalysisSamples({ commit, state }, context) {
    return labAssignmentService.setAnalysisSamples(context.projectData)
  },

  getSettingByName({ commit, state }, context) {
    return settingsService.getSettingByName(context.settingName)
  },

  updateSetting({ commit, state }, context) {
    return settingsService.updateSetting(context.settingName, context.settings)
  },

  loadNewsItems({ state }) {
    const lang = cookie.get('language')
    let category

    switch (lang) {
      case 'nl':
        category = 401
        break
      case 'fr':
        category = 299
        break
      case 'en':
        category = 417
        break
      default:
        category = 417
        break
    }

    return fetch(`https://www.terraindex.com/wp-json/wp/v2/posts/?per_page=100&categories=${category}`)
      .then((response) => response.json())
      .then((data) => {
        const newsItems = []
        for (const newsItem of data) {
          if (newsItems.length < 5 && (lang === newsItem.lang || lang === 'en')) {
            newsItems.push(newsItem)
          }
        }
        Vue.set(state, 'newsItems', [...newsItems])
      })
  },

  async getProjectMediaList({ getters }, context) {
    return mediaService.getProjectMediaList(getters.getCurrentProject.PrGuid, context.mediaType)
  },

  async loadMediaLocationLink({ getters }, context) {
    return mediaService.getProjectMediaLocationLink(getters.getCurrentProject.PrGuid, context.mediaType)
  },

  async loadMediaThumbnail({ getters }, context) {
    return mediaService.getMediaThumbnail(getters.getCurrentProject.PrGuid, context.fileName, context.mediaType)
  },

  getProjectUsersNotPinned({ commit, state }, context) {
    return projectUserService.getProjectUsersNotPinned(context.projectId)
  },

  getProjectUsersPinned({ commit, state }, context) {
    return projectUserService.getProjectUsersPinned(context.projectId)
  },

  getProjectUsersPinnedForGrid({ commit, state }, context) {
    return projectUserService.getProjectUsersPinnedForGrid(context.projectId)
  },

  pinUnpinUser({ commit, state }, context) {
    return projectUserService.pinUnpinUser(context)
  },

  getConcernedContacts({ commit, state }, context) {
    return concernedContactsService.getConcernedContacts(context.projectId)
  },

  createConcernedContact({ commit, state }, context) {
    return concernedContactsService.createConcernedContact(context.concernedContact)
  },

  deleteConcernedContact({ commit, state }, context) {
    return concernedContactsService.deleteConcernedContact(context.PrID, context.CcGUID)
  },

  updateConcernedContact({ commit, state }, context) {
    return concernedContactsService.updateConcernedContact(context.concernedContact)
  },

  async getMediaThumbnail({ commit, state }, context) {
    const getMediaResult = await mediaService.getMediaThumbnail(context.PrGuid, context.FileName, context.mediaClassification)
    if (getMediaResult) {
      return getMediaResult
    }
  },

  async getMediaThumbnailBatch({ commit, state }, context) {
    const getMediaThumbnailBatchResult = []

    const batchSize = 25
    for (let i = 0; i < context.FileNames.length; i += batchSize) {
      const batch = context.FileNames.slice(i, i + batchSize)
      const batchResult = await mediaService.getMediaThumbnailBatch(context.PrGuid, batch, context.mediaClassification)
      getMediaThumbnailBatchResult.push(batchResult)
      if (i === 0 && context.firstBatchOfThumbnailsAvailableCallback) {
        context.firstBatchOfThumbnailsAvailableCallback()
      }
    }

    return getMediaThumbnailBatchResult
  },

  getAllLabAssignmentsBetweenTwoDates({ commit, state }, context) {
    return labAssignmentService.getAllLabAssignmentsBetweenTwoDate(context.FromDate, context.ToDate).then((result) => {
      return result
    })
  },

  getAllBorelogsNamesForLicense({ commit, state }, context) {
    return boreprofileService.getListOfAllBorelogs(context.licenseNumber)
  },

  generateBorelogPreview({ commit, state }, context) {
    return boreprofileService.getTemplateByTemplateId(context.templateID).then((templateSettings) => {
      return boreprofileService.generateBorelogPreview(context.projectId, context.measurementPointId, templateSettings, context.measurementPointGuid, context.templateId, context.forceReload)
        .then((result) => {
          if (result.length <= 0) return ''
          return result[0].Content
        })
    })
  },

  clearLocalCache({ commit, state }, context) {
    return Promise.all([templateService.clearLocalCache(), boreprofileService.clearLocalCache(), codeListService.clearLocalCache(), mediaService.clearLocalCache()])
  },

  LogFrontEndActivity({ commit, state }, context) {
    return loggingService.LogFrontEndActivity(context.activityType, context.activityMessage)
  },

  setExportProjects({ commit, state }, context) {
    return exportService.setExportProjects(context)
  },

  setExportFiles({ commit, state }, context) {
    return exportService.setExportFiles(context.PrID, context.exportServerIdentifier)
  },

  downloadExportFiles({ commit, state }, context) {
    return exportService.downloadExportFiles(context)
  },

  changeFileName({ commit, state }, context) {
    return exportService.changeFileName(context)
  },

  setMeasurementPoints({ commit, state }, context) {
    return measurementPointService.setMeasurementPoints(context)
  },

  getAnalysisSampleReportWS({ commit, state }, context) {
    return exportServiceReportWS.getAnalysisSampleReportWS(context.projectId)
  },

  setAnalysisSampleReportWS({ commit, state }, context) {
    return exportServiceReportWS.setAnalysisSampleReportWS(context.changedAnalysisSamples)
  },

  getWaterSamplesReportWS({ commit, state }, context) {
    return exportServiceReportWS.getWaterSamplesReportWS(context.projectId)
  },

  setWaterSamplesReportWS({ commit, state }, context) {
    return exportServiceReportWS.setWaterSamplesReportWS(context.waterAirSamples)
  },

  sendEmail({ commit, state }, context) {
    return mailingService.sendEmail(context)
  },

  checkIfLicenseForUserIsAuthorizedForFeatureword({ commit, state }, context) {
    return userManagementService.checkIfLicenseForUserIsAuthorizedForFeatureword(context.featureWord)
  },

  checkIfUserHasRoleFunction({ commit, state }, context) {
    return userManagementService.checkIfUserHasRoleFunction(context.functionName)
  }
}
