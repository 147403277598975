<template>
  <div id="app">
    <div class="content">
      <router-view />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import config from '@/configurations/config.js'
import authenticationProviders from '@/utils/authenticationProvider'
import store from '@/vuex/store'

import { LoggingService } from '@/services/ManagmentWS/loggingService'
const loggingService = new LoggingService()

let authenticationProvider = authenticationProviders[config.platform].default
var self

export default {
  data() {
    return {
      authenticated: false,
      username: ''
    }
  },
  methods: {
    handleAuthenticated: function () {
      if (authenticationProvider.isAuthenticated()) {
        this.authenticated = true
        self.$router.app.username = this.username = authenticationProvider.getAuthenticatedUser().username
        Vue.config.lang = authenticationProvider.getAuthenticatedUser().language
      }
    }
  },
  created() {
    self = this
    this.$store.dispatch('clearLocalCache')

    window.addEventListener('keydown', (event) => {
      if (event.key === 'F5') {
        this.$store.dispatch('clearLocalCache')
      }
    })

    window.addEventListener('TILogError', function (e) {
      let errorType = e.detail.severity
      let errorMessage = e.detail.message
      let bugReport = JSON.stringify(e.detail.description, Object.getOwnPropertyNames(e.detail.description))

      loggingService.logFrontEndError(errorType, errorMessage, bugReport).then(res => {
        if (res.status >= 200 && res.status < 300) {
          // Log successfully received by server
          if (res.data.Success === true) {
            // Log successfully saved to database
          } else {
            // Log not saved to database
            console.error(res.data.Message)
          }
        } else {
          // Log not correctly received by server
          console.error(res.status)
        }
      })
    })
  },
  mounted() {
    self = this
    self.$router.app.username = authenticationProvider.getAuthenticatedUser().username
    this.username = authenticationProvider.getAuthenticatedUser().username
  },
  store
}
</script>

<style lang="less">
html {
  overflow: hidden;
}

.mb-1 {
  margin-bottom: 5px;
}

.VueTables__sort-icon.glyphicon-sort {
  display: none !important;
  /* hide ugly sorting icon */
}

.content {
  margin: 60px 0 0;
  height: calc(100vh - 120px);
  overflow: auto;
}

.selectableTable {
  td {
    cursor: pointer;
  }
}

.disabled {
  opacity: .1;
  cursor: default;
  text-align: center;
  display: inline-block !important;
}

@media (max-width: 500px) {
  .VueTables__table-wrapper {
    overflow-x: auto
  }
}

.m-b-sm {
  margin-bottom: 50px;
  clear: both;
}

.flatbutton {
  width: 40px;
  height: 40px;
  display: inline-block;
  background: #bd0001;
  /* background: #67ac45; */
  /* margin: 5px; */
  margin-bottom: 5px;
  margin-right: 5px;
}

.flatbutton span {
  display: inline-block;
  width: 35px;
  height: 35px;
  color: #fff;
  font-size: 21px;
  margin: 3px 1px;
}

#projectContextMenuPopup {
  white-space: nowrap;
}

#projectContextMenuPopupMp {
  white-space: nowrap;
}

figure.button.disabled {
  opacity: .1;
  cursor: default;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
  width: 6px;
}

::-webkit-scrollbar-corner {
  background-color: #eee;
}

::-webkit-scrollbar:horizontal {
  height: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 1px solid #eee;
  /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, .5);
}

::-webkit-scrollbar-track {
  background-color: #eee;
  border-radius: 8px;
}

.zIndex-1 {
  z-index: 1
}

.VueTables__table .selected td {
  background-color: #67ac45;
  color: #FFFFFF;
}

#projectContextMenuPopup {
  display: none;
  cursor: pointer;
}

#projectContextMenuPopup .showingPopup {
  display: block;
}

#projectContextMenuPopupMp {
  display: none;
  cursor: pointer;
}

#projectContextMenuPopupMp .showingPopup {
  display: block;
}

/* right click popup menu, not a component, needs to be global */
.right-click-menu {
  background: #FAFAFA;
  border: 1px solid #BDBDBD;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12);
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 999999;
}

.right-click-menu li {
  border-bottom: 1px solid #E0E0E0;
  margin: 0;
  padding: 5px 35px;
}

.right-click-menu li:last-child {
  border-bottom: none;
}

.right-click-menu li:hover {
  background: #67ac45;
  color: #FFF;
}

.right-click-menu li a {
  text-decoration: none;
}

.right-click-menu li:hover a {
  color: #FFF;
}

/* hide 'xx Records' under v-client-table  */
.VuePagination__count {
  display: none !important;
}

/* search box */
.search {
  top: 60px;
  width: 100%;
  padding: 5px 15px;
  background-color: #eee;
  z-index: 900;

  &>div {
    position: relative;
  }
}

.modal-dialog {
  .modal-header {
    background-color: #67ac45;
    color: white;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  .modal-body {
    background-color: #fff;
  }

  .modal-body p {
    margin: 5px;

    &.error {
      color: red;
    }
  }

  .modal-footer {
    align-items: center;
    background-color: #fff;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  .modal-content {
    background-color: transparent;
  }

  .form-control {
    width: 50%
  }

  .btn {
    background-color: #67ac45;
    color: white;
  }
}

.dx-scrollbar-horizontal.dx-scrollbar-hoverable {
  transition: height 25ms linear 75ms, background-color 25ms linear 75ms !important;
}

.dx-scrollbar-vertical.dx-scrollbar-hoverable {
  transition: width 25ms linear 75ms, background-color 25ms linear 75ms !important;
}

.dx-scrollbar-horizontal.dx-scrollbar-hoverable .dx-scrollable-scroll .dx-scrollable-scroll-content {
  transition: box-shadow 25ms linear 25ms, background-color 25ms linear 25ms !important;
}

.dx-scrollbar-horizontal.dx-scrollbar-hoverable .dx-scrollable-scroll {
  transition: height 25ms linear 75ms, background-color 25ms linear 75ms !important;
}

.dx-scrollbar-vertical.dx-scrollbar-hoverable .dx-scrollable-scroll {
  transition: width 25ms linear 75ms, background-color 25ms linear 75ms !important;
}
</style>
