/**
 * Inline module that can be used in both ES6 and ES3
 */
(function () {
  /**
  * @param {*} isAuthoziedChecker a function that checks if the current user is logged in (authorized)
  * @param {*} getSSOUrl a function that get the URL of the SSO
  * @param {*} getCurrentToken a function that gets the current authorization token
  * @param {*} intervalSeconds interval in seconds to call the keep-alive URL, defaults to 5 secs.
  */
  function keepAlive (ssoServerURL, isAuthoziedChecker, getCurrentToken, intervalSeconds) {
    // default the interval to 30 seconds

    intervalSeconds = intervalSeconds || (30 * 1000) // defaults to 30 seconds

    // check if we are fetching
    var isFetching = false

    // interval that calls the REST API
    var intervalRef = setInterval(function () {
      // only call if the user is logged in.
      if (isAuthoziedChecker()) {
        if (!isFetching) {
          isFetching = true
          fetch(ssoServerURL + '/token/postUpdateKeepAliveToken', {
            method: 'POST',
            headers: {
              'Authorization': 'Bearer ' + getCurrentToken(),
              'Content-Type': 'application/json'
            }
          }).then(responseJson => {
            isFetching = false
            // console error, if the response is not "success"
            responseJson.json().then(function (result) {
              if (result !== 'success') {
                console.error(result)
                console.error(responseJson)
              }
            })
          })
            .catch(function (error) {
              console.error(error)
            })
        }
      }
    }, intervalSeconds)

    /**
         * Stops the keepAlive interval
         */
    return function () {
      if (intervalRef) {
        clearInterval(intervalRef)
      }
    }
  }

  if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') { module.exports = keepAlive } else { window.keepAlive = keepAlive }
})()
