import Vue from 'vue'
import config from '@/configurations/config-environment'
import { BaseService } from '../baseService'

const managementWs = config.apis.managementWs

export class MailingService extends BaseService {
  sendEmail(emailInformation) {
    const data = {
      Authorisation: this.getAuthorizationObject(),
      ApplicationCode: 61,
      Sender: 'no-reply@terraindex.com',
      Recipient: emailInformation.Recipient,
      Subject: emailInformation.Subject,
      Description: emailInformation.Description,
      FileName: emailInformation.FileName,
      IsHtml: false,
      Base64Content: emailInformation.Base64Content
    }

    return Vue.http.post(`${managementWs}MailingRestService_V1_0/SendEmail`, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      if (response.data.Success === false) return
      return true
    })
  }
}
