import Vue from 'vue'
import config from '@/configurations/config-environment'
import { BaseService } from '../baseService'

const reportws = config.apis.reportGenerator

export class ExportServiceReportWS extends BaseService {
  getAnalysisSampleReportWS(projectId) {
    const languageCode = this.getLangCodeLongFromShort(this.getAuthorizationObject().Language)
    return Vue.http.get(`${reportws}GetAnlysisSample/${projectId}?lang=${languageCode}`)
      .then((response) => {
        if (!response || !response.status === 200) return false
        return response.data.Result
      })
  }

  setAnalysisSampleReportWS(tableData) {
    tableData = JSON.stringify(tableData)
    const languageCode = this.getLangCodeLongFromShort(this.getAuthorizationObject().Language)
    return Vue.http.put(`${reportws}UpdateAnlysisSample/?lang=${languageCode}`, tableData)
      .then((response) => {
        if (!response || !response.status === 200) return false
        return response
      })
  }

  getWaterSamplesReportWS(projectId) {
    const languageCode = this.getLangCodeLongFromShort(this.getAuthorizationObject().Language)
    return Vue.http.get(`${reportws}GetWaterSample/${projectId}?lang=${languageCode}`)
      .then((response) => {
        if (!response || !response.status === 200) return false
        return response.data.Result
      })
  }

  setWaterSamplesReportWS(tableData) {
    tableData = JSON.stringify(tableData)
    const languageCode = this.getLangCodeLongFromShort(this.getAuthorizationObject().Language)
    return Vue.http.put(`${reportws}UpdateWaterSample?lang=${languageCode}`, tableData)
      .then((response) => {
        if (!response || !response.status === 200) return false
        return response
      })
  }
}
