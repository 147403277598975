import Vue from 'vue'
import config from '@/configurations/config-environment'
import arrayUtils from '@/utils/arrayUtils'
import { BaseService } from '../baseService'

const dataws = config.apis.dataws

export class CadastralOvamService extends BaseService {
  updateCadastralOvam(cadastralOvam, PrID) {
    const data = {
      data: cadastralOvam,
      action: 'UPDATE'
    }

    data.data.Authorisation = this.getAuthorizationObject()

    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/SetCadastralOvam`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((resp) => {
        if (resp.status !== 200 || resp.data.ResultCode !== 'Import_Succes') {
          throw new Error(`Unable to save cadastral ovam. ${resp.data.ResultMessage}`)
        }
        let tblCadastralOvam = JSON.parse(resp.data.FileContent).VeldData.tblCadastralOvam
        tblCadastralOvam = arrayUtils.formatArray(tblCadastralOvam)
        return tblCadastralOvam
      })
  }

  createCadastralOvam(cadastralOvam, PrID) {
    const data = {
      data: cadastralOvam,
      action: 'INSERT'
    }

    data.data.Authorisation = this.getAuthorizationObject()

    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/SetCadastralOvam`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((resp) => {
        if (resp.status !== 200 || resp.data.ResultCode !== 'Import_Succes') {
          throw new Error(`Unable to save cadastral ovam. ${resp.data.ResultMessage}`)
        }
        return this.getCadastralOvam(PrID).then((table) => {
          return table.tblCadastralOvam
        })
      })
  }

  deleteCadastralOvam(cadastralOvam, PrID) {
    const data = {
      data: cadastralOvam,
      action: 'DELETE'
    }

    data.data.Authorisation = this.getAuthorizationObject()

    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/SetCadastralOvam`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((resp) => {
        if (resp.status !== 200 || resp.data.ResultCode !== 'Import_Succes') {
          throw new Error(`Unable to save cadastral ovam. ${resp.data.ResultMessage}`)
        }
        return this.getCadastralOvam(PrID).then((table) => {
          return table.tblCadastralOvam
        })
      })
  }

  updateCadastralOvamCustomer(cadastralOvamCustomerRow, PrID, CaID) {
    const data = {
      data: cadastralOvamCustomerRow,
      action: 'UPDATE'
    }
    data.data.Authorisation = this.getAuthorizationObject()
    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/SetCadastralOvamCustomer`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((resp) => {
        if (resp.status !== 200 || resp.data.ResultCode !== 'Import_Succes') {
          throw new Error(`Unable to save cadastral ovam customer. ${resp.data.ResultMessage}`)
        }
        let tblCadastralOvamCustomer = JSON.parse(resp.data.FileContent).VeldData.tblCadastralOvamCustomers
        tblCadastralOvamCustomer = arrayUtils.formatArray(tblCadastralOvamCustomer)
        tblCadastralOvamCustomer = tblCadastralOvamCustomer.filter((coc) => coc.CaID === CaID)
        return tblCadastralOvamCustomer
      })
  }

  createCadastralOvamCustomer(cadastralOvamCustomerRow, PrID, CaID) {
    const data = {
      data: cadastralOvamCustomerRow,
      action: 'INSERT'
    }

    data.data.Authorisation = this.getAuthorizationObject()

    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/SetCadastralOvamCustomer`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((resp) => {
        if (resp.status !== 200 || resp.data.ResultCode !== 'Import_Succes') {
          throw new Error(`Unable to save cadastral ovam customer. ${resp.data.ResultMessage}`)
        }
        return this.getCadastralOvamCustomers(PrID).then((table) => {
          return table.tblCadastralOvamCustomers.filter((coc) => coc.CaID === CaID)
        })
      })
  }

  deleteCadastralOvamCustomer(cadastralOvamCustomerRow, PrID, CaID) {
    const data = {
      data: cadastralOvamCustomerRow,
      action: 'DELETE'
    }

    data.data.Authorisation = this.getAuthorizationObject()

    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/SetCadastralOvamCustomer`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((resp) => {
        if (resp.status !== 200 || resp.data.ResultCode !== 'Import_Succes') {
          throw new Error(`Unable to save cadastral ovam customer. ${resp.data.ResultMessage}`)
        }
        return this.getCadastralOvamCustomers(PrID).then((table) => {
          return table.tblCadastralOvamCustomers.filter((coc) => coc.CaID === CaID)
        })
      })
  }

  createCadastralOvamVlaremAct(cadastralOvamVlaremAct, PrID, CaID) {
    const data = {
      data: cadastralOvamVlaremAct,
      action: 'INSERT'
    }
    data.data.Authorisation = this.getAuthorizationObject()
    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/SetCadastralOvamVlaremAct`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((resp) => {
        if (resp.status !== 200 || resp.data.ResultCode !== 'Import_Succes') {
          throw new Error(`Unable to save cadastral ovam vlarem act. ${resp.data.ResultMessage}`)
        }
        return this.getCadastralOvamVlaremActs(PrID).then((table) => {
          return table.tblCadastralOvamVlaremAct.filter((cova) => cova.CaID === CaID)
        })
      })
  }

  updateCadastralOvamVlaremAct(cadastralOvamVlaremAct, PrID, CaID) {
    const data = {
      data: cadastralOvamVlaremAct,
      action: 'UPDATE'
    }
    data.data.Authorisation = this.getAuthorizationObject()
    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/SetCadastralOvamVlaremAct`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((resp) => {
        if (resp.status !== 200 || resp.data.ResultCode !== 'Import_Succes') {
          throw new Error(`Unable to save cadastral ovam vlarem act. ${resp.data.ResultMessage}`)
        }
        let tblCadastralOvamVlaremAct = JSON.parse(resp.data.FileContent).VeldData.tblCadastralOvamVlaremAct
        tblCadastralOvamVlaremAct = arrayUtils.formatArray(tblCadastralOvamVlaremAct)
        tblCadastralOvamVlaremAct = tblCadastralOvamVlaremAct.filter((cova) => cova.CaID === CaID)
        return tblCadastralOvamVlaremAct
      })
  }

  deleteCadastralOvamVlaremAct(cadastralOvamVlaremAct, PrID, CaID) {
    const data = {
      data: cadastralOvamVlaremAct,
      action: 'DELETE'
    }
    data.data.Authorisation = this.getAuthorizationObject()
    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/SetCadastralOvamVlaremAct`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((resp) => {
        if (resp.status !== 200 || resp.data.ResultCode !== 'Import_Succes') {
          throw new Error(`Unable to save cadastral ovam vlarem act. ${resp.data.ResultMessage}`)
        }
        return this.getCadastralOvamVlaremActs(PrID).then((table) => {
          return table.tblCadastralOvamVlaremAct.filter((cova) => cova.CaID === CaID)
        })
      })
  }

  getCadastralOvam(projectID) {
    const authorisation = this.getAuthorizationObject()
    const languageCode = this.getLangCodeLongFromShort(authorisation.Language)
    const data = {
      Authorisation: authorisation,
      LanguageCode: languageCode,
      WebserviceVersion: '1.0',
      TypeOfExport: 'Export_FIELD_JSON_v1_0_0',
      Parameters: [
        {
          ParameterType: 'ExportFormat',
          StringValue: 'json'
        },
        {
          ParameterType: 'ProjectID',
          StringValue: projectID.toString(),
          FloatValue: projectID.toString()
        }
      ],
      logFormat: 'XML'
    }

    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/GetCadastralOvam`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((resp) => {
        if (resp.status !== 200 || resp.data.ResultCode !== 'Export_Succes' || typeof resp.data.Files[0] !== 'object') {
          throw new Error(`Unable to get Cadastral Ovam. ${resp.data.ResultMessage}`)
        }

        const veldData = JSON.parse(resp.data.Files[0].FileContent).VeldData
        const tblCadastralOvam = arrayUtils.formatArray(veldData.tblCadastralOvam)
        const tblProjects = arrayUtils.formatArray(veldData.tblProjects)

        return {
          tblCadastralOvam,
          tblProjects
        }
      })
  }

  getCadastralOvamVlaremActs(projectID) {
    const authorisation = this.getAuthorizationObject()
    const languageCode = this.getLangCodeLongFromShort(authorisation.Language)
    const data = {
      Authorisation: authorisation,
      LanguageCode: languageCode,
      WebserviceVersion: '1.0',
      TypeOfExport: 'Export_FIELD_JSON_v1_0_0',
      Parameters: [
        {
          ParameterType: 'ExportFormat',
          StringValue: 'json'
        },
        {
          ParameterType: 'ProjectID',
          StringValue: projectID.toString(),
          FloatValue: projectID.toString()
        }
      ],
      logFormat: 'XML'
    }

    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/GetCadastralOvamVlaremAct`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((resp) => {
        if (resp.status !== 200 || resp.data.ResultCode !== 'Export_Succes' || typeof resp.data.Files[0] !== 'object') {
          throw new Error(`Unable to get Cadastral Ovam. ${resp.data.ResultMessage}`)
        }
        const veldData = JSON.parse(resp.data.Files[0].FileContent).VeldData
        const tblCadastralOvamVlaremAct = arrayUtils.formatArray(veldData.tblCadastralOvamVlaremAct)
        const tblCadastralOvam = arrayUtils.formatArray(veldData.tblCadastralOvam)
        const tblProjects = arrayUtils.formatArray(veldData.tblProjects)

        return {
          tblCadastralOvamVlaremAct,
          tblCadastralOvam,
          tblProjects
        }
      })
  }

  getCadastralOvamCustomers(projectID) {
    const authorisation = this.getAuthorizationObject()
    const languageCode = this.getLangCodeLongFromShort(authorisation.Language)
    const data = {
      Authorisation: authorisation,
      LanguageCode: languageCode,
      WebserviceVersion: '1.0',
      TypeOfExport: 'Export_FIELD_JSON_v1_0_0',
      Parameters: [
        {
          ParameterType: 'ExportFormat',
          StringValue: 'json'
        },
        {
          ParameterType: 'ProjectID',
          StringValue: projectID.toString(),
          FloatValue: projectID.toString()
        }
      ],
      logFormat: 'XML'
    }

    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/GetCadastralOvamCustomer`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((resp) => {
        if (resp.status !== 200 || resp.data.ResultCode !== 'Export_Succes' || typeof resp.data.Files[0] !== 'object') {
          throw new Error(`Unable to get Cadastral Ovam. ${resp.data.ResultMessage}`)
        }
        const veldData = JSON.parse(resp.data.Files[0].FileContent).VeldData
        const tblCadastralOvamCustomers = arrayUtils.formatArray(veldData.tblCadastralOvamCustomers)
        const tblCadastralOvam = arrayUtils.formatArray(veldData.tblCadastralOvam)
        const tblProjects = arrayUtils.formatArray(veldData.tblProjects)

        return {
          tblCadastralOvamCustomers,
          tblCadastralOvam,
          tblProjects
        }
      })
  }
}
