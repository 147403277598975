'use strict'

const twsMapping = {
  watersample: [
    'WsDate',
    'WsMatrixCode',
    'WsName',
    'WsPumpTypeCode',
    'WsRefTopFtCode',
    'WsRemark',
    'WsSampleTakerCode',
    'WsTopFt',
    'WsSpecialConditions',
    'WsFieldConditions',
    'WsStartSampleTime',
    'WsPostSampleTime'
  ],
  observation: [
    {
      key: 'WsColourMainCode',
      field: 'ObPropertyCode',
      type: '3',
      unit: '-1'
    },
    {
      key: 'WsColourSubCode',
      field: 'ObPropertyCode',
      type: '4',
      unit: '-1'
    },
    {
      key: 'WsColourShadeCode',
      field: 'ObPropertyCode',
      type: '5',
      unit: '-1'
    },
    {
      key: 'WsOdourCode',
      field: 'ObPropertyCode',
      type: '6',
      unit: '-1'
    },
    {
      key: 'WsOdourGradationCode',
      field: 'ObPropertyCode',
      type: '7',
      unit: '-1'
    },
    {
      key: 'WsGwtReferencePointTypeCode',
      field: 'ObPropertyCode',
      type: '14',
      unit: '-1'
    },
    {
      key: 'WsYieldCode',
      field: 'ObPropertyCode',
      type: '28',
      unit: '-1'
    },
    {
      key: 'WsClarityCode',
      field: 'ObPropertyCode',
      type: '29',
      unit: '-1'
    },
    {
      key: 'WsGwlBelowFilter',
      field: 'ObPropertyCode',
      type: '40',
      unit: '-1'
    },
    {
      key: 'WsPollutionGradation',
      field: 'ObPropertyCode',
      type: '42',
      unit: '-1'
    },
    {
      key: 'WsPollutionGroup',
      field: 'ObPropertyCode',
      type: '41',
      unit: '-1'
    },
    {
      key: 'WsPrePumpTypeCode',
      field: 'ObPropertyCode',
      type: '44',
      unit: '-1'
    },
    {
      key: 'WsPrePumpEndingTypeCode',
      field: 'ObPropertyCode',
      type: '48',
      unit: '-1'
    },
    {
      key: 'WsFoamingTypeCode',
      field: 'ObPropertyCode',
      type: '50',
      unit: '-1'
    },
    {
      key: 'WsSuspendedParticlesTypeCode',
      field: 'ObPropertyCode',
      type: '51',
      unit: '-1'
    },
    {
      key: 'WsSuspendedParticlesColourCode',
      field: 'ObPropertyCode',
      type: '52',
      unit: '-1'
    },
    {
      key: 'WsBubbleFormationTypeCode',
      field: 'ObPropertyCode',
      type: '53',
      unit: '-1'
    },
    {
      key: 'WsInflowTypeCode',
      field: 'ObPropertyCode',
      type: '54',
      unit: '-1'
    },
    {
      key: 'WsOldHoseTypeCode',
      field: 'ObPropertyCode',
      type: '55',
      unit: '-1'
    },
    {
      key: 'WsTubeStateTypeCode',
      field: 'ObPropertyCode',
      type: '56',
      unit: '-1'
    },
    {
      key: 'WsPossibleSiltingTypeCode',
      field: 'ObPropertyCode',
      type: '57',
      unit: '-1'
    },
    {
      key: 'WsWaterDumpTypeCode',
      field: 'ObPropertyCode',
      type: '58',
      unit: '-1'
    },
    {
      key: 'WsOxygenUnitCode',
      field: 'ObMeasurementUnit',
      type: '23',
      unit: '60'
    },
    {
      key: 'WsRedox',
      field: 'ObMeasurementValue',
      type: '1',
      unit: '75'
    },
    {
      key: 'WsPh',
      field: 'ObMeasurementValue',
      type: '2',
      unit: '-1'
    },
    {
      key: 'WsGwt',
      field: 'ObMeasurementValue',
      type: '11',
      unit: '19'
    },
    {
      key: 'WsTemperature',
      field: 'ObMeasurementValue',
      type: '12',
      unit: '8'
    },
    {
      key: 'WsPid',
      field: 'ObMeasurementValue',
      type: '13',
      unit: '132'
    },
    {
      key: 'WsAmountPumped',
      field: 'ObMeasurementValue',
      type: '15',
      unit: '44'
    },
    {
      key: 'WsFlowSpeed',
      field: 'ObMeasurementValue',
      type: '16',
      unit: '48'
    },
    {
      key: 'WsEc1',
      field: 'ObMeasurementValue',
      type: '8',
      unit: '18'
    },
    {
      key: 'WsEcMin1',
      field: 'ObMeasurementValue',
      type: '18',
      unit: '133'
    },
    {
      key: 'WsEc2',
      field: 'ObMeasurementValue',
      type: '9',
      unit: '18'
    },
    {
      key: 'WsEcMin2',
      field: 'ObMeasurementValue',
      type: '19',
      unit: '133'
    },
    {
      key: 'WsEc3',
      field: 'ObMeasurementValue',
      type: '10',
      unit: '18'
    },
    {
      key: 'WsEcMin3',
      field: 'ObMeasurementValue',
      type: '20',
      unit: '133'
    },
    {
      key: 'WsCrustTop',
      field: 'ObMeasurementValue',
      type: '21',
      unit: '19'
    },
    {
      key: 'WsFounderLayerTop',
      field: 'ObMeasurementValue',
      type: '22',
      unit: '19'
    },
    {
      key: 'WsOxygen',
      field: 'ObMeasurementValue',
      type: '23',
      unit: '60'
    },
    {
      key: 'WsAirPressure',
      field: 'ObMeasurementValue',
      type: '24',
      unit: '36'
    },
    {
      key: 'WsCrust',
      field: 'ObPropertyCode',
      type: '26',
      unit: '-1',
      transforms: {
        true: 'J',
        false: 'N'
      }
    },
    {
      key: 'WsFounderLayer',
      field: 'ObPropertyCode',
      type: '27',
      unit: '-1',
      transforms: {
        true: 'T',
        false: 'F'
      }
    },
    {
      key: 'WsTurbidityPrePumped',
      field: 'ObMeasurementValue',
      type: '38',
      unit: '200'
    },
    {
      key: 'WsTurbiditySample',
      field: 'ObMeasurementValue',
      type: '39',
      unit: '200'
    },
    {
      key: 'WsSuctionPointDepth',
      field: 'ObMeasurementValue',
      type: '43',
      unit: '19'
    },
    {
      key: 'WsPrePumpStartTime',
      field: 'ObObservationDate',
      type: '45',
      unit: '-1'
    },
    {
      key: 'WsPrePumpEndTime',
      field: 'ObObservationDate',
      type: '46',
      unit: '-1'
    },
    {
      key: 'WsPrePumpPumpFlowRate',
      field: 'ObMeasurementValue',
      type: '47',
      unit: '48'
    },
    {
      key: 'WsGwlAfterSampling',
      field: 'ObMeasurementValue',
      type: '49',
      unit: '19'
    },
    {
      key: 'WsThicknessFloatingLayer',
      field: 'ObMeasurementValue',
      type: '89',
      unit: '19'
    },
    {
      key: 'WsThicknessSinkLayer',
      field: 'ObMeasurementValue',
      type: '90',
      unit: '19'
    },
    {
      key: 'WsVolumeGaugingtube',
      field: 'ObMeasurementValue',
      type: '91',
      unit: '44'
    },
    {
      key: 'WsVolumeFloatingLayerExtracted',
      field: 'ObMeasurementValue',
      type: '92',
      unit: '44'
    },
    {
      key: 'WsThicknessFloatingLayerExtracted',
      field: 'ObMeasurementValue',
      type: '93',
      unit: '19'
    },
    {
      key: 'WsTakenOfFloatingLayer',
      field: 'ObPropertyCode',
      type: '83',
      unit: '-1'
    },
    {
      key: 'WsSamplingDiscontinued',
      field: 'ObPropertyCode',
      type: '84',
      unit: '-1'
    },
    {
      key: 'WsOilWaterReaction',
      field: 'ObPropertyCode',
      type: '85',
      unit: '-1'
    },
    {
      key: 'WsTypePid',
      field: 'ObPropertyCode',
      type: '86',
      unit: '-1'
    },
    {
      key: 'WsConditioning',
      field: 'ObPropertyCode',
      type: '88',
      unit: '-1'
    },
    {
      key: 'WsGaugingTubeDepth',
      field: 'ObMeasurementValue',
      type: '94',
      unit: '1'
    },
    {
      key: 'WsReferenceGaugingTubeDepth',
      field: 'ObPropertyCode',
      type: '95',
      unit: '-1'
    },
    {
      key: 'WsStandardVoltage',
      field: 'ObMeasurementValue',
      type: '98',
      unit: '75'
    },
    {
      key: 'WsWeatherConditions',
      field: 'ObPropertyCode',
      type: '99',
      unit: '-1'
    },
    {
      key: 'WsGWLafterPrepumping',
      field: 'ObMeasurementValue',
      type: '100',
      unit: '19'
    },
    {
      key: 'WsAirTemperature',
      field: 'ObMeasurementValue',
      type: '108',
      unit: '8'
    },
    {
      key: 'WsPurgeStopCriteriaCode',
      field: 'ObPropertyCode',
      type: '107',
      unit: '-1'
    },
    {
      key: 'WsSampledVolume',
      field: 'ObMeasurementValue',
      type: '109',
      unit: '44'
    },
    {
      key: 'WsSamplingMethodTypeCode',
      field: 'ObPropertyCode',
      type: '110',
      unit: '-1'
    },
    {
      key: 'WsOxygenPercentage',
      field: 'ObMeasurementValue',
      type: '111',
      unit: '1'
    },
    {
      key: 'WsCarbonDioxine',
      field: 'ObMeasurementValue',
      type: '113',
      unit: '132'
    },
    {
      key: 'WsHumidityPercentage',
      field: 'ObMeasurementValue',
      type: '115',
      unit: '1'
    },
    {
      key: 'WsFloatingLayerRemoveMethodCode',
      field: 'ObPropertyCode',
      type: '119',
      unit: '-1'
    },
    {
      key: 'WsFloatingLayerSystemActionCode',
      field: 'ObPropertyCode',
      type: '120',
      unit: '-1'
    },
    {
      key: 'WsSaturationAbsorptionStickCode',
      field: 'ObPropertyCode',
      type: '121',
      unit: '-1'
    },
    {
      key: 'WsPurgeMethodCode',
      field: 'ObPropertyCode',
      type: '122',
      unit: '-1'
    },
    {
      key: 'WsPurgeModeCode',
      field: 'ObPropertyCode',
      type: '123',
      unit: '-1'
    },
    {
      key: 'WsTotalVoltage',
      field: 'ObMeasurementValue',
      type: '124',
      unit: '75'
    },
    {
      key: 'WsCleanedCode',
      field: 'ObPropertyCode',
      type: '125',
      unit: '-1'
    },
    {
      key: 'WsDepthBeforeCleaning',
      field: 'ObMeasurementValue',
      type: '126',
      unit: '1'
    },
    {
      key: 'WsReplenishmentCode',
      field: 'ObPropertyCode',
      type: '127',
      unit: '-1'
    },
    {
      key: 'WsLowerExplosiveLevel',
      field: 'ObMeasurementValue',
      type: '128',
      unit: '1'
    },
    {
      key: 'WsH2S',
      field: 'ObMeasurementValue',
      type: '130',
      unit: '1'
    },
    {
      key: 'WsPrepumpSuctionPointDepth',
      field: 'ObMeasurementValue',
      type: '133',
      unit: '19'
    },
    {
      key: 'WsPrepumpFTDryCode',
      field: 'ObPropertyCode',
      type: '134',
      unit: '-1'
    },
    {
      key: 'WsCalibrationO2',
      field: 'ObMeasurementValue',
      type: '136',
      unit: '1'
    },
    {
      key: 'WsCalibrationCO2',
      field: 'ObMeasurementValue',
      type: '137',
      unit: '132'
    },
    {
      key: 'WsPostSampleO2',
      field: 'ObMeasurementValue',
      type: '140',
      unit: '1'
    },
    {
      key: 'WsPostSampleCO2',
      field: 'ObMeasurementValue',
      type: '141',
      unit: '132'
    },
    {
      key: 'WsPostSampleFlowRate',
      field: 'ObMeasurementValue',
      type: '142',
      unit: '48'
    },
    {
      key: 'WsPostSamplePID',
      field: 'ObMeasurementValue',
      type: '143',
      unit: '132'
    },
    {
      key: 'WsPostSampleTemperature',
      field: 'ObMeasurementValue',
      type: '144',
      unit: '8'
    },
    {
      key: 'WsPostSampleHumidity',
      field: 'ObMeasurementValue',
      type: '145',
      unit: '1'
    },
    {
      key: 'WsPostSamplePressure',
      field: 'ObMeasurementValue',
      type: '146',
      unit: '36'
    },
    {
      key: 'WsSurroundingHumidity',
      field: 'ObPropertyCode',
      type: '147',
      unit: '-1'
    },
    {
      key: 'WsSurfaceWaterFlowTypeCode',
      field: 'ObPropertyCode',
      type: '148',
      unit: '-1'
    },
    {
      key: 'WsSurfaceWaterFlowRateCode',
      field: 'ObPropertyCode',
      type: '149',
      unit: '-1'
    },
    {
      key: 'WsPumpPowerConnectionCode',
      field: 'ObPropertyCode',
      type: '150',
      unit: '-1'
    },
    {
      key: 'WsSeriesMeasurementPumpedVolume',
      field: 'ObMeasurementValue',
      type: '151',
      unit: '44'
    },
    {
      key: 'WsSeriesMeasurementDuration',
      field: 'ObMeasurementValue',
      type: '153',
      unit: '133'
    },
    {
      key: 'WsLowerExplosiveLevelVolPercent',
      field: 'ObMeasurementValue',
      type: '155',
      unit: '1'
    },
    {
      key: 'WsBenzenePID',
      field: 'ObMeasurementValue',
      type: '156',
      unit: '132'
    },
    {
      key: 'WsSmokeTubeTestCode',
      field: 'ObPropertyCode',
      type: '157',
      unit: '-1'
    },
    {
      key: 'WsRecommendedPrepumpVolume',
      field: 'ObMeasurementValue',
      type: '158',
      unit: '44'
    },
    {
      key: 'WsTubeFlushVolume',
      field: 'ObMeasurementValue',
      type: '159',
      unit: '44'
    },
    {
      key: 'WsTubeDryCode',
      field: 'ObPropertyCode',
      type: '160',
      unit: '-1'
    },
    {
      key: 'WsCOppm',
      field: 'ObMeasurementValue',
      type: '161',
      unit: '132'
    },
    {
      key: 'WsCH4ppm',
      field: 'ObMeasurementValue',
      type: '162',
      unit: '132'
    },
    {
      key: 'WsGWLBeforeSampling',
      field: 'ObMeasurementValue',
      type: '165',
      unit: '29'
    }
  ],
  'measurement': {

  }
}

export default twsMapping
