export default {
  showDecimals: function (decimalInput, decimals) {
    try {
      return parseFloat(decimalInput).toFixed(decimals)
    } catch (e) {
      console.info('Not a number received')
      return decimalInput
    }
  },

  isNullOrEmptyOrWhitespace(value) {
    return !value || value === ''
  },

  returnDecimalForWgs84(ctrl, inWGS84Bounds, stringInput) {
    if (ctrl && inWGS84Bounds === 'WG') {
      return this.showDecimals(stringInput, 8)
    } else {
      return this.showDecimals(stringInput, 2)
    }
  },

  getFileExtension(filename) {
    return filename.split('.').pop()
  },

  escapeRegExp (str) {
    // eslint-disable-next-line no-useless-escape
    return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&')
  },

  convert2To3LetterLanguageCode (langCode) {
    switch (langCode) {
      case 'nl':
        return 'nld'
      case 'fr':
        return 'fra'
      case 'de':
        return 'deu'
      case 'it':
        return 'ita'
      case 'es':
        return 'spa'
      default:
        return 'eng'
    }
  },

  convertFourToTwoLanguageCode (fourLanguage) {
    switch (fourLanguage) {
      case 'nl-NL':
        return 'nl'
      case 'fr-FR':
        return 'fr'
      case 'de-DE':
        return 'de'
      case 'it-IT':
        return 'it'
      case 'es-ES':
        return 'es'
      default:
        return 'en'
    }
  },

  capitalizeFirstLetter(string) {
    if (!string) return ''
    return string.charAt(0).toUpperCase() + string.slice(1)
  },

  fixStringLength (str, length) {
    return str.length > length ? str.substring(0, length) : str
  }
}
