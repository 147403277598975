import Vue from 'vue'
import config from '@/configurations/config-environment'
import moment from 'moment'
import { BaseService } from '../baseService'

const dataws = config.apis.dataws

export class ProjectService extends BaseService {
  setProject(project, action) {
    project.PrDateLastChanged = moment().utc().add(3, 'hour').format('YYYY-MM-DD HH:mm:ss')

    if (!action) action = 'UPDATE' // default is 'update'

    const data = {
      data: project,
      action
    }

    data.data.Authorisation = this.getAuthorizationObject()

    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/SetProject`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((resp) => {
        if (resp.status !== 200 || resp.data.ResultCode !== 'Import_Succes') throw new Error(`Unable to save project. ${resp.data.ResultMessage}`)

        return JSON.parse(resp.data.FileContent).VeldData.tblProjects
      })
  }

  SetProjectWithChildsCall(projectID, req) {
    var msg = {
      data: {
        PrID: projectID,
        velddata: {
          VeldData: req
        },
        Authorisation: this.getAuthorizationObject()
      },
      action: 'UPDATE'
    }

    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/SetProjectWithChilds`, msg, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        if (response.status !== 200 || response.data.ResultCode !== 'Import_Succes') {
          throw new Error(`SetProjectWithChilds failed for projectID: ${projectID}.\nResultMessage: ${response.data.ResultMessage}\nResultCode: ${response.data.ResultCode}`)
        }
        return true
      })
  }

  deleteProject(prID, prCode, prGuid) {
    const data = {
      data: {
        PrCode: prCode,
        PrID: prID,
        PrGuid: prGuid,
        Authorisation: this.getAuthorizationObject()
      },
      action: 'DELETE'
    }

    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/SetProject`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((resp) => {
        if (resp.status !== 200 || resp.data.ResultCode !== 'Import_Succes') throw new Error(`Unable to delete project. ${resp.data.ResultMessage}`)

        return true
      })
  }

  getSingleProject(prID) {
    const authorisation = this.getAuthorizationObject()
    const languageCode = this.getLangCodeLongFromShort(authorisation.Language)
    const data = {
      Authorisation: authorisation,
      LanguageCode: languageCode,
      WebserviceVersion: '1.0',
      TypeOfExport: 'Export_FIELD_JSON_v1_0_0',
      Parameters: [
        {
          ParameterType: 'ExportFormat',
          StringValue: 'json'
        },
        {
          ParameterType: 'ProjectID',
          StringValue: prID.toString(),
          FloatValue: prID.toString()
        }
      ],
      logFormat: 'XML'
    }

    return Vue.http.post(`${dataws}ITWDataRestService_V1_0/GetSingleProject`, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  GetProjectRecord(prID) {
    const authorisation = this.getAuthorizationObject()
    const languageCode = this.getLangCodeLongFromShort(authorisation.Language)
    const data = {
      Authorisation: authorisation,
      LanguageCode: languageCode,
      WebserviceVersion: '1.0',
      TypeOfExport: 'Export_FIELD_JSON_v1_0_0',
      Parameters: [
        {
          ParameterType: 'ExportFormat',
          StringValue: 'json'
        },
        {
          ParameterType: 'ProjectID',
          StringValue: prID.toString(),
          FloatValue: prID.toString()
        }
      ],
      logFormat: 'XML'
    }

    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/GetSingleProject`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        if (response.status !== 200 && response.data.ResultCode !== '"Export_Succes"') throw new Error(`Unable to retrieve the project record. ${response.data}`)
        return JSON.parse(response.data.Files[0].FileContent).VeldData.tblProjects
      })
  }

  validateProject(prCode) {
    const data = {
      data: {
        PrCode: prCode,
        Authorisation: this.getAuthorizationObject()
      }
    }

    return Vue.http.post(`${dataws}ITWDataRestService_V1_0/ValidateProjectCode`, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }
}
