import Vue from 'vue'
import Router from 'vue-router'
import _ from 'lodash'
import translation from '@/configurations/app/config-locales'

import store from '@/vuex/store'
import authenticationProviders from '@/utils/authenticationProvider'
import config from '@/configurations/config'
import searchComponentUtils from './utils/searchComponentUtils'

let authenticationProvider = authenticationProviders[config.platform].default

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login'),
      meta: {
        auth_required: false
      }
    },
    {
      path: '/connect-azure',
      name: 'connectAzureAuthentication',
      component: () => import('@/azure-auth/ConnectAzureAuthentication'),
      meta: {
        auth_required: false
      }
    },
    {
      path: '/generate-pincode',
      name: 'generatePincode',
      component: () => import('@/azure-auth/GeneratePincode'),
      meta: {
        auth_required: false
      }
    },
    {
      path: '/',
      name: 'projects',
      component: () => import(/* webpackChunkName: "projects" */ '@/views/Projects'),
      meta: {
        buttons: {
          left: {
            import: {
              name: 'import',
              handler: 'importProject',
              class: 'glyphicons glyphicons-cloud-upload',
              requiresSelection: false,
              visible: true
            },
            export: {
              name: 'export',
              handler: 'toggleExportPopup',
              class: 'glyphicons glyphicons-cloud-download',
              requiresSelection: 1,
              visible: true
            },
            verify: {
              name: 'verify',
              handler: 'verifyProject',
              class: 'glyphicons glyphicons-disk-saved',
              requiresSelection: 1,
              visible: true
            },
            report: {
              name: 'report',
              handler: 'generateReport',
              class: 'glyphicons glyphicons-book',
              requiresSelection: 1,
              visible: true
            }
          },
          right: {
            newproject: {
              name: 'newProject',
              handler: 'createProject',
              class: 'glyphicons glyphicons-plus',
              requiresSelection: false,
              visible: true
            }
          }
        }
      }
    },
    {
      path: '/project/:projectId',
      name: 'project',
      component: () => import(/* webpackChunkName: "project" */ '@/views/Project'),
      beforeEnter: async (to, from, next) => {
        const defaultView = await store.dispatch('getSettingByName', {
          settingName: 'defaultView'
        })
        if (from.name !== 'overview' && defaultView.data.SettingValue === 'table') {
          router.push(`/project/${to.params.projectId}/overview/108`)
          return
        } else if (from.name !== 'dashboard' && defaultView.data.SettingValue === 'dashboard') {
          router.push(`/project/${to.params.projectId}/dashboard/1`)
          return
        }
        next()
      },
      meta: {
        buttons: {
          left: {
            save: {
              name: 'save',
              handler: 'save',
              class: 'glyphicons glyphicons-floppy-disk',
              requiresSelection: false,
              visible: false
            },
            export: {
              name: 'export',
              handler: 'toggleExportPopup',
              class: 'glyphicons glyphicons-cloud-download',
              visible: true
            },
            report: {
              name: 'report',
              handler: 'generateReport',
              class: 'glyphicons glyphicons-book',
              visible: true
            },
            borelog: {
              name: 'borelog',
              handler: 'generateReport',
              class: 'glyphicons glyphicons-newspaper',
              visible: true
            },
            xyzimport: {
              name: 'xyzimport',
              handler: 'xyzImport',
              class: 'glyphicons glyphicons-paste',
              visible: true
            },
            ovam: {
              name: 'ovamOverview',
              handler: 'gotoOvamOverview',
              class: 'glyphicons glyphicons-cadastral-map',
              visible: true
            }
          },
          right: {
            deleteproject: {
              name: 'deleteProject',
              handler: 'deleteProject',
              class: 'glyphicons glyphicons-bin',
              requiresSelection: false,
              visible: true
            }
          }
        }
      }
    },
    {
      path: '/project/:projectId/concernedContact/:CcGUID',
      name: 'concernedContact',
      component: () => import(/* webpackChunkName: "concernedContact" */ '@/views/ConcernedContact'),
      meta: {
        buttons: {
          left: {
            save: {
              name: 'save',
              handler: 'save',
              class: 'glyphicons glyphicons-floppy-disk',
              requiresSelection: false,
              visible: false
            },
            export: {
              name: 'export',
              handler: 'toggleExportPopup',
              class: 'glyphicons glyphicons-cloud-download',
              visible: true
            }
          },
          right: {
            deleteLayer: {
              name: 'deleteConcernedContact',
              handler: 'deleteConcernedContact',
              class: 'glyphicons glyphicons-bin',
              requiresSelection: false,
              visible: true
            }
          }
        }
      }
    },
    {
      path: '/project/:projectId/subLocation/:SlGUID/',
      name: 'subLocation',
      component: () => import(/* webpackChunkName: "subLocation" */ '@/views/SubLocation'),
      meta: {
        buttons: {
          left: {
            save: {
              name: 'save',
              handler: 'save',
              class: 'glyphicons glyphicons-floppy-disk',
              requiresSelection: false,
              visible: false
            },
            export: {
              name: 'export',
              handler: 'toggleExportPopup',
              class: 'glyphicons glyphicons-cloud-download',
              visible: true
            }
          },
          right: {
            deleteSubLocation: {
              name: 'deleteSubLocation',
              handler: 'deleteSubLocation',
              class: 'glyphicons glyphicons-bin',
              requiresSelection: false,
              visible: true
            }
          }
        }
      }
    },
    {
      path: '/project/:projectId/summaryAndConclusions/:SmGUID/',
      name: 'summaryAndConclusion',
      component: () => import(/* webpackChunkName: "subLocation" */ '@/views/SummaryAndConclusion'),
      meta: {
        buttons: {
          left: {
            save: {
              name: 'save',
              handler: 'save',
              class: 'glyphicons glyphicons-floppy-disk',
              requiresSelection: false,
              visible: false
            },
            export: {
              name: 'export',
              handler: 'toggleExportPopup',
              class: 'glyphicons glyphicons-cloud-download',
              visible: true
            }
          },
          right: {
            deleteSummaryAndConclusion: {
              name: 'deleteSummaryAndConclusion',
              handler: 'deleteSummaryAndConclusion',
              class: 'glyphicons glyphicons-bin',
              requiresSelection: false,
              visible: true
            }
          }
        }
      }
    },
    {
      path: '/project/:projectId/measurementPoint/:measurementPointId/',
      name: 'measurementPoint',
      component: () => import(/* webpackChunkName: "measurementPoint" */ '@/views/MeasurementPoint'),
      meta: {
        buttons: {
          left: {
            save: {
              name: 'save',
              handler: 'save',
              class: 'glyphicons glyphicons-floppy-disk',
              requiresSelection: false,
              visible: false
            },
            export: {
              name: 'export',
              handler: 'toggleExportPopup',
              class: 'glyphicons glyphicons-cloud-download',
              visible: true
            },
            report: {
              name: 'report',
              handler: 'generateReport',
              class: 'glyphicons glyphicons-book',
              visible: true
            },
            borelog: {
              name: 'borelog',
              handler: 'generateReport',
              class: 'glyphicons glyphicons-newspaper',
              visible: true
            }
          },
          right: {
            duplicateMeasurementPoint: {
              name: 'duplicateMeasurementPoint',
              handler: 'duplicateMeasurementPoint',
              class: 'glyphicons glyphicons-duplicate',
              requiresSelection: false,
              visible: true
            },
            deleteMeasurementPoint: {
              name: 'deleteMeasurementPoint',
              handler: 'deleteMeasurementPoint',
              class: 'glyphicons glyphicons-bin',
              requiresSelection: false,
              visible: true
            }
          }
        }
      }
    },
    {
      path: '/project/:projectId/measurementPoint/:measurementPointId/:openMap',
      name: 'measurementPointMap',
      component: () => import(/* webpackChunkName: "measurementPointMap" */ '@/views/MeasurementPoint'),
      meta: {
        buttons: {
          left: {
            save: {
              name: 'save',
              handler: 'save',
              class: 'glyphicons glyphicons-floppy-disk',
              requiresSelection: false,
              visible: false
            },
            export: {
              name: 'export',
              handler: 'toggleExportPopup',
              class: 'glyphicons glyphicons-cloud-download',
              visible: true
            },
            borelog: {
              name: 'borelog',
              handler: 'generateReport',
              class: 'glyphicons glyphicons-newspaper',
              visible: true
            }
          },
          right: {
            deleteMeasurementPoint: {
              name: 'deleteMeasurementPoint',
              handler: 'deleteMeasurementPoint',
              class: 'glyphicons glyphicons-bin',
              requiresSelection: false,
              visible: true
            }
          }
        }
      }
    },
    {
      path: '/project/:projectId/measurementPoint/:measurementPointId/layer/:layerID',
      name: 'layer',
      component: () => import(/* webpackChunkName: "layer" */ '@/views/LayersDetail'),
      meta: {
        buttons: {
          left: {
            save: {
              name: 'save',
              handler: 'save',
              class: 'glyphicons glyphicons-floppy-disk',
              requiresSelection: false,
              visible: false
            },
            export: {
              name: 'export',
              handler: 'toggleExportPopup',
              class: 'glyphicons glyphicons-cloud-download',
              visible: true
            }
          },
          right: {
            deleteLayer: {
              name: 'deleteLayer',
              handler: 'deleteLayer',
              class: 'glyphicons glyphicons-bin',
              requiresSelection: false,
              visible: true
            }
          }
        }
      }
    },
    {
      path: '/project/:projectId/measurementPoint/:measurementPointId/soilSample/:jarID',
      name: 'soilSample',
      component: () => import(/* webpackChunkName: "soilSample" */ '@/views/LayersDetail'),
      meta: {
        buttons: {
          left: {
            save: {
              name: 'save',
              handler: 'save',
              class: 'glyphicons glyphicons-floppy-disk',
              requiresSelection: false,
              visible: false
            },
            export: {
              name: 'export',
              handler: 'toggleExportPopup',
              class: 'glyphicons glyphicons-cloud-download',
              visible: true
            }
          },
          right: {
            deleteLayer: {
              name: 'deleteLayer',
              handler: 'deleteLayer',
              class: 'glyphicons glyphicons-bin',
              requiresSelection: false,
              visible: true
            }
          }
        }
      }
    },
    {
      path: '/project/:projectId/measurementPoint/:measurementPointId/gaugingTubes/:FtID',
      name: 'gaugingTubes',
      component: () => import(/* webpackChunkName: "gaugingTubes" */ '@/views/GaugingTubesDetail'),
      meta: {
        buttons: {
          left: {
            save: {
              name: 'save',
              handler: 'save',
              class: 'glyphicons glyphicons-floppy-disk',
              requiresSelection: false,
              visible: false
            },
            export: {
              name: 'export',
              handler: 'toggleExportPopup',
              class: 'glyphicons glyphicons-cloud-download',
              visible: true
            }
          },
          right: {
            deleteLayer: {
              name: 'delete',
              handler: 'delete',
              class: 'glyphicons glyphicons-bin',
              requiresSelection: false,
              visible: true
            }
          }
        }
      }
    },
    {
      path: '/project/:projectId/measurementPoint/:measurementPointId/finishings/:FiID',
      name: 'finishings',
      component: () => import(/* webpackChunkName: "finishings" */ '@/views/FinishingsDetail'),
      meta: {
        buttons: {
          left: {
            save: {
              name: 'save',
              handler: 'save',
              class: 'glyphicons glyphicons-floppy-disk',
              requiresSelection: false,
              visible: false
            },
            export: {
              name: 'export',
              handler: 'toggleExportPopup',
              class: 'glyphicons glyphicons-cloud-download',
              visible: true
            }
          },
          right: {
            deleteLayer: {
              name: 'delete',
              handler: 'delete',
              class: 'glyphicons glyphicons-bin',
              requiresSelection: false,
              visible: true
            }
          }
        }
      }
    },
    {
      path: '/project/:projectId/measurementPoint/:measurementPointId/gaugingTubes/:FtGuid/watersample/:WsID',
      name: 'watersample',
      component: () => import(/* webpackChunkName: "watersample" */ '@/views/WaterSampleDetail'),
      meta: {
        buttons: {
          left: {
            save: {
              name: 'save',
              handler: 'save',
              class: 'glyphicons glyphicons-floppy-disk',
              requiresSelection: false,
              visible: false
            },
            export: {
              name: 'export',
              handler: 'toggleExportPopup',
              class: 'glyphicons glyphicons-cloud-download',
              visible: true
            }
          },
          right: {
            delete: {
              name: 'delete',
              handler: 'delete',
              class: 'glyphicons glyphicons-bin',
              requiresSelection: false,
              visible: true
            }
          }
        }
      }
    },
    {
      path: '/project/:projectId/measurementPoint/:measurementPointId/watersample/:WsID',
      name: 'watersampleSurface',
      component: () => import(/* webpackChunkName: "watersampleSurface" */ '@/views/WaterSampleDetail'),
      meta: {
        buttons: {
          left: {
            save: {
              name: 'save',
              handler: 'save',
              class: 'glyphicons glyphicons-floppy-disk',
              requiresSelection: false,
              visible: false
            },
            export: {
              name: 'export',
              handler: 'toggleExportPopup',
              class: 'glyphicons glyphicons-cloud-download',
              visible: true
            }
          },
          right: {
            delete: {
              name: 'delete',
              handler: 'delete',
              class: 'glyphicons glyphicons-bin',
              requiresSelection: false,
              visible: true
            }
          }
        }
      }
    },
    {
      path: '/project/:projectId/measurementPoint/:measurementPointId/gaugingTubes/:FtGuid/watersample/:WsID/bottle/:BoID',
      name: 'bottle',
      component: () => import(/* webpackChunkName: "bottle" */ '@/views/WaterSampleDetail'),
      meta: {
        buttons: {
          left: {
            save: {
              name: 'save',
              handler: 'save',
              class: 'glyphicons glyphicons-floppy-disk',
              requiresSelection: false,
              visible: false
            },
            export: {
              name: 'export',
              handler: 'toggleExportPopup',
              class: 'glyphicons glyphicons-cloud-download',
              visible: true
            }
          },
          right: {
            delete: {
              name: 'delete',
              handler: 'delete',
              class: 'glyphicons glyphicons-bin',
              requiresSelection: false,
              visible: true
            }
          }
        }
      }
    },
    {
      path: '/project/:projectId/measurementPoint/:measurementPointId/watersample/:WsID/bottle/:BoID',
      name: 'bottleSurface',
      component: () => import(/* webpackChunkName: "bottle" */ '@/views/WaterSampleDetail'),
      meta: {
        buttons: {
          left: {
            save: {
              name: 'save',
              handler: 'save',
              class: 'glyphicons glyphicons-floppy-disk',
              requiresSelection: false,
              visible: false
            },
            export: {
              name: 'export',
              handler: 'toggleExportPopup',
              class: 'glyphicons glyphicons-cloud-download',
              visible: true
            }
          },
          right: {
            delete: {
              name: 'delete',
              handler: 'delete',
              class: 'glyphicons glyphicons-bin',
              requiresSelection: false,
              visible: true
            }
          }
        }
      }
    },
    {
      path: '/project/:projectId/overview/:overviewId?/:mpGuid?',
      name: 'overview',
      component: () => import(/* webpackChunckName: "overview" */ '@/views/Overview'),
      meta: {
        buttons: {
          left: {
            export: {
              name: 'export',
              handler: 'toggleExportPopup',
              class: 'glyphicons glyphicons-cloud-download',
              visible: true
            }
          },
          right: {}
        }
      }
    },
    {
      path: '/project/:projectId/dashboard/:dashboardId?',
      name: 'dashboard',
      component: () => import(/* webpackChunckName: "overview" */ '@/views/DashboardView'),
      meta: {
        buttons: {
          left: {},
          right: {}
        }
      }
    },
    {
      path: '/browser_warning',
      name: 'browserWarning',
      component: () => import(/* webpackChunkName: "browserWarning" */ '@/views/BrowserWarning'),
      auth_required: false // if not defined it will checkz
    },
    {
      path: '/project/:projectId/measurementPoint/:measurementPointId/gaugingTubes/:FtGuid/airsample/:WsID',
      name: 'airSample',
      component: () => import(/* webpackChunkName: "airsample" */ '@/views/AirSampleDetail'),
      meta: {
        buttons: {
          left: {
            save: {
              name: 'save',
              handler: 'save',
              class: 'glyphicons glyphicons-floppy-disk',
              requiresSelection: false,
              visible: false
            },
            export: {
              name: 'export',
              handler: 'toggleExportPopup',
              class: 'glyphicons glyphicons-cloud-download',
              visible: true
            }
          },
          right: {
            delete: {
              name: 'delete',
              handler: 'delete',
              class: 'glyphicons glyphicons-bin',
              requiresSelection: false,
              visible: true
            }
          }
        }
      }
    },
    {
      path: '/project/:projectId/measurementPoint/:measurementPointId/airsample/:WsID',
      name: 'airSampleSurface',
      component: () => import(/* webpackChunkName: "airsample" */ '@/views/AirSampleDetail'),
      meta: {
        buttons: {
          left: {
            save: {
              name: 'save',
              handler: 'save',
              class: 'glyphicons glyphicons-floppy-disk',
              requiresSelection: false,
              visible: false
            },
            export: {
              name: 'export',
              handler: 'toggleExportPopup',
              class: 'glyphicons glyphicons-cloud-download',
              visible: true
            }
          },
          right: {
            delete: {
              name: 'delete',
              handler: 'delete',
              class: 'glyphicons glyphicons-bin',
              requiresSelection: false,
              visible: true
            }
          }
        }
      }
    },
    {
      path: '/project/:projectId/measurementPoint/:measurementPointId/gaugingTubes/:FtGuid/airSample/:WsID/packaging/:BoID',
      name: 'bottleAir',
      component: () => import(/* webpackChunkName: "bottle" */ '@/views/AirSampleDetail'),
      meta: {
        buttons: {
          left: {
            save: {
              name: 'save',
              handler: 'save',
              class: 'glyphicons glyphicons-floppy-disk',
              requiresSelection: false,
              visible: false
            },
            export: {
              name: 'export',
              handler: 'toggleExportPopup',
              class: 'glyphicons glyphicons-cloud-download',
              visible: true
            }
          },
          right: {
            delete: {
              name: 'delete',
              handler: 'delete',
              class: 'glyphicons glyphicons-bin',
              requiresSelection: false,
              visible: true
            }
          }
        }
      }
    },
    {
      path: '/project/:projectId/measurementPoint/:measurementPointId/airSample/:WsID/packaging/:BoID',
      name: 'bottleSurfaceAir',
      component: () => import(/* webpackChunkName: "bottle" */ '@/views/AirSampleDetail'),
      meta: {
        buttons: {
          left: {
            save: {
              name: 'save',
              handler: 'save',
              class: 'glyphicons glyphicons-floppy-disk',
              requiresSelection: false,
              visible: false
            },
            export: {
              name: 'export',
              handler: 'toggleExportPopup',
              class: 'glyphicons glyphicons-cloud-download',
              visible: true
            }
          },
          right: {
            delete: {
              name: 'delete',
              handler: 'delete',
              class: 'glyphicons glyphicons-bin',
              requiresSelection: false,
              visible: true
            }
          }
        }
      }
    },
    {
      path: '/suggest-analysis-samples/:ProjectID/:AvailableSampleDays',
      name: 'suggestAnalysisSamples',
      component: () => import(/* webpackChunkName: "SuggestAnalysisSamples" */ '@/views/SuggestAnalysisSamples')
    },
    {
      path: '/CodeConventions',
      name: 'codeconventions',
      component: () => import(/* webpackChunkName: "conventions" */ '@/views/TerraIndexCodeConventions'),
      meta: {
        buttons: {
          left: {
            codeReview: {
              name: 'CodeReview',
              handler: 'startCodeReview',
              class: 'glyphicons glyphicon-heart',
              requiresSelection: false,
              visible: true
            }
          },
          right: {}
        }
      }
    },
    {
      path: '/citrixLogin',
      name: 'citrixLogin',
      beforeEnter(to, from, next) {
        window.location = 'https://terraindex.itworks.nl/Citrix/Small/auth/login.aspx'
      }
    },
    {
      path: '/ExternalImport',
      name: 'ExternalImport',
      beforeEnter(to, from, next) {
        window.location = config.apps.import
      }
    },
    {
      path: '/ExternalExport/:PrID',
      name: 'ExternalExport',
      beforeEnter(to, from, next) {
        window.location.href = config.apps.export + to.params.PrID
      }
    },
    {
      path: '/ExternalVerify/:PrID',
      name: 'ExternalVerify',
      beforeEnter(to, from, next) {
        window.location.href = config.apps.verify + to.params.PrID
      }
    },
    {
      path: '/ExternalReport/:PrID',
      name: 'ExternalReport',
      beforeEnter(to, from, next) {
        window.location.href = config.apps.report + to.params.PrID
      }
    },
    {
      path: '/ExternalXYZ/:PrID',
      name: 'ExternalXYZ',
      beforeEnter(to, from, next) {
        window.location.href = config.apps.xyzimport + 'project/' + to.params.PrID
      }
    },
    {
      // fallback to 404
      path: '*',
      name: '404',
      component: () => import(/* webpackChunkName: "404" */ '@/views/404'),
      meta: {
        auth_required: false
      }
    }
  ]
})

let processAutoSaving = (transition) => {
  return new Promise((resolve, reject) => {
    if (!store.state.workingObjectChanged) {
      resolve(true)
      return
    }

    if (_.isEmpty(store.state.workingObject)) {
      resolve(true)
      return
    }

    let pageName = transition.from.name
    // try to find component of current page
    let c = searchComponentUtils.findComponentByName(pageName)

    if (!c) {
      resolve("Can't find component.")
      return
    }

    if (typeof c.save === 'function' && store.state.workingObjectChanged) {
      let result = c.save.bind(c)() // bind save's this to the current component so this works as expected
      store.state.workingObjectChanged = false
      if (typeof result.then === 'function') {
        result
          .then((res) => {
            resolve(res)
          })
          .catch((err) => {
            console.error(err)
          })
      } else {
        resolve(result)
      }
    } else {
      resolve(true)
    }
  })
}

let waitForValidator = function (transition) {
  if (store.state.activeEditorValidationPending) {
    // try again in 100 ms
    setTimeout(waitForValidator.bind(null, transition), 100)
  } else {
    // no changes pending, go processAutoSaving and transition
    // check if route contains an editor, if found callUnfocus to see if any are invalid (false means abort)
    let Editors = searchComponentUtils.findComponentByName('Editors')

    let AutoSave = true
    if (Editors) {
      if (Editors.unfocusEditors(null, transition.to.path) === false) {
        console.warn('unfocusEditors said not to autosave!') // not sure when this is done, please figure out before blindly uncommenting, probably should stop it moving to the next page
        AutoSave = false // dont save if editor sais not to
      }
    }

    if (AutoSave) {
      processAutoSaving(transition)
        .then((result) => {
          if (result === true) {
            transition.next()
            return null // according to bluebird promise, we need to return null!
          } else {
            console.error('Error saving changes: ', result)
            transition.next() // according to bluebird promise, we need to return null!
            return null
          }
        })
        .catch((err) => {
          console.error(err)
        })
    }
  }
}

router.beforeEach((to, from, next) => {
  if (from.name === 'project') {
    // Cancel all outgoing requests; user doesn't need them when navigating away from projects.
    window.stop()
  }
  let query = to.query
  // handle authentication
  if (authenticationProvider.inProcess(query)) {
    authenticationProvider.removeUserInfo()
    return authenticationProvider
      .process(query)
      .then((result) => {
        if (translation.locale !== result.language) {
          translation.locale = result.language
        }

        router.app.$children[0].handleAuthenticated()

        // browser detect
        var supportsES6 = (function () {
          try {
            // eslint-disable-next-line no-new-func,no-new
            new Function('(a = 0) => a')
            return true
          } catch (err) {
            return false
          }
        })()
        if (!supportsES6) {
          router.push({
            name: 'browserWarning',
            params: { lang: result.language }
          })
          return
        }

        // redirect
        if (result.url === 'projects') {
          router.push({
            name: 'projects',
            params: { lang: result.language }
          })
        } else {
          window.location = result.url
        }
      })
      .catch((e) => {
        console.error(e)
      })
  }
  if (!authenticationProvider.isAuthenticated() && authenticationProvider.isAuthenticationRequired(to)) {
    authenticationProvider.removeUserInfo()
    authenticationProvider.redirectToPreLoginPage(query)
  } else {
    router.app.authenticated = true
    if (to && to.path === '/forbidden') {
      router.app.authenticating = true
      setTimeout(() => {
        router.app.authenticating = false
        alert('this route is forbidden by a global before hook')
        next(false) // abort
      }, 3000)
    } else {
      // auto saving when navigate away from current page.
      waitForValidator({ from: from, to: to, next: next })
    }
  }
})

export default router
