import Vue from 'vue'
import moment from 'moment'
import translation from '@/configurations/app/config-locales'
import store from '@/vuex/store'
import projectionUtils from '@/utils/projectionUtil'
import { ProjectService } from '@/services/DataWS/projectService'
import { WaterSampleService } from '@/services/DataWS/waterSampleService'

const projectService = new ProjectService()
const waterSampleService = new WaterSampleService()
let TerraIndexEditorValidator = {}
let TerraIndexEditorValidators = {}
let currentlyRunningValidator = new Promise(function (resolve) { resolve() }) // this makes it so that a newer validation call can not finish before the older is done

let getValidationFunctions = function (field, currentValue, context) {
  let validationFunctions = []
  // see if it needs to be skipped
  if (field.skipParentValid) {
    return validationFunctions // return empty
  }
  // BooleanEditor
  if (field.type === '4') validationFunctions.push(TerraIndexEditorValidators.booleanValueValidator(field, currentValue, context))
  // TrajectoryEditor
  if (field.type === '19') validationFunctions.push(TerraIndexEditorValidators.trajectoryValidator(field, currentValue, context))
  // Barcode Editor
  if (field.type === '14') validationFunctions.push(TerraIndexEditorValidators.barCodeValidator(field, currentValue, context))
  // field required
  if (field.requiredBySystem === true) validationFunctions.push(TerraIndexEditorValidators.valueRequiredValidator(field, currentValue, context))
  // length check
  if ((field.maxLength > 0) && (field.type !== '24')) { // colour editor has a maxlength, but it is not a string so we have no way to check this atm
    validationFunctions.push(TerraIndexEditorValidators.valueLengthValidator(field, currentValue, context))
  }
  // pattern check
  if (field.validationPattern) validationFunctions.push(TerraIndexEditorValidators.valuePatternValidator(field, currentValue, context))
  // special compound validator
  if (field.key === 'LaSpecialCompounds') validationFunctions.push(TerraIndexEditorValidators.specialCompoundUniqueValidator(field, currentValue, context))
  if (field.key === 'LaSpecialCompounds') validationFunctions.push(TerraIndexEditorValidators.specialCompoundSystemRequiredPartsValidator(field, currentValue, context))
  if (field.isDate) validationFunctions.push(TerraIndexEditorValidators.dateValidator(field, currentValue, context))
  if ((field.type === '6') || (field.type === '5')) validationFunctions.push(TerraIndexEditorValidators.numberValidator(field, currentValue, context))
  // sequence check
  if (field.rawOptions && field.rawOptions.rule === 'next') validationFunctions.push(TerraIndexEditorValidators.sequenceValidator(field, currentValue, context))
  // For tableLayers
  if (field.key === 'LaTo' && field.type === '6') validationFunctions.push(TerraIndexEditorValidators.tableSequenceValidator(field, currentValue, context))
  // trajectory check
  // if(field.rawOptions && field.rawOptions.rule === 'lower') validationFunctions.push(trajectoryValidator(field, currentValue, context))
  // jar name unique validator
  if (field.rawOptions && field.rawOptions.rule === 'jrUnique') validationFunctions.push(TerraIndexEditorValidators.jarNameUniqueValidator(field, currentValue, context))
  // PrCode unique
  if (field.key === 'PrCode') validationFunctions.push(TerraIndexEditorValidators.projectCodeUniqueValidator(field, currentValue, context))
  // Measurement point name unique
  if (field.key === 'MpName') validationFunctions.push(TerraIndexEditorValidators.measurementPointUniqueValidator(field, currentValue, context))
  // soil sample name unique
  if (field.key === 'JrName') validationFunctions.push(TerraIndexEditorValidators.soilSampleUniqueValidator(field, currentValue, context))
  if (field.key === 'JrFrom' || field.key === 'JrTo') validationFunctions.push(TerraIndexEditorValidators.soilSampleTrajactoryValidator(field, currentValue, context))
  // gauging tube name unique
  if (field.key === 'FtName') validationFunctions.push(TerraIndexEditorValidators.gaugingTubeUniqueValidator(field, currentValue, context))
  // water sample name unique
  if (field.key === 'WsName') validationFunctions.push(TerraIndexEditorValidators.waterSampleUniqueValidator(field, currentValue, context))
  // bottle name unique
  if (field.key === 'BoName') validationFunctions.push(TerraIndexEditorValidators.bottleUniqueValidator(field, currentValue, context))
  if (field.key === 'SlName') validationFunctions.push(TerraIndexEditorValidators.slNameUniqueValidator(field, currentValue, context))
  if (field.inputControlOptions && (typeof (field.inputControlOptions.AlphabeticOnly)) !== 'undefined' && field.inputControlOptions.AlphabeticOnly === 'true') validationFunctions.push(TerraIndexEditorValidators.letterValidator(field, currentValue, context))
  return validationFunctions
}

TerraIndexEditorValidator.$validateEditor = function (field, currentValue, context) {
  // see if field is defined and valid
  if (typeof field !== 'object') {
    let error = 'TerraIndexEditorValidator.$validateEditor has been passed an invalid field.'
    console.error(error)
    return Promise.reject(new ValidationError((error)))
  }
  let validationFunctions = getValidationFunctions(field, currentValue, context)

  // clear any unhandled errors
  currentlyRunningValidator = currentlyRunningValidator.then(null, function () { })

  currentlyRunningValidator = currentlyRunningValidator.then(function () {
    return Promise.all(validationFunctions)
      .then(function (res) {
        res.currentValue = currentValue
        return res
      }) // return currentvalue if all went well
  })

  return currentlyRunningValidator
}

TerraIndexEditorValidators = {
  booleanValueValidator: function (field, value) {
    return new Promise(function (resolve, reject) {
      let valid = value === 'true' || value === 'false'
      if (valid) {
        resolve({ valid: true })
      } else {
        reject(new ValidationError(translation.t('message.validate_error_action_confirm_title')))
      }
    })
  },
  valueRequiredValidator: function (field, value) {
    return new Promise(function (resolve, reject) {
      let valid = ((value !== null) && (value !== ''))
      if (valid) {
        resolve({ valid: true })
      } else {
        reject(new ValidationError(translation.t('message.valueRequired')))
      }
    })
  },
  valueLengthValidator: function (field, value) {
    return new Promise(function (resolve, reject) {
      if (typeof value !== 'string') {
        console.warn('Value [' + value + '] is not a valid string, could not check length')
        value = ''
      }
      let valid = value.length <= field.maxLength
      if (valid) {
        resolve({ valid: true })
      } else {
        reject(new ValidationError(translation.t('message.valueTooLong')))
      }
    })
  },
  valuePatternValidator: function (field, value) {
    return new Promise(function (resolve, reject) {
      let valid = true

      if (typeof value !== 'string') {
        // console.warn('Value [' + value + '] is not a valid string, could not check validationPattern');
      } else if (value !== '') { // empty strings are valid
        valid = (new RegExp(field.validationPattern, 'i').test(value) === true)
      }

      if (valid) {
        resolve({ valid: true })
      } else {
        reject(new ValidationError(translation.t('message.valueNotMatchPattern')))
      }
    })
  },
  numberValidator: function (field, value) {
    return new Promise(function (resolve, reject) {
      let valid = true
      if ((typeof value !== 'string') && (typeof value !== 'number')) {
        console.warn('Value [' + value + '] is not a valid string, could not check numberValidator')
        valid = false
      } else {
        let num = Number(value)
        valid = !isNaN(num) && num <= 2147483647
      }

      if (valid) {
        resolve({ valid: true })
      } else {
        reject(new ValidationError(translation.t('message.valueNotNumber')))
      }
    })
  },
  dateValidator: function (field, value, context) {
    return new Promise(function (resolve, reject) {
      let valid = true
      // TODO: Parser is a hot fix should be solved in boxes.vue line 379 to get a date string there instead of a object
      if (typeof value !== 'string') {
        try {
          value = value.toString()
        } catch (e) {
          console.log('Transfomer')
          console.error(e)
        }
      }

      // if value is not an empty string, then validate else if field is required invalidate
      if (value !== '') {
        try {
          let date = moment(value, context.format)

          valid = date.isValid()
        } catch (e) {
          console.log('Validator')
          console.error(e)
        }
      } else if (field.requiredBySystem) {
        valid = false
      }

      if (valid) {
        resolve({ valid: true })
      } else {
        reject(new ValidationError(translation.t('message.invalidDateFormat')))
      }
    })
  },

  tableSequenceValidator: function (field, value, context) { // context : { newLayer: Boolean }{
    return new Promise(function (resolve, reject) {
      let newLayer = false

      if (typeof value !== 'number') {
        console.warn('Value [' + value + '] is not a valid number, could not check tableSequenceValidator')
      }

      if ((typeof context !== 'undefined') && (typeof context.newLayer !== 'undefined') && (context.newLayer)) {
        newLayer = true
      }

      // get next and previous values from the state based on the type of sequence
      var previous

      let next = null
      let layers = store.state.tableLayers.filter((layer) => {
        return layer.MpGUID === context.MpGUID
      })
      // get my own identifier
      let index = null
      let l = layers.length

      if (newLayer) { // new layer just checks the last layer
        index = l - 1
      } else {
        for (let i = 0; i < l; i++) {
          if (layers[i].LaGuid === context.LaGuid) {
            index = i
          }
        }
      }
      if (index !== null) { // if position of the layer is found look in front and behond to get previous and next
        if (index > 0) { // make sure there is a previous screen
          previous = layers[index - 1].LaTo
        }
        if (index < (l - 1)) { // make sure there is a next layer
          next = layers[index + 1].LaTo
        }
      }

      let currentLayer = layers[index]

      let first = (currentLayer.LaFrom !== '') ? Number(currentLayer.LaFrom) : NaN // empty string is not a number
      let second = (value !== '') ? Number(value) : NaN // empty string is not a number

      if (isNaN(first)) {
        reject(new ValidationError(translation.t('message.valueNotNumber')))
        return
      }

      if (isNaN(second)) {
        reject(new ValidationError(translation.t('message.valueNotNumber')))
        return
      }

      if (first >= second) {
        reject(new ValidationError(translation.t('message.SecondBiggerThanFirst')))
        return
      }

      if (next !== null) {
        if (second >= next) {
          reject(new ValidationError(translation.t('message.SequenceToLowerThanNextTo')))
          return
        }
      }

      if (previous) {
        if (first < previous) {
          reject(new ValidationError(translation.t('message.SequenceFromHigherThanPreviousTo')))
          return
        }
      }

      resolve({ valid: true })
    })
  },
  sequenceValidator: function (field, value, context) { // context : { newLayer: Boolean }
    return new Promise(function (resolve, reject) {
      let newLayer = false

      if (typeof value !== 'object') {
        console.warn('Value [' + value + '] is not a valid selected object, could not check sequenceValidator')
      }

      if ((typeof context !== 'undefined') && (typeof context.newLayer !== 'undefined') && (context.newLayer)) {
        newLayer = true
      }
      // get next and previous values from the state based on the type of sequence

      /* eslint-disable no-unused-vars */
      let previous = null
      /* eslint-enable no-unused-vars */

      let next = null
      if ((field.key === 'LaTo') && (store.state.workingObjectType === 'layer')) {
        let layers = store.state.layers.filter(v => v.type !== 'emptyBox')
        // get my own identifier
        let index = null
        let l = layers.length

        if (newLayer) { // new layer just checks the last layer
          index = l - 1
        } else {
          for (let i = 0; i < l; i++) {
            if (layers[i].LaGuid === store.state.workingObject.LaGuid) {
              index = i
            }
          }
        }
        if (index !== null) { // if position of the layer is found look in front and behond to get previous and next
          if (index > 0) { // make sure there is a previous screen
            previous = layers[index - 1].to
          }
          if (index < (l - 1)) { // make sure there is a next layer
            next = layers[index + 1].to
          }
        }
      }

      let first = (value.first !== '') ? Number(value.first) : NaN // empty string is not a number
      let second = (value.second !== '') ? Number(value.second) : NaN // empty string is not a number

      if (isNaN(first)) {
        reject(new ValidationError(translation.t('message.valueNotNumber')))
        return
      }

      if (isNaN(second)) {
        reject(new ValidationError(translation.t('message.valueNotNumber')))
        return
      }

      if (first >= second) {
        reject(new ValidationError(translation.t('message.SecondBiggerThanFirst')))
        return
      }

      if (next !== null) {
        if (second >= next) {
          reject(new ValidationError(translation.t('message.SequenceToLowerThanNextTo')))
          return
        }
      }

      if (newLayer) {
        // Check if the 'to' value exceeds the next layer
        let laIndex = store.state.layers.findIndex((elem) => elem.LaFrom === first.toString())
        let nextLayer = null
        if (laIndex >= 0 && store.state.layers[laIndex + 1]) {
          nextLayer = store.state.layers[laIndex + 1]
        }

        if (laIndex >= 0 && !nextLayer) {
          if (second >= store.state.layers[laIndex].LaTo) {
            reject(new ValidationError(translation.t('layers.layerRangeExceeded')))
            return
          }
        }

        // edge case when adding intermediate within the from - to range of the last layer in the list
        if (nextLayer) {
          if (second >= nextLayer.LaFrom) {
            reject(new ValidationError(translation.t('layers.layerRangeExceeded')))
            return
          }
        }
      }

      resolve({ valid: true })
    })
  },
  trajectoryValidator: function (field, value) {
    return new Promise(function (resolve, reject) {
      if (typeof value !== 'object') {
        console.warn('Value [' + value + '] is not a valid selected object, could not check trajectoryValidator')
      }
      let first = value['first']
      let second = value['second']
      let first_ = first
      let second_ = second

      if (!field.requiredBySystem && first === '' && second === '') {
        resolve({ valid: true })
      }

      if (isNaN(first_)) {
        reject(new ValidationError(translation.t('message.FirstMustBeNumber')))
        return
      }

      if (isNaN(second_)) {
        reject(new ValidationError(translation.t('message.SecondMustBeNumber')))
        return
      }

      if (field.rawOptions && field.rawOptions.rule === 'lower') {
        if (parseFloat(first_) >= parseFloat(second_)) {
          reject(new ValidationError(translation.t('message.SecondBiggerThanFirst')))
          return
        }
      }

      resolve({ valid: true })
    })
  },
  specialCompoundSystemRequiredPartsValidator: function (field, value, context) {
    let promises = []
    for (let i = 1; i < 5; i++) {
      let context2 = {
        listGroups: context.listGroups,
        requiredGroups: context.listGroups.filter(group => (group.systemRequired)),
        index: i
      }
      promises.push(TerraIndexEditorValidators.specialCompoundRequiredSet(field, value, context2))
    }

    return Promise.all(promises)
  },
  specialCompoundUniqueValidator: function (field, value, context) {
    return new Promise(function (resolve, reject) {
      if (typeof value !== 'object') {
        console.warn('Value [' + value + '] is not a valid currentValues object, could not check specialCompoundUniqueValidator')
      }

      let uniqueGroups = context.listGroups.filter(group => group.isUnique)

      for (let group of uniqueGroups) {
        // get keys in object of which their values must be unique
        let keys = Object.keys(value).filter(key => key.indexOf(group.name) !== -1)
        // get values corresponding to keys
        let values = keys.map(key => value[key]).filter(value => value !== '')
        // get set of unique values
        let set = [...new Set(values)]

        // if length has changed, then we know that there is a duplicate
        if (values.length !== set.length) {
          reject(new ValidationError(translation.t('message.only_allowed_once_title_in_list').replace('<title>', group.title)))
          return
        }
      }
      resolve({ valid: true })
    })
  },
  specialCompoundRequiredSet: function (field, value, context) {
    return new Promise(function (resolve, reject) {
      if ((typeof context !== 'object') || (typeof context.listGroups !== 'object') || (context.listGroups.constructor !== Array)) {
        console.warn('context.listGroups is not a valid array, could not check specialCompoundRequiredSet')
      }
      if ((typeof context !== 'object') || (typeof context.requiredGroups !== 'object') || (context.requiredGroups.constructor !== Array)) {
        console.warn('context.requiredGroups is not a valid array, could not check specialCompoundRequiredSet')
      }
      if ((typeof context !== 'object') || (typeof context.index !== 'number')) {
        console.warn('context.index is not a valid number, could not check specialCompoundRequiredSet')
      }

      // Only validate if some value is set, if all three values are empty, the entry is empty.
      if (context.listGroups.some(group => value[group.name + '_' + context.index])) {
        context.requiredGroups.forEach(group => {
          if (!value[group.name + '_' + context.index]) {
            reject(new ValidationError(translation.t('message.MissingValue') + ' ' + group.title))
          }
        })
      }
      resolve({ valid: true })
    })
  },
  jarNameUniqueValidator: function (field, value, context) {
    return new Promise(function (resolve, reject) {
      if ((typeof context !== 'object') || (typeof context.soilSamples !== 'object')) {
        console.warn('context.soilSamples is not a valid array of soilSamples, could not check jarNameUniqueValidator')
      }

      // try to find soil sample with same name
      let item = context.soilSamples.filter(v => v.type && v.type.toUpperCase() !== 'EMPTYBOX')
        .find(v => v.JrName.toUpperCase() === value.toUpperCase())

      if (item) {
        reject(new ValidationError(translation.t('message.valueInvalid')))
      } else {
        resolve({ valid: true })
      }
    })
  },
  projectCodeUniqueValidator: function (field, value) {
    return new Promise(function (resolve, reject) {
      projectService.validateProject(value).then(result => {
        if (!result.data) {
          reject(new ValidationError(translation.t('project.InvalidProjectCode')))
        } else {
          resolve({ valid: true })
        }
      }).catch((err) => {
        // Do not show error message to user
        reject(new ValidationError(err.message))
      })
    })
  },
  measurementPointUniqueValidator: function (field, value, context) {
    return new Promise(function (resolve, reject) {
      if ((typeof context !== 'object') || (typeof context.MpGuid !== 'string')) {
        console.warn('context.MpGuid is not a valid string, could not check measurementPointUniqueValidator')
      }

      // try to find item with the same name that does not have the same GUID
      let item = store.state.measurementPoints
        .find(v => v.MpName && v.MpName.toUpperCase() === value.toUpperCase() && v.MpGuid !== context.MpGuid)

      if (item) {
        reject(new ValidationError(translation.t('message.valueInvalid')))
      } else {
        resolve({ valid: true })
      }
    })
  },
  soilSampleUniqueValidator: function (field, value, context) {
    return new Promise(function (resolve, reject) {
      if ((typeof context !== 'object') || (typeof context.JrGuid !== 'string')) {
        console.warn('context.JrGuid is not a valid string, could not check soilSampleUniqueValidator')
      }
      let item = null
      // try to find item with the same name that does not have the same GUID
      if (context && context.fromTableEntry && context.MpGuid) {
        item = store.state.tableJars.find(v => v.JrName.toUpperCase() === value.toUpperCase() && v.JrGuid !== context.JrGuid && v.MpGuid === context.MpGuid)
      } else {
        item = store.state.soilSamples.filter(v => (v.type && v.type.toUpperCase() !== 'EMPTYBOX') || v.type === undefined)
          .find(v => v.JrName.toUpperCase() === value.toUpperCase() && v.JrGuid !== context.JrGuid)
      }
      if (item) {
        reject(new ValidationError(translation.t('message.valueInvalid')))
      } else {
        resolve({ valid: true })
      }
    })
  },

  soilSampleTrajactoryValidator: function (field, value, context) {
    return new Promise(function (resolve, reject) {
      if ((typeof context !== 'object') || (typeof context.JrGuid !== 'string')) {
        console.warn('context.JrGuid is not a valid string, could not check soilSampleUniqueValidator')
      }
      let item = true
      if (context && context.JrFrom && context.JrTo) {
        item = (parseFloat(context.JrFrom) < parseFloat(context.JrTo))
      }
      // try to find item with the same name that does not have the same GUID
      if (!item) {
        reject(new ValidationError(translation.t('message.trajectorySequentie')))
      } else {
        resolve({ valid: true })
      }
    })
  },

  barCodeValidator: function (field, value, context) {
    return new Promise(function (resolve, reject) {
      let barCodes = value.split(';')
      if ((field.editorOptions.maxLength) && (field.editorOptions.maxLength > 0) && (barCodes.length >= field.editorOptions.maxLength)) {
        reject(new ValidationError(translation.t('message.valueTooLong')))
        return
      }

      if ((new Set(barCodes)).size !== barCodes.length) {
        reject(new ValidationError(translation.t('message.valueInvalid')))
        return
      }
      resolve({ valid: true })
    })
  },
  gaugingTubeUniqueValidator: function (field, value, context) {
    return new Promise(function (resolve, reject) {
      if ((typeof context !== 'object') || (typeof context.FtGuid !== 'string')) {
        console.warn('context.FtGuid is not a valid string, could not check gaugingTubeUniqueValidator')
      }

      // try to find item with the same name that does not have the same GUID
      let item = store.state.gaugingTubes.filter(v => v.type && v.type.toUpperCase() !== 'EMPTYBOX')
        .find(v => v.FtName.toUpperCase() === value.toUpperCase() && v.FtGuid !== context.FtGuid)

      if (item) {
        reject(new ValidationError(translation.t('message.valueInvalid')))
      } else {
        resolve({ valid: true })
      }
    })
  },
  waterSampleUniqueValidator: function (field, value, context) {
    return new Promise(function (resolve, reject) {
      if ((typeof context !== 'object') || (typeof context.WsGuid !== 'string')) {
        console.warn('context.WsGuid is not a valid string, could not check waterSampleUniqueValidator')
      }
      let watersample = store.state.waterSamples.find(ws => ws.WsGuid === context.WsGuid)
      if (watersample && watersample.WsName.trim() === value.trim()) { // Don't do server request if the name matches my own name
        resolve({ valid: true })
      } else {
        waterSampleService.validateWaterSampleName(context.PrID, value, context.WsGuid).then(result => {
          if (!result.data) {
            reject(new ValidationError(translation.t('message.valueInvalid')))
          } else {
            resolve({ valid: true })
          }
        }).catch((err) => {
          // Do not show error message to user
          reject(new ValidationError(err.message))
        })
      }
    })
  },
  bottleUniqueValidator: function (field, value, context) {
    return new Promise(function (resolve, reject) {
      if ((typeof context !== 'object') || (typeof context.BoGuid !== 'string')) {
        console.warn('context.BoGuid is not a valid string, could not check bottlesUniqueValidator')
      }

      let bottle = store.state.bottles.find(b => b.BoGuid === context.BoGuid)
      // try to find item with the same name that does not have the same GUID
      let item = store.state.bottles.find(v => v.BoName.toUpperCase() === value.toUpperCase() && v.BoGuid !== context.BoGuid && ((typeof bottle === 'undefined') || (v.WsID === bottle.WsID)))

      if (item) {
        reject(new ValidationError(translation.t('message.valueInvalid')))
      } else {
        resolve({ valid: true })
      }
    })
  },
  coordinatesInProjectedBounds: function (x, y, epsg) {
    return new Promise(function (resolve, reject) {
      if (x === '' || y === '' || x === undefined || y === undefined || x == null || y == null) {
        reject(new ValidationError(translation.t('message.invalidPoint')))
      }
      x = parseFloat(x)
      y = parseFloat(y)
      let projection = projectionUtils.coordinateSystems.find(system => system.epsg === epsg)
      let xMin = parseFloat(projection.projectedBounds.XMin)
      let xMax = parseFloat(projection.projectedBounds.XMax)
      let yMin = parseFloat(projection.projectedBounds.YMin)
      let yMax = parseFloat(projection.projectedBounds.YMax)
      if (x < xMin || x > xMax || y < yMin || y > yMax) {
        reject(new ValidationError(translation.t('message.outOfBounds')))
      } else {
        resolve({ valid: true })
      }
    })
  },
  coordinatesInWGS84Bounds: function (lat, long, epsg) {
    if (lat === '' || long === '' || lat === undefined || long === undefined || lat == null || long == null) {
      return { valid: true, message: translation.t('message.invalidPoint') }
    }
    lat = parseFloat(lat)
    long = parseFloat(long)
    let projection = projectionUtils.coordinateSystems.find(system => system.epsg === epsg)
    let latMin = parseFloat(projection.WGS84Bounds.latMin)
    let latMax = parseFloat(projection.WGS84Bounds.latMax)
    let longMin = parseFloat(projection.WGS84Bounds.longMin)
    let longMax = parseFloat(projection.WGS84Bounds.longMax)
    if (lat < latMin || lat > latMax || long < longMin || long > longMax) {
      return { valid: false, message: translation.t('message.cantDisplay') }
    } else {
      return { valid: true, message: '' }
    }
  },

  letterValidator: function (field, value) {
    return new Promise(function (resolve, reject) {
      let valid = true
      if ((typeof value !== 'string') && (typeof value !== 'number')) {
        console.warn('Value [' + value + '] is not a valid string, could not check letterValidator')
        valid = false
      } else {
        valid = /^[a-z]+$/i.test(value)
      }
      if (value === '') {
        valid = true
      }
      if (valid) {
        resolve({ valid: true })
      } else {
        reject(new ValidationError(translation.t('message.valueMayOnlyContainLetters')))
      }
    })
  },

  slNameUniqueValidator: function (field, value, context) {
    return new Promise(function (resolve, reject) {
      if ((typeof context !== 'object') || (typeof context.SlGuid !== 'string')) {
        console.warn('context.SlGuid is not a valid string, could not check slNameUniqueValidator')
      }
      let subLocations = store.getters.getSubLocations
      // try to find item with the same name that does not have the same GUID
      let item = subLocations.find((item) => {
        return (item.SlName.toString().toUpperCase() === value.toString().toUpperCase() &&
          item.SlGuid !== context.SlGuid)
      })

      if (item) {
        reject(new ValidationError(translation.t('message.valueInvalid')))
      } else {
        resolve({ valid: true })
      }
    })
  }
}

export function dxTableCellValidator (control) {
  return (params) => {
    let checkValid = true
    if ((params.value === null) || (params.value === undefined)) { // no need to check undefined stuff unless it is required
      checkValid = false
      if (control.fields[0].requiredBySystem === true) {
        checkValid = true
      }
    }

    if (checkValid) {
      TerraIndexEditorValidator.$validateEditor(control.fields[0], params.value, params.data)
        .then(() => {
          params.rule.isValid = true
          params.rule.message = null
          params.validator.validate()
        })
        .catch(reason => {
          // extract reason and set valid to false
          if (typeof reason.message === 'undefined') {
            console.error(reason)
          }
          params.rule.isValid = false
          params.rule.message = reason.message
          params.validator.validate()
        })
    }
    return true // return false to the cell untill we have validated it
  }
}

function ValidationError (message) {
  this.name = 'ValidationError'
  this.message = (message || '')
  this.valid = false
}
ValidationError.prototype = Error.prototype

TerraIndexEditorValidator.install = function () {
  Vue.prototype.$validateEditor = TerraIndexEditorValidator.$validateEditor
  Vue.prototype.$validatorFunctions = TerraIndexEditorValidators
}

export default TerraIndexEditorValidator
