export default {
  extendNonJQ () {
    for (let i = 1; i < arguments.length; i++) {
      for (let key in arguments[i]) {
        if (arguments[i].hasOwnProperty(key)) { arguments[0][key] = arguments[i][key] }
      }
    }
    return arguments[0]
  },

  // eslint-disable-next-line no-unused-vars
  calculateNENClassification (Gravel, Loam, Lutum, OrganicMatter, Silt, M50) {
  // old: ProcToOrde V, L, S, Z, G
  // Grind, Leem, Klei, Veen, Silt
    let res = {}
    let nenKeys = ['MedianCode', 'SoilTypeCode', 'Ad1TypeCode', 'Ad1GradationCode', 'Ad2TypeCode', 'Ad2GradationCode']
    if (!Gravel) Gravel = 0
    if (!Loam) Loam = 0
    if (!Lutum) Lutum = 0
    if (!OrganicMatter) OrganicMatter = 0
    if (!Silt) Silt = 0
    Gravel = parseFloat(Gravel)
    Loam = parseFloat(Loam)
    Lutum = parseFloat(Lutum)
    OrganicMatter = parseFloat(OrganicMatter)
    Silt = parseFloat(Silt)

    if (typeof M50 === 'undefined') {
      M50 = ''
    }
    if (M50 !== '') {
      M50 = parseFloat(M50)
    }

    if ((Gravel === 0) && (Loam === 0) && (Lutum === 0) && (OrganicMatter === 0) && (Silt === 0)) {
    // make sure res is extended properly
      for (let i = 0; i < nenKeys.length; i++) {
        if (typeof res[nenKeys[i]] === 'undefined') {
          res[nenKeys[i]] = ''
        }
      }
      return res
    }
    let tmpd = this.calculateLoamFraction(Lutum, Loam, Silt, OrganicMatter, Gravel) // G1Z1
    Lutum = tmpd.Lutum
    Loam = tmpd.Leem
    Silt = tmpd.Silt
    OrganicMatter = tmpd.OS
    Gravel = tmpd.Grind
    let Z = tmpd.Zand // berekend!
    let IsGrind = false
    let IsVeen = false
    let hulpLS = 0
    let hulpSZ = 0
    let Vc = 0
    let Lc = 0
    let Sc = 0
    let Zc = 0
    let Gc = 0
    let hulpLSc = 0
    let hulpSZc = 0

    // {bekijk eerst de driehoek voor: lutum+silt tegen grind tegen zand:}
    hulpLS = Lutum + Silt
    let tmp
    tmp = this.Conv3(hulpLS, Gravel, Z, hulpLSc, Gc, Zc)
    hulpLSc = tmp.Ac
    Gc = tmp.Bc
    Zc = tmp.Cc
    IsGrind = (Gc >= 30) // {Grindmengsel}
    // {bekijk dan de driehoek voor: silt+zand tegen org.stof tegen lutum:}
    hulpSZ = Silt + Z
    tmp = this.Conv3(hulpSZ, OrganicMatter, Lutum, hulpSZc, Vc, Lc)
    hulpSZc = tmp.Ac
    Vc = tmp.Bc
    Lc = tmp.Cc
    IsVeen = (Vc > 30)

    if (!IsGrind) { // { Veenmengsel }
      if ((!IsVeen) && ((Vc > 15) && (Vc <= 30))) {
        IsVeen = ((Vc > 15 * (2 - hulpSZc / 85)) && (hulpSZc < 85))
        if (!IsVeen) {
          IsVeen = (Vc > (30 - 15 * (1 - Lc / 70)))
        }
      }
    }

    if (IsGrind) { // H=grind
      if (hulpLS >= Zc) {
        res = this.gravelLoam(Gc, hulpLSc) // G_LS
      } else {
        res = this.gravelSand(Gc, Zc) // G_Z
      }
    } else if (IsVeen) { // { Hoofdnaam is Veen }
      if (Lutum > hulpSZ) {
        res = this.organicMatterLoam(Vc, Lc) // V_L
      } else {
      // V_SZ(Vc, hulpSZc, veen, zand, klei)
        res = this.organicMatterSand(Vc, hulpSZc)
      }
    } else {
      tmp = this.Conv3(Lutum, Silt, Z, Lc, Sc, Zc)
      Lc = tmp.Ac
      Sc = tmp.Bc
      Zc = tmp.Cc
      // L_S_Z(Lc, Sc, Zc, klei, zand, leem);
      res = this.sandLutumLoamTriangle(Lc, Sc, Zc) // L_S_Z
    }
    // { niet naamgevende toevoeging }
    if ((OrganicMatter > 0) && (!IsVeen)) { // ToevVeen( Vc, hulpSZ, L, veen );
      this.extendNonJQ(res, this.additionOrganicMatter(Vc, hulpSZ, Lutum))
    } else if ((Gravel > 0) && (!IsGrind)) { // ToevGrind( G, grind );
      this.extendNonJQ(res, this.additionGravel(Gravel))
    }
    // delphi gradatie
    // make sure res is extended properly
    for (let i = 0; i < nenKeys.length; i++) {
      if (typeof res[nenKeys[i]] === 'undefined') {
        res[nenKeys[i]] = ''
      }
    }

    // get MedianCode
    if (M50 !== '') {
      if (res.SoilTypeCode === 'G') {
        res.MedianCode = this.medianGravel(M50)
      } else if (res.SoilTypeCode === 'Z') {
        res.MedianCode = this.medianSand(M50)
      };
    }

    return res
  },

  calculateLoamFraction (Lutum, Leem, Silt, OS, Grind) { // L,Le,S,V,G // ConvLeemFractie
    if ((Lutum > 0) && (Silt > 0)) { // {beiden gemeten}
      Silt = Leem
    } else {
      if (Lutum > 0) { // {lutum gemeten}
        Silt = Math.round(2.78 * Lutum - 0.0348 * Lutum * Lutum - 3.32)
        if (Silt < 0) {
          Silt = 0
        }
        if (Lutum + Silt + OS + Grind > 100) {
          let factor = (100 - (OS + Grind)) / (Lutum + Silt)
          Lutum = Math.round(Lutum * factor)
          Silt = Math.round(Silt * factor)
        }
      } else if (Leem > 0) { // {alleen Leem gemeten}
        Lutum = Math.round(1.24 + 0.207 * Leem)
        Silt = Leem - Lutum
      }
    }
    return {
      Lutum: Lutum,
      Leem: Leem,
      Silt: Silt,
      OS: OS,
      Grind: Grind,
      Zand: (100 - (Lutum + Silt + OS + Grind))
    }
  },

  /*
function gravelTriangle (data) {
  if (data.lutum + data.silt >= data.sand) {
    return gravelLoam(data.gravel, data.lutum, data.silt)
  } else {
    return gravelSand(data.gravel, data.sand)
  }
}

function organicMatterTriangle (data) {
  if (data.lutum > data.silt + data.sand) {
    return organicMatterLoam(data.organicMatter, data.lutum)
  } else {
    return organicMatterSand(data.organicMatter, data.silt, data.sand)
  }
}
*/

  sandLutumLoamTriangle (L, S, Z) { // L_S_Z // Zand_Klei_Leem // Lutum, silt, sand
    let SoilTypeCode = '' // init vars to empty string
    let Ad1TypeCode = ''
    let Ad1GradationCode = ''
    let sp = 100 - S - Z
    let dummy = 8 + (50 - Z) * 17 / 50
    if (L > 50) {
      SoilTypeCode = 'K'
      Ad1TypeCode = 'S'
      Ad1GradationCode = 1
    } else {
      if (((L < 50) && (L > 35)) || ((S < 65) && (sp > 35) && (sp <= 50))) {
        SoilTypeCode = 'K'
        Ad1TypeCode = 'S'
        Ad1GradationCode = 2
      } else if (((L < 35) && (L > 25)) || ((S < 75) && (sp > 25) && (sp <= 35))) {
        SoilTypeCode = 'K'
        Ad1TypeCode = 'S'
        Ad1GradationCode = 3
      } else if (Z < 50) { //
        if (((L <= 25) && (L > dummy)) || ((S > 25) && (S < 75) && (sp <= 25) && (sp > dummy))) {
          SoilTypeCode = 'K'
          Ad1TypeCode = 'S'
          Ad1GradationCode = 4
        } else if ((S >= 65) && (Z < 15)) {
          SoilTypeCode = 'L'
          Ad1TypeCode = 'Z'
          Ad1GradationCode = 1
        } else {
          SoilTypeCode = 'L'
          Ad1TypeCode = 'Z'
          Ad1GradationCode = 3
        }
      } else if ((Z < 82.5) && (((L <= 25) && (L > 17.5)) || ((S <= 32.5) && (sp <= 25) && (sp > 17.5)))) {
        SoilTypeCode = 'K'
        Ad1TypeCode = 'Z'
        Ad1GradationCode = 1
      } else if ((Z < 88) && (((L <= 17.5) && (L > 12)) || ((S <= 38) && (sp <= 17.5) && (sp > 12)))) {
        SoilTypeCode = 'K'
        Ad1TypeCode = 'Z'
        Ad1GradationCode = 2
      } else if ((Z < 92) && (((L <= 12) && (L > 8)) || ((Z < 42) && (sp <= 12) && (sp > 8)))) {
        SoilTypeCode = 'K'
        Ad1TypeCode = 'Z'
        Ad1GradationCode = 3
      } else if ((Z < 67.5) && (sp <= 8)) {
        SoilTypeCode = 'Z'
        Ad1TypeCode = 'S'
        Ad1GradationCode = 4
      } else if ((Z < 82.5) && (sp <= 8)) {
        SoilTypeCode = 'Z'
        Ad1TypeCode = 'S'
        Ad1GradationCode = 3
      } else if ((Z < 90) && (sp <= 5)) {
        SoilTypeCode = 'Z'
        Ad1TypeCode = 'S'
        Ad1GradationCode = 2
      } else if (sp <= 5) {
        SoilTypeCode = 'Z'
        Ad1TypeCode = 'S'
        Ad1GradationCode = 1
      } else {
        SoilTypeCode = 'Z'
        Ad1TypeCode = 'K'
        Ad1GradationCode = 3
      }
    }

    return {
      SoilTypeCode: SoilTypeCode,
      Ad1TypeCode: Ad1TypeCode,
      Ad1GradationCode: Ad1GradationCode
    }
  },

  gravelLoam (G, LS) { // G_LS // Grind_Leem // gravel, loam, silt
    let SoilTypeCode = 'G' // init vars to empty string
    let Ad1TypeCode = ''
    let Ad1GradationCode = ''

    if (LS > 20) {
      Ad1TypeCode = 'S'
      Ad1GradationCode = 3
    } else if (G >= 90) {
      Ad1TypeCode = 'Z'
      Ad1GradationCode = 1
    } else if ((G >= 70) && (G < 90)) {
      if ((LS >= Math.abs(90 - G))) { // (HBR: T1=”Z” en T1Grad=1 of 2)
        Ad1TypeCode = 'Z'
        Ad1GradationCode = 1
      } else {
        Ad1TypeCode = 'Z'
        Ad1GradationCode = 2
      }
    } else if ((G >= 50) && (G < 70)) {
      if (LS >= Math.abs(70 - G)) { // (HBR: T1=”Z” en T1Grad=2 of 3)
        Ad1TypeCode = 'Z'
        Ad1GradationCode = 2
      } else {
        Ad1TypeCode = 'Z'
        Ad1GradationCode = 3
      }
    } else if (LS >= Math.abs(50 - G)) { // (HBR: T1=”Z” en T1Grad=3 of 4)
      Ad1TypeCode = 'Z'
      Ad1GradationCode = 3
    } else {
      Ad1TypeCode = 'Z'
      Ad1GradationCode = 4
    }

    return {
      SoilTypeCode: SoilTypeCode,
      Ad1TypeCode: Ad1TypeCode,
      Ad1GradationCode: Ad1GradationCode
    }
  },

  gravelSand (gravel, sand) {
    let SoilTypeCode = 'G'
    let Ad1TypeCode = ''
    let Ad1GradationCode = ''

    if (Math.abs(100 - gravel - sand) > 20) {
      Ad1TypeCode = 'S'
    } else {
      Ad1TypeCode = 'Z'

      if (sand <= 10) {
        Ad1GradationCode = 1
      } else if (sand <= 30) {
        Ad1GradationCode = 2
      } else if (sand <= 50) {
        Ad1GradationCode = 3
      } else if (sand <= 70) {
        Ad1GradationCode = 4
      }
    }

    return {
      SoilTypeCode: SoilTypeCode,
      Ad1TypeCode: Ad1TypeCode,
      Ad1GradationCode: Ad1GradationCode
    }
  },

  organicMatterLoam (organicMatter, lutum) { // V_L // Veen, klei
    let SoilTypeCode = 'V'
    let Ad1TypeCode = ''
    let Ad1GradationCode = ''

    if (organicMatter < (70 - 35 * (1 - lutum / 30))) {
      if (organicMatter > (45 - 22.5 * (1 - lutum / 55))) {
        Ad1TypeCode = (lutum < 4) ? 'Z' : 'K'
        Ad1GradationCode = 1
      } else {
        Ad1TypeCode = (lutum < 6) ? 'Z' : 'K'
        Ad1GradationCode = 3
      }
    }

    return {
      SoilTypeCode: SoilTypeCode,
      Ad1TypeCode: Ad1TypeCode,
      Ad1GradationCode: Ad1GradationCode
    }
  },

  organicMatterSand (V, SZ) { // V_SZ // Veen_Zand // organic matter, silt, sand
    let SoilTypeCode = 'V' // main component is veen
    let Ad1TypeCode = ''
    let Ad1GradationCode = ''

    if (V > (35 + 35 * (65 - SZ) / 65)) {
    // do nothing (should never happen)
    } else {
      if (V > (22.5 + 22.5 * (77.5 - SZ) / 77.5)) {
        if (V > (96 - SZ)) {
          Ad1TypeCode = 'Z'
          Ad1GradationCode = 1
        } else {
          Ad1TypeCode = 'K'
          Ad1GradationCode = 1
        }
      } else {
        if (V > (94 - SZ)) {
          Ad1TypeCode = 'Z'
          Ad1GradationCode = 3
        } else {
          Ad1TypeCode = 'K'
          Ad1GradationCode = 3
        }
      }
    }

    return {
      SoilTypeCode: SoilTypeCode,
      Ad1TypeCode: Ad1TypeCode,
      Ad1GradationCode: Ad1GradationCode
    }
  },

  additionOrganicMatter (V, SZ, L) {
    let Ad2GradationCode = ''
    if (L > SZ) {
      if (V > (16 - 8 * (1 - L / 84))) {
        Ad2GradationCode = 3
      } else if (V > (5 - 2.5 * (1 - L / 95))) {
        Ad2GradationCode = 2
      } else {
        Ad2GradationCode = 1
      }
    } else if (V > (8 + 8 * (92 - SZ) / 92)) {
      Ad2GradationCode = 3
    } else if (V > (2.5 + 2.5 * (97.5 - SZ) / 97.5)) {
      Ad2GradationCode = 2
    } else {
      Ad2GradationCode = 1
    }

    return {
      Ad2TypeCode: 'H',
      Ad2GradationCode: Ad2GradationCode
    }
  },

  additionGravel (G) {
    let Ad2GradationCode = ''
    if (G < 5) {
      Ad2GradationCode = 1
    } else if (G < 15) {
      Ad2GradationCode = 2
    } else {
      Ad2GradationCode = 3
    }
    return {
      Ad2TypeCode: 'G',
      Ad2GradationCode: Ad2GradationCode
    }
  },

  medianSand (m50) {
    if (m50 < 105) {
      return 1
    } else if (m50 < 150) {
      return 2
    } else if (m50 < 210) {
      return 3
    } else if (m50 < 300) {
      return 4
    } else if (m50 < 420) {
      return 5
    } else {
      return 6
    }
  },

  medianGravel (m50) {
    if (m50 < 5600) {
      return 1
    } else if (m50 < 16000) {
      return 2
    } else {
      return 3
    }
  },

  // eslint-disable-next-line no-unused-vars
  concatNEN (o) { // returns a DB friendly uppcase nen structurecode
    return (o.SoilTypeCode + o.MedianCode + o.Ad1TypeCode + o.Ad1GradationCode + o.Ad2TypeCode + o.Ad2GradationCode).toUpperCase()
  },

  Conv3 (A, B, C, Ac, Bc, Cc) {
    Ac = A
    Bc = B
    Cc = C
    if (((A + B + C) !== 100) && ((A + B + C) !== 0)) {
      Ac = A * 100 / (A + B + C)
      Bc = B * 100 / (A + B + C)
      Cc = C * 100 / (A + B + C)
    }
    return {
      Ac: Ac,
      Bc: Bc,
      Cc: Cc
    }
  }

}
