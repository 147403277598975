import en from '@/locales/en.json'
import nl from '@/locales/nl.json'
import fr from '@/locales/fr.json'
import it from '@/locales/it.json'
import es from '@/locales/es.json'
import de from '@/locales/de.json'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import cookie from '@/utils/cacheProviders/cookieCacheProvider'

const locales = {
  en, nl, fr, it, es, de
}

Vue.use(VueI18n)
const i18n = new VueI18n({
  fallbackLng: 'en',
  messages: locales,
  // try to set language based on cookie and default to 'nl'
  locale: (cookie.get('language') || 'nl')
})

export default i18n
