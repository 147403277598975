import Vue from 'vue'
import config from '@/configurations/config-environment'
import arrayUtils from '@/utils/arrayUtils'
import { BaseService } from '../baseService'

const dataws = config.apis.dataws

export class WaterSampleService extends BaseService {
  createWaterSample(projectId, measurementPointGuid, filtertubeGuid, watersamples, observations) {
    const data = {
      data: {
        PrID: projectId,
        MpGuid: measurementPointGuid,
        FtGuid: filtertubeGuid,
        WaterSamples: watersamples,
        Observations: observations,
        Authorisation: this.getAuthorizationObject()
      },
      action: 'INSERT'
    }

    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/setWaterSamples`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((resp) => {
        this.evaluateResponse(resp, 'createWaterSample')

        // find updated item
        const veldData = JSON.parse(resp.data.FileContent).VeldData
        const tblFilterTubes = arrayUtils.formatArray(veldData.tblFilterTubes)
        // let tblMeasurementPoints  = arrayUtils.formatArray(veldData.tblMeasurementPoints)
        const tblObservation = arrayUtils.formatArray(veldData.tblObservation)
        const tblWaterSamples = arrayUtils.formatArray(veldData.tblWaterSamples)
        const tblBottles = arrayUtils.formatArray(veldData.tblBottles)

        return {
          tblFilterTubes,
          // tblMeasurementPoints: tblMeasurementPoints,
          tblObservation,
          tblWaterSamples,
          tblBottles
        }
      })
  }

  updateWaterSample(projectId, measurementPointGuid, filtertubeGuid, watersamples, observations) {
    const data = {
      data: {
        PrID: projectId,
        MpGuid: measurementPointGuid,
        FtGuid: filtertubeGuid,
        WaterSamples: watersamples,
        Observations: observations,
        Authorisation: this.getAuthorizationObject()
      },
      action: 'UPDATE'
    }

    if (!filtertubeGuid || filtertubeGuid === '') {
      delete data.data.FtGuid
    }

    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/setWaterSamples`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((resp) => {
        this.evaluateResponse(resp, 'updateWaterSample')

        return true
      })
  }

  getAllWaterSamples(projectID) {
    const authorisation = this.getAuthorizationObject()
    const languageCode = this.getLangCodeLongFromShort(authorisation.Language)
    const data = {
      Authorisation: authorisation,
      LanguageCode: languageCode,
      WebserviceVersion: '1.0',
      TypeOfExport: 'Export_FIELD_JSON_v1_0_0',
      Parameters: [
        {
          ParameterType: 'ExportFormat',
          StringValue: 'json'
        },
        {
          ParameterType: 'ProjectID',
          StringValue: projectID.toString(),
          FloatValue: projectID.toString()
        },
        {
          ParameterType: 'ExcludeFieldsampleOlderThenXDays',
          FloatValue: 0
        }
      ],
      logFormat: 'XML'
    }

    return Vue.http.post(`${dataws}ITWDataRestService_V1_0/GetLabassignmentWaterData`, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        const veldData = JSON.parse(response.data.Files[0].FileContent).VeldData
        const tblWaterSamples = arrayUtils.formatArray(veldData.tblWaterSamples)

        return {
          tblWaterSamples
        }
      })
  }

  setWaterSamples(prid, mpguid, ftGuid, watersamples, observations) {
    const data = {
      data: {
        PrID: prid,
        MpGuid: mpguid,
        FtGuid: ftGuid,
        WaterSamples: watersamples,
        Observations: observations,
        Authorisation: this.getAuthorizationObject()
      },
      action: 'UPDATE'
    }

    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/SetWaterSamples`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((resp) => {
        if (resp.status !== 200 || resp.data.ResultCode !== 'Import_Succes') throw new Error(`Unable to save watersamples. ${resp.data.ResultMessage}`)

        // find updated item
        const veldData = JSON.parse(resp.data.FileContent).VeldData
        const tblFilterTubes = arrayUtils.formatArray(veldData.tblFilterTubes)
        // let tblMeasurementPoints  = arrayUtils.formatArray(veldData.tblMeasurementPoints)
        const tblObservation = arrayUtils.formatArray(veldData.tblObservation)
        const tblWaterSamples = arrayUtils.formatArray(veldData.tblWaterSamples)
        const tblBottles = arrayUtils.formatArray(veldData.tblBottles)

        return {
          tblFilterTubes,
          // tblMeasurementPoints: tblMeasurementPoints,
          tblObservation,
          tblWaterSamples,
          tblBottles
        }
      })
  }

  deleteWaterSample(projectID, measurementPointGuid, filtertubeGuid, watersampleGuid) {
    const data = {
      data: {
        PrID: projectID,
        MpGuid: measurementPointGuid,
        FtGuid: filtertubeGuid,
        WsGuid: watersampleGuid,
        Authorisation: this.getAuthorizationObject()
      },
      action: 'DELETE'
    }

    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/setWaterSamples`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((resp) => {
        this.evaluateResponse(resp, 'deleteWaterSample')

        return true
      })
  }
  evaluateResponse(response, requestName) {
    if (
      response.status !== 200 ||
      !response.data ||
      (response.data.ResultCode !== 'Import_Succes' && response.data.ResultCode !== 'Export_Succes') ||
      (response.data.ResultMessage !== null && response.data.ResultMessage.toLowerCase().includes('error'))
    ) {
      throw new Error(`Request ${requestName} failed with message '${response.data.ResultMessage}'`)
    }
  }

  validateWaterSampleName(projectId, waterSampleName, WSGuid) {
    const data = {
      data: {
        WaterSampleName: waterSampleName,
        PrID: projectId,
        WSGuid,
        Authorisation: this.getAuthorizationObject()
      }
    }

    return Vue.http.post(`${dataws}ITWDataRestService_V1_0/ValidateWaterSampleName`, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }
}
